import {
  FILTER_OPERATORS,
  type ServerFilterInput,
} from '@common/constants/filters/serverFilters';
import { useMutation } from '@graphql/hooks';
import { graphql } from '@graphql/types';

type EmailTemplate = {
  id: string;
  subject: string;
};

type SendEmailsParams = {
  filters?: ServerFilterInput;
  emailTemplate: EmailTemplate;
  excludeCompliantPrimaryRecords: boolean;
  recipientsMap?: Record<string, string[]>;
};

const SEND_EMAILS_MUTATION = graphql(`
  mutation SendEmails($data: SendPrimaryRecordsRequestInput!) {
    sendPrimaryRecordsRequest(data: $data) {
      operation {
        code
        name
        status
        progress
      }
      errors {
        code
        message
      }
    }
  }
`);

export const useSendEmailsMutation = () => {
  const [sendEmailsMutation, { loading }] = useMutation(SEND_EMAILS_MUTATION);

  const sendEmails = ({
    filters,
    emailTemplate,
    excludeCompliantPrimaryRecords,
    recipientsMap,
  }: SendEmailsParams) => {
    return sendEmailsMutation({
      variables: {
        data: {
          filter: {
            and: [
              ...(filters ? filters.and : []),
              ...(excludeCompliantPrimaryRecords
                ? [
                    {
                      name: 'complianceStatus',
                      operator: FILTER_OPERATORS.in,
                      value: ['nonCompliant'],
                    },
                  ]
                : []),
            ],
          },
          emailTemplate,
          ...(recipientsMap && { recipientsMap }),
        },
      },
    });
  };

  return {
    sendEmails,
    loading,
  };
};
