import { FILTER_TYPES } from '@common/constants/filters';
import type { ServerFiltersConfig } from '@common/utils/filters';

export const COLUMN_FIELDS = {
  name: 'name',
  primaryRecordsCompliance: 'primaryRecordsCompliance',
  contextRecordsCompliance: 'contextRecordsCompliance',
  createdAt: 'createdAt',
} as const;

export const SERVER_FILTERS_CONFIG: ServerFiltersConfig = {
  [COLUMN_FIELDS.name]: {
    name: 'name',
    filterType: FILTER_TYPES.string,
  },
};
