import { InfoCircleOutlined } from '@ant-design/icons';
import { Alert, Form } from 'antd';
import DOMPurify from 'dompurify';
import type { EditorState } from 'draft-js';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import RichTextEditor from '@common/components/RichTextEditor';
import {
  getEditorPlainText,
  getUpdatedEditorState,
} from '@common/components/RichTextEditor/helpers';
import Spinner from '@common/components/Spinner';
import { EmailTemplateSelect } from '@modules/email-template';

import { usePlaceholdersByEmailTemplateType } from '@modules/email-template/hooks';
import { type EmailTemplate, useEmailTemplates } from '../hooks';

type EmailTemplateFormProps = {
  partiesTotal?: number;
  objectPluralName: string;
  onFormChange: (params: { subject: string; templateId: string }) => void;
};

export const EmailTemplateForm = ({
  onFormChange,
  partiesTotal,
  objectPluralName,
}: EmailTemplateFormProps) => {
  const [subjectEditorState, setSubjectEditorState] = useState<
    EditorState | undefined
  >();
  const [currentTemplate, setCurrentTemplate] = useState<EmailTemplate>();
  const {
    emailTemplates,
    areEmailTemplatesLoading,
    isEmailTemplateCompiling,
    fetchCompiledEmailTemplate,
    emailTemplateHTML,
    defaultEmailTemplate,
  } = useEmailTemplates();

  // biome-ignore lint/correctness/useExhaustiveDependencies: More Deps Than Needed
  const debouncedUpdateValue = useCallback(
    debounce((content) => onFormChange(content), 500),
    [onFormChange],
  );

  const { emailPlaceholders } = usePlaceholdersByEmailTemplateType(
    currentTemplate?.type,
  );

  useEffect(() => {
    setCurrentTemplate(defaultEmailTemplate);
    setSubjectEditorState(getUpdatedEditorState(defaultEmailTemplate?.subject));
  }, [defaultEmailTemplate, defaultEmailTemplate?.subject]);

  const handleTemplateSelectChange = (newTempalteId: string) => {
    const template = emailTemplates?.find(({ _id }) => _id === newTempalteId);
    setCurrentTemplate(template);

    debouncedUpdateValue({
      templateId: newTempalteId,
      subject: subjectEditorState ? getEditorPlainText(subjectEditorState) : '',
    });

    fetchCompiledEmailTemplate({
      mjml: template?.layout.mjml || '',
      regions: template?.regions,
    });

    setSubjectEditorState(getUpdatedEditorState(template?.subject));
  };

  return (
    <StyledWrap>
      <section className="column">
        <StyledSectionTitle>Customize email</StyledSectionTitle>
        <StyledAlert
          message={`This request will be sent to ${partiesTotal} ${objectPluralName}.`}
          type="info"
          showIcon
        />

        {areEmailTemplatesLoading ? (
          <Spinner />
        ) : (
          <Form layout="vertical">
            <Form.Item required label="Select email template">
              <EmailTemplateSelect
                selectedTemplateId={currentTemplate?._id || ''}
                templates={emailTemplates || []}
                onTemplateChange={handleTemplateSelectChange}
              />
            </Form.Item>
            <Form.Item label="Email subject">
              <RichTextEditor
                isValid
                minHeight={32}
                maxHeight={55}
                placeholder={`Enter subject...`}
                editorState={subjectEditorState}
                disableInlineStyleButtons
                isOneLineEditor
                placeholders={emailPlaceholders}
                focusDependence={false}
                onChange={(subjectEditorState: EditorState): void => {
                  setSubjectEditorState(subjectEditorState);
                  debouncedUpdateValue({
                    templateId: currentTemplate?._id || '',
                    subject: getEditorPlainText(subjectEditorState),
                  });
                }}
              />
            </Form.Item>
          </Form>
        )}
        <StyledInfoTextCustomization>
          <InfoCircleOutlined />
          <p className="description">
            You can use special tokens to customize content in layout regions.
            Type the open curly bracket character <strong>{`{`}</strong> and
            select from the list of available tokens.
          </p>
        </StyledInfoTextCustomization>
      </section>
      <section className="column">
        <StyledSectionTitle>Preview email</StyledSectionTitle>

        {isEmailTemplateCompiling ? (
          <Spinner />
        ) : (
          <StyledPreviewWrap
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(emailTemplateHTML || '', {
                WHOLE_DOCUMENT: true,
                ADD_TAGS: ['head'],
              }),
            }}
          />
        )}
      </section>
    </StyledWrap>
  );
};

const StyledWrap = styled.section`
  display: flex;
  padding: 20px 0 30px;

  .column {
    width: 50%;
  }

  .column:last-child {
    padding-left: 15px;
    border-left: 1px solid #f0f0f0;
  }

  .column:first-child {
    padding-right: 15px;
  }
`;

const StyledSectionTitle = styled.h3`
  display: block;
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 400;
`;

const StyledAlert = styled(Alert)`
  border-radius: 2px;
  margin-bottom: 16px;
`;

const StyledInfoTextCustomization = styled.section`
  display: flex;
  align-items: center;
  margin-top: 10px;

  .description {
    margin-left: 10px;
    margin-bottom: 0;
    font-size: 12px;
    color: ${({ theme }) => theme.colors.blackGray};
  }

  .description > strong {
    color: ${({ theme }) => theme.colors.blue};
  }
`;

const StyledPreviewWrap = styled.section`
  max-height: 450px;
  overflow-y: scroll;
`;
