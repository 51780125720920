import { useMutation } from '@graphql/hooks';
import { graphql } from '@graphql/types';
import { useCallback } from 'react';

const UPDATE_PORTAL_MESSAGE_MUTATION = graphql(`
  mutation UpdatePortalMessage($data: UpdatePortalMessageInput!) {
    updatePortalMessage(data: $data)
  }
`);

export const useUpdatePortalMessage = (primaryRecordId: string) => {
  const [updatePortalMessageMutation, { loading: isUpdatingPortalMessage }] =
    useMutation(UPDATE_PORTAL_MESSAGE_MUTATION);

  const updatePortalMessage = useCallback(
    (message: string | null) => {
      return updatePortalMessageMutation({
        variables: {
          data: {
            message,
            primaryRecordId,
          },
        },
      });
    },
    [updatePortalMessageMutation, primaryRecordId],
  );

  return {
    updatePortalMessage,
    isUpdatingPortalMessage,
  };
};
