import { useCallback } from 'react';

import type { GetRowData } from '@common/components/Table';
import { mapTableFiltersToServerFilters } from '@common/utils/filters';
import { useCustomLazyQuery } from '@graphql/hooks';
import { graphql } from '@graphql/types';
import { SERVER_FILTERS_CONFIG } from '../constants';

export const WORKSPACES_QUERY = graphql(`
  query Worspaces($input: WorkspacesInput!) {
    workspaces(input: $input) {
      totalCount
      nodes {
        _id
        name
        slugifyName
        createdAt
        stats {
          contextRecords {
            compliantCount
            totalCount
          }
          primaryRecords {
            compliantCount
            totalCount
          }
        }
      }
    }
  }
`);

export const useWorkspacesQuery = () => {
  const getWorkspacesQuery = useCustomLazyQuery(WORKSPACES_QUERY);

  const getWorkspaces: GetRowData = useCallback(
    async ({ startRow, endRow, filters }) => {
      const activeFilters = mapTableFiltersToServerFilters({
        tableFilters: filters,
        serverFiltersConfig: SERVER_FILTERS_CONFIG,
      });

      const { data } = await getWorkspacesQuery({
        variables: {
          input: {
            offset: startRow,
            first: endRow - startRow,
            filter: activeFilters,
          },
        },
      });

      const workspaces = data?.workspaces || {};

      return {
        rowData: workspaces.nodes || [],
        rowCount: workspaces.totalCount || 0,
      };
    },
    [getWorkspacesQuery],
  );

  return {
    getWorkspaces,
  };
};
