import { useIsComplianceRequirementsAddOnEnabled } from '@modules/add-on';
import { REQUEST_RECORDS_TABS } from '@modules/request-records';
import { Navigate } from 'react-router-dom';

export const RequestRecordDetailGuard = () => {
  const { isComplianceRequirementsAddOnEnabled } =
    useIsComplianceRequirementsAddOnEnabled();

  return isComplianceRequirementsAddOnEnabled ? (
    <Navigate to={REQUEST_RECORDS_TABS.Compliance.key} replace />
  ) : (
    <Navigate to={REQUEST_RECORDS_TABS.Checklist.key} replace />
  );
};
