import { Collapse } from 'antd';
import React from 'react';
import styled from 'styled-components';

type HeaderProps = { children: React.ReactNode };
type BodyProps = { children: React.ReactNode };

type CollapseCardProps = {
  children: React.ReactNode[];
  collapsible?: boolean;
  expanded?: boolean;
  className?: string;
};

const PANEL_KEY = 'panel';

/**
 *
 * @docs This component is a wrapper of the antd **Collapse** Antd component. It is used to create a collapsible card. It must have a **Header** and the **Body** at least.
 */
export const CollapseCard = ({
  expanded = true,
  collapsible = true,
  children,
  className,
}: Omit<CollapseCardProps, 'header'>) => {
  const headerContent = React.Children.toArray(children).find(
    (child) =>
      React.isValidElement(child) && child.type === CollapseCard.Header,
  );

  const panelContent = React.Children.toArray(children).filter(
    (child) =>
      !React.isValidElement(child) || child.type !== CollapseCard.Header,
  );

  return (
    <StyledCollapse
      bordered={false}
      expandIcon={() => null}
      collapsible={collapsible ? 'header' : 'disabled'}
      defaultActiveKey={expanded ? PANEL_KEY : ''}
      className={className}
    >
      <Collapse.Panel key={PANEL_KEY} header={headerContent}>
        {panelContent}
      </Collapse.Panel>
    </StyledCollapse>
  );
};

CollapseCard.Header = ({ children, ...rest }: HeaderProps) => (
  <StyledCollapseHeader {...rest}>{children}</StyledCollapseHeader>
);

CollapseCard.Body = ({ children, ...rest }: BodyProps) => (
  <div {...rest}>{children}</div>
);

const StyledCollapse = styled(Collapse)`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 0;

  .ant-collapse-header-text {
    width: 100%;
  }

  .ant-collapse-item {
    box-shadow: 0 0 0 1px rgba(71, 88, 114, 0.12);
    border-radius: 9px !important;
  }

  .ant-collapse-header {
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 8px !important;
    box-shadow: none;
    padding: 12px 24px !important;
  }

  .ant-collapse-content.ant-collapse-content-active {
    border-top: 1px solid #F0F0F0;
  }

  .ant-collapse-content-box {
    padding: 12px 40px !important;
  }
`;

const StyledCollapseHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
