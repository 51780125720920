import { UploadAttachmentsModal as CommonUploadAttachmentsModal } from '@common/components/UploadAttachmentsModal';
import { acceptExtensions } from '@modules/document/constants';
import { MAX_DOCUMENTS_UPLOAD_COUNT } from '@trustlayer/common';
import { message } from 'antd';
import type { UploadFile } from 'antd/es/upload';
import { useUploadContextRecordAttachments } from '../../hooks/useUploadContextRecordAttachments';

type UploadAttachmentModalProps = {
  open: boolean;
  contextRecordId?: string;
  onCancel?: VoidFunction;
  onCompleted?: VoidFunction;
  onFailed?: VoidFunction;
};

export function UploadAttachmentModal({
  open,
  contextRecordId,
  onCancel,
  onCompleted,
  onFailed,
}: UploadAttachmentModalProps) {
  const { uploadContextRecordAttachments, uploading } =
    useUploadContextRecordAttachments();

  const handleUpload = async (files: UploadFile[]) => {
    try {
      const res = await uploadContextRecordAttachments({
        variables: {
          data: {
            contextRecordId,
            files,
          },
        },
      });

      if (res.data?.uploadContextRecordAttachments) {
        message.success('Attachments uploaded successfully.');
        onCompleted?.();
      } else {
        message.error('Failed to upload attachments.');
        onFailed?.();
      }
    } catch (error) {
      message.error('Failed to upload attachments.');
      onFailed?.();
    }
  };

  return (
    <CommonUploadAttachmentsModal
      open={open}
      title="Upload attachments"
      maxUploadCount={MAX_DOCUMENTS_UPLOAD_COUNT}
      acceptedFileTypes={acceptExtensions}
      onCancel={onCancel}
      onCompleted={onCompleted}
      onFailed={onFailed}
      onOk={handleUpload}
      loading={uploading}
    />
  );
}
