import { gql } from '@apollo/client';
import { useState } from 'react';

import { useMutation, useQuery } from '@graphql/hooks';
import { EMAIL_TEMPLATE_TYPES } from '@modules/email-template';

export type EmailTemplate = {
  _id: string;
  name: string;
  type: string;
  subject: string;
  regions: Record<string, string>;
  layout: {
    mjml: string;
  };
};

type EmailTemplateQuery = {
  listEmailTemplates: EmailTemplate[];
};

type CompileEmailTemplateMutation = {
  compileEmailTemplate: string;
};

type CompileEmailTemplateMutationVariables = {
  data: {
    mjml: string;
    regions?: Record<string, string>;
  };
};

const COMPILE_EMAIL_TEMPLATE_MUTATION = gql`
  mutation compileEmailTemplate($data: CompileEmailTemplateInput!) {
    compileEmailTemplate(data: $data)
  }
`;

const EMAIL_TEMPLATES_QUERY = gql`
  query EmailTemplatesList {
    listEmailTemplates {
      _id
      name
      type
      subject
      regions
      layout {
        mjml
      }
    }
  }
`;

const DEFAULT_EMAIL_TEMPLATE_TYPE =
  EMAIL_TEMPLATE_TYPES.PartyComplianceRequestReminder;

export const useEmailTemplates = () => {
  const [defaultEmailTemplate, setDefaultEmailTemplate] = useState<
    EmailTemplate | undefined
  >();

  const [
    compileEmailTemplateMutation,
    { loading: isEmailTemplateCompiling, data: compileEmailTemplateData },
  ] = useMutation<
    CompileEmailTemplateMutation,
    CompileEmailTemplateMutationVariables
  >(COMPILE_EMAIL_TEMPLATE_MUTATION);

  const fetchCompiledEmailTemplate = (
    payload: CompileEmailTemplateMutationVariables['data'],
  ) =>
    compileEmailTemplateMutation({
      variables: {
        data: payload,
      },
    });

  const { data, loading: areEmailTemplatesLoading } =
    useQuery<EmailTemplateQuery>(EMAIL_TEMPLATES_QUERY, {
      onCompleted: ({ listEmailTemplates }) => {
        const template = listEmailTemplates?.find(
          ({ type }) => type === DEFAULT_EMAIL_TEMPLATE_TYPE,
        );

        setDefaultEmailTemplate(template);

        fetchCompiledEmailTemplate({
          mjml: template?.layout.mjml || '',
          regions: template?.regions,
        });
      },
    });

  return {
    emailTemplates: data?.listEmailTemplates,
    areEmailTemplatesLoading,
    emailTemplateHTML: compileEmailTemplateData?.compileEmailTemplate,
    fetchCompiledEmailTemplate,
    isEmailTemplateCompiling,
    defaultEmailTemplate,
  };
};
