import pluralize from 'pluralize';
import styled from 'styled-components';

type AllPartiesSelectedMessageProps = {
  partiesMissingContactCount: number;
  partiesTotalCount: number;
};

const getMissingContactsText = (
  missingContactCount: number,
) => `${missingContactCount} ${pluralize('party', missingContactCount)} cannot be sent to because there
  is no email address present.`;

export const AllPartiesSelectedMessage = ({
  partiesMissingContactCount,
  partiesTotalCount,
}: AllPartiesSelectedMessageProps) => {
  const partiesWithContactCount =
    partiesTotalCount - partiesMissingContactCount;

  return (
    <StyledSection>
      You are sending requests to{' '}
      <strong>
        {partiesWithContactCount} of {partiesTotalCount} parties selected.{' '}
      </strong>
      {Boolean(partiesMissingContactCount) &&
        getMissingContactsText(partiesMissingContactCount)}{' '}
      Do you want to proceed?
    </StyledSection>
  );
};

const StyledSection = styled.section`
  padding: 24px 0;
`;
