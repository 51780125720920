import CheckIcon from '@common/icons/CheckIcon';
import MarkIcon from '@common/icons/MarkIcon';
import { groupByModule } from '@common/utils/group-by-module';
import { getRequirementsComplianceStats } from '@modules/requirement/utils/requirement-helpers';
import { getGraphqlPayload } from '@store/helpers';
import pluralize from 'pluralize';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { Skeleton } from 'antd';
import { fetchPartyRequirements } from '../actions';

const PartyComplianceIndicatorPopoverContent = ({
  party,
  hasLinks,
  fetchRequirements,
}) => {
  const navigate = useNavigate();
  const { organizationSlugifyName } = useParams();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [requirements, setRequirements] = useState([]);

  const defaultFetchPartyRequirements = async (partyId) => {
    const res = await dispatch(fetchPartyRequirements(partyId));
    const data = getGraphqlPayload(res);
    return data?.requirements || [];
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: Legacy
  useEffect(() => {
    const loadRequirements = async () => {
      setIsLoading(true);
      const newRequirements = fetchRequirements
        ? await fetchRequirements(party?._id)
        : await defaultFetchPartyRequirements(party?._id);

      setRequirements(newRequirements);
      setIsLoading(false);
    };

    loadRequirements();
  }, []);

  const redirectToCompliance = (subjectId) =>
    navigate(`/${organizationSlugifyName}/parties/${party._id}/compliance`, {
      state: { subjectId },
    });

  if (isLoading) {
    return <Skeleton active title={false} />;
  }

  const groupedModules = groupByModule(requirements);

  return groupedModules.map((moduleData) =>
    moduleData.subjects.map((subject) => {
      const complianceStats = getRequirementsComplianceStats(subject.children);

      const isCompliant = complianceStats.nonCompliantRequirementsCount === 0;

      const subjectHasWaivedRequirements =
        complianceStats.waivedRequirementsCount > 0;

      const subjectHasOverriddenRequirements =
        complianceStats.overriddenRequirementsCount > 0;

      return (
        <StyledSubjectWrapper key={subject.subjectId}>
          <StyledIconWrapper>
            {isCompliant ? (
              <CheckIcon
                muted={
                  subjectHasWaivedRequirements ||
                  subjectHasOverriddenRequirements
                }
              />
            ) : (
              <MarkIcon
                muted={
                  subjectHasWaivedRequirements ||
                  subjectHasOverriddenRequirements
                }
              />
            )}
          </StyledIconWrapper>
          {hasLinks ? (
            <StyledSection
              onClick={() => redirectToCompliance(subject.subjectId)}
            >
              {subject.subjectLabel}
            </StyledSection>
          ) : (
            <span>{subject.subjectLabel}</span>
          )}
          {subjectHasWaivedRequirements && (
            <StyledWaiverBadge>
              {pluralize(
                'waiver',
                complianceStats.waivedRequirementsCount,
                true,
              )}
            </StyledWaiverBadge>
          )}
          {subjectHasOverriddenRequirements && (
            <StyledOverrideBadge>
              {pluralize(
                'override',
                complianceStats.overriddenRequirementsCount,
                true,
              )}
            </StyledOverrideBadge>
          )}
        </StyledSubjectWrapper>
      );
    }),
  );
};

const StyledIconWrapper = styled.span`
  margin-right: 8px;
`;

const StyledSection = styled.span`
  cursor: pointer;
  transition:
    opacity 0.5s,
    color 0.2s;

  :hover {
    opacity: 0.8;
  }

  :active {
    color: ${(props) => props.theme.colors.blue};
  }
`;

const StyledSubjectWrapper = styled.div`
  &:hover small {
    opacity: 1;
  }
`;

const StyledBadge = styled.small`
  margin: 0 3px;
  opacity: 0;
  transition: all 0.3s;
`;

const StyledWaiverBadge = styled(StyledBadge)`
  color: #8099bc;
`;

const StyledOverrideBadge = styled(StyledBadge)`
  color: #f90;
`;

export default PartyComplianceIndicatorPopoverContent;
