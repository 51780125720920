import { useIsComplianceRequirementsAddOnEnabled } from '@modules/add-on';
import { ComplianceProfilesSelect } from '@modules/compliance-profile/containers/ComplianceProfilesSelect/ComplianceProfilesSelect';
import { Button, Modal } from 'antd';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useAssignComplianceProfileMutation } from '../../hooks/useAssignComplianceProfileMutation';

type AssignComplianceProfileModalProps = {
  isShow: boolean;
  onClose: () => void;
  requestRecordId: string;
  complianceProfile?: {
    _id: string;
    name: string;
  };
  onSuccess: () => void;
};

export const AssignComplianceProfileModal = ({
  isShow,
  onClose,
  onSuccess,
  complianceProfile,
  requestRecordId,
}: AssignComplianceProfileModalProps) => {
  const { isComplianceRequirementsAddOnEnabled } =
    useIsComplianceRequirementsAddOnEnabled();

  const [selectedComplianceProfile, setSelectedComplianceProfile] =
    useState(complianceProfile);

  useEffect(() => {
    setSelectedComplianceProfile(complianceProfile);
  }, [complianceProfile]);

  const { assignComplianceProfile, isAssignComplianceProfileLoading } =
    useAssignComplianceProfileMutation();

  const onCancel = () => {
    onClose();
    setSelectedComplianceProfile(undefined);
  };

  const onOk = () => {
    assignComplianceProfile({
      variables: {
        data: {
          id: selectedComplianceProfile?._id,
          requestRecords: [
            {
              requestRecordId,
            },
          ],
        },
      },
      onCompleted: () => {
        onSuccess();
      },
    });
  };

  const elementTitle = isComplianceRequirementsAddOnEnabled
    ? 'compliance profile'
    : 'checklist';

  return (
    <Modal
      title={`Assign ${elementTitle}`}
      open={isShow}
      onOk={onOk}
      onCancel={onCancel}
      closable={!isAssignComplianceProfileLoading}
      footer={[
        <Button
          key="back"
          onClick={onCancel}
          disabled={isAssignComplianceProfileLoading}
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={isAssignComplianceProfileLoading}
          onClick={onOk}
          disabled={!selectedComplianceProfile}
        >
          Ok
        </Button>,
      ]}
    >
      <p>Select which {elementTitle} you want to assign</p>

      <StyledDropdownWrapper>
        <ComplianceProfilesSelect
          placeholder={`Select a ${elementTitle}`}
          value={selectedComplianceProfile}
          onChange={(value) => setSelectedComplianceProfile(value)}
          size="middle"
        />
      </StyledDropdownWrapper>
    </Modal>
  );
};

const StyledDropdownWrapper = styled.div`
  margin-bottom: 16px;
`;
