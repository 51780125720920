import {
  useIsComplianceRequirementsAddOnEnabled,
  useIsContextsAddOnEnabled,
} from '@modules/add-on';

import { useMemo } from 'react';
import { getPlaceholders } from '../constants';
import { useIsEmbeddedInsuranceAddOnEnabled } from './useIsEmbeddedInsuranceAddOnEnabled';

export const usePlaceholders = () => {
  const { isComplianceRequirementsAddOnEnabled } =
    useIsComplianceRequirementsAddOnEnabled();
  const { isContextsAddOnEnabled } = useIsContextsAddOnEnabled();
  const { isEmbeddedInsuranceAddOnEnabled } =
    useIsEmbeddedInsuranceAddOnEnabled();
  const placeholders = useMemo(
    () =>
      getPlaceholders({
        isComplianceRequirementsAddOnEnabled,
        isContextsAddOnEnabled,
        isEmbeddedInsuranceAddOnEnabled,
      }),
    [
      isComplianceRequirementsAddOnEnabled,
      isContextsAddOnEnabled,
      isEmbeddedInsuranceAddOnEnabled,
    ],
  );

  return { placeholders };
};
