import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ModalConfirm } from '@common/components/ModalConfirm';
import { withControlledMount } from '@common/hoc/withControlledMount';
import { BulkOperationStatus } from '@graphql/types/graphql';
import { useIsComplianceRequirementsAddOnEnabled } from '@modules/add-on';
import { useMakeActivePrimaryRecordMutation } from '@modules/primary-records/hooks/useMakeActivePrimaryRecordMutation';
import { Typography, message } from 'antd';
import { usePrimaryRecordQuery } from './hooks/usePrimaryRecordQuery';

type MakeActivePrimaryRecordModalProps = {
  open: boolean;
  primaryRecordId: string;
  onCancel?: () => void;
  onCompleted?: () => void;
  onScheduled?: () => void;
  onFailed?: () => void;
};

const MakeActivePrimaryRecordModalComponent = ({
  open,
  primaryRecordId,
  onCancel,
  onCompleted,
  onScheduled,
  onFailed,
}: MakeActivePrimaryRecordModalProps) => {
  const { isComplianceRequirementsAddOnEnabled } =
    useIsComplianceRequirementsAddOnEnabled();

  const { primaryRecord, loading } = usePrimaryRecordQuery({
    id: primaryRecordId,
    skip: !open || !primaryRecordId,
  });

  const { makeActivePrimaryRecord, isMakingActivePrimaryRecord } =
    useMakeActivePrimaryRecordMutation();

  const handleMakeActive = async () => {
    if (!primaryRecordId) {
      throw new Error('Primary record ID is required');
    }

    const res = await makeActivePrimaryRecord(primaryRecordId);

    const status = res.data?.activatePrimaryRecords.operation.status;

    if (status === BulkOperationStatus.Completed) {
      message.success(`${primaryRecord?.name} is now active.`);
      onCompleted?.();
    }

    if (status === BulkOperationStatus.Scheduled) {
      message.success(
        `${primaryRecord?.name} has been scheduled for activation.`,
      );
      onScheduled?.();
    }

    if (status === BulkOperationStatus.Failed) {
      message.error(
        `The activation of ${primaryRecord?.name} has failed. Please try again or contact support.`,
      );
      onFailed?.();
    }
  };

  return (
    <ModalConfirm
      loading={loading}
      data-cy="make-active-primary-record-modal"
      open={open}
      icon={<ExclamationCircleOutlined />}
      okButtonProps={{
        loading: isMakingActivePrimaryRecord,
      }}
      okText="Make active"
      title={`Are you sure you want to make active ${primaryRecord?.name}?`}
      onCancel={onCancel}
      onOk={handleMakeActive}
    >
      <Typography.Paragraph>
        Please confirm that you want to make active {primaryRecord?.name}
      </Typography.Paragraph>
      <ul>
        <li>
          It will show up in your reports
          {isComplianceRequirementsAddOnEnabled ? ' and dashboard' : ''}
        </li>
        <li>Automatic requests for expired documents will be sent</li>
      </ul>
    </ModalConfirm>
  );
};

export const MakeActivePrimaryRecordModal = withControlledMount(
  MakeActivePrimaryRecordModalComponent,
);
