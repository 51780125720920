import { gql } from '@apollo/client';
import { message as notify } from 'antd';
import moment from 'moment';
import * as R from 'ramda';
import { eventChannel } from 'redux-saga';
import {
  all,
  call,
  fork,
  put,
  putResolve,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects';

import graphqlClient from '@graphql/client';
import { SET_AUTHENTICATED_APP_READY } from '@modules/app/actions';
import {
  getSubjectsByModule,
  getUniqModules,
} from '@modules/compliance-profile/utils/compliance-attributes-helpers';
import { setBulkOperationCompleted } from '@modules/organization/actions';
import {
  getActiveOrganizationId,
  getIsPartyBelongToActiveOrganization,
  getOrganizationNamespaceUrl,
} from '@modules/organization/selectors';
import { ASSIGN_COMPLIANCE_PROFILE } from '@modules/party-compliance-profile/actions';
import { fetchPartyTypes } from '@modules/party-type/actions';
import { createRequest } from '@modules/request/actions';
import { push } from '@modules/router/actions';
import { getGraphqlPayload } from '@store/helpers';

import { trackEvent } from '@common/utils/track-helpers';
import {
  CREATE_OR_UPDATE_PARTY,
  DELETE_PARTY,
  FETCH_CREATE_OR_EDIT_PARTY_DATA,
  FETCH_PARTY_WITH_DETAILS,
  IMPORT_PARTIES,
  SEND_REQUEST,
  UPDATE_PARTY_STATE,
  createParty,
  fetchPartyToEdit,
  selectDocumentForComplianceCard,
  updateParty,
} from '../actions';
import {
  EventType,
  bulkEventAnalyticsMessage,
  bulkEventDefaultMessage,
} from '../constants';
import { getParty, getPartySelectedDocuments } from '../selectors';

const BULK_PARTIES_ACTIONS_PROCESSED_SUBSCRIPTION = gql`
  subscription onBulkPartiesProcessed($organizationId: String!) {
    bulkPartiesProcessed(organizationId: $organizationId)
  }
`;

/**
 * Fetching data for create or edit party page
 */
function* fetchCreateOrEditPartyDataSaga({ payload: { partyId } }) {
  if (partyId) {
    const partyRes = yield putResolve(fetchPartyToEdit(partyId));
    const party = getGraphqlPayload(partyRes);
    const isPartyBelongToActiveOrganization = yield select(
      getIsPartyBelongToActiveOrganization,
      party,
    );
    if (!isPartyBelongToActiveOrganization) yield put(push('/not-found'));
  }

  yield putResolve(fetchPartyTypes());
}

/**
 * Create or update party
 */
function* createOrUpdatePartySaga({ payload: { party } }) {
  const organizationNamespace = yield select(getOrganizationNamespaceUrl);
  const defaultPartiesUrl = `${organizationNamespace}/parties`;

  if (!party.id) {
    yield putResolve(createParty(party));
    yield call(trackEvent, 'User created a new party');

    yield put(push(defaultPartiesUrl));
    yield notify.success(`New party successfully created`);
  } else {
    yield putResolve(updateParty(party));
    yield call(trackEvent, 'User updated a party');

    yield put(push(`${defaultPartiesUrl}/${party.id}/overview`));
    yield notify.success(`Party successfully updated`);
  }
}

/**
 * Send request
 */
function* sendRequestSaga({
  payload: { party, recipients, message, dueDate, attachments, template },
  willNotify = true,
}) {
  yield putResolve(
    createRequest({
      party,
      dueDate,
      recipients,
      message,
      attachments,
      template,
    }),
  );

  yield call(trackEvent, 'User sent a document request');

  if (willNotify) {
    yield notify.success('Request successfully sent');
  }
}

/**
 * Successfully delete party notification
 */
function* deletePartyNotificationSaga({ payload }) {
  yield call(trackEvent, 'User deleted a party');
  yield notify.success(`Party successfully deleted`);
}

/**
 * Fail import parties notification
 */
function* importPartiesFailNotificationSaga(payload) {
  yield notify.error(R.path(['error', '0', 'message'], payload));
}

/**
 * ! FIXME: This implementation has issues
 * If the party has > 1 pages of documents, this will not work as expected.
 */
function* setSelectedDocumentSaga({
  partyId,
  subjects,
  selectedDocuments,
  sortedDocuments,
}) {
  yield all(
    subjects?.reduce((acc, subject) => {
      const alreadyExist = selectedDocuments[subject?.subjectId];

      if (alreadyExist) {
        return acc;
      }

      const documentAvailableForSubject = sortedDocuments.find((document) => {
        const subjectMeta = R.pathOr(
          {},
          ['metadata', subject?.subjectId],
          document,
        );

        const { effectiveDate, expirationDate } = subjectMeta;

        if (
          effectiveDate &&
          expirationDate &&
          moment().isBetween(effectiveDate, expirationDate)
        ) {
          return true;
        }

        return false;
      });

      if (documentAvailableForSubject) {
        acc.push(
          put(
            selectDocumentForComplianceCard({
              partyId,
              subjectId: subject?.subjectId,
              documentId: documentAvailableForSubject?._id,
            }),
          ),
        );
      }
      return acc;
    }, []),
  );
}

function* setSelectedDocumentsByDefault(action) {
  const payload = getGraphqlPayload(action) || {};

  const {
    _id: partyId,
    requirements,
    applicableDocuments,
    documents,
  } = payload;

  const modules = getUniqModules(requirements);
  const subjects = modules.reduce((acc, data) => {
    return acc.concat(getSubjectsByModule(data?.moduleId, requirements));
  }, []);

  const partyDocuments =
    Array.isArray(applicableDocuments) && applicableDocuments.length
      ? applicableDocuments
      : Array.isArray(documents)
        ? documents
        : [];

  const sortedDocuments = partyDocuments?.sort((a, b) => {
    return 0 - moment(a?.createdAt).diff(moment(b?.createdAt));
  });

  const selectedDocuments = yield select((store) =>
    getPartySelectedDocuments(store, partyId),
  );

  yield call(setSelectedDocumentSaga, {
    partyId,
    subjects,
    selectedDocuments,
    sortedDocuments,
  });
}

function* selectDefaultDocumentsForProfileSaga(action) {
  const payload = getGraphqlPayload(action);
  const requirements = R.propOr([], 'requirements', payload);
  const partyId = R.pathOr('', ['partyComplianceProfile', 'party'], payload);
  const partyData = yield select((store) => getParty(store, partyId));
  const documents = R.propOr([], 'documents', partyData);
  const applicableDocuments = R.propOr([], 'applicableDocuments', partyData);
  const modules = getUniqModules(requirements);
  const subjects = modules.reduce((acc, data) => {
    return acc.concat(getSubjectsByModule(data?.moduleId, requirements));
  }, []);

  const partyDocuments =
    Array.isArray(applicableDocuments) && applicableDocuments?.length
      ? applicableDocuments
      : Array.isArray(documents)
        ? documents
        : [];

  const sortedDocuments = partyDocuments?.sort((a, b) => {
    return 0 - moment(a?.createdAt).diff(moment(b?.createdAt));
  });

  const selectedDocuments = yield select((store) =>
    getPartySelectedDocuments(store, partyId),
  );

  yield call(setSelectedDocumentSaga, {
    partyId,
    subjects,
    selectedDocuments,
    sortedDocuments,
  });
}

function* setSelectedDocumentsByDefaultOnPartyUpdate(action) {
  const requirements = R.pathOr(
    {},
    ['payload', 'data', 'documentProcessed', 'partyData', 'requirements'],
    action,
  );
  const _id = R.pathOr(
    {},
    ['payload', 'data', 'documentProcessed', 'partyData', '_id'],
    action,
  );

  const partyDocuments = R.flatten(
    R.append(
      R.pathOr(
        [],
        ['payload', 'data', 'documentProcessed', 'partyData', 'documents'],
        action,
      ),
      R.pathOr(
        [],
        [
          'payload',
          'data',
          'documentProcessed',
          'partyData',
          'applicableDocuments',
        ],
        action,
      ),
    ),
  );

  const filteredPartyDocuments = partyDocuments?.filter((doc) =>
    R.isNil(doc?.archivedAt),
  );

  const sortedDocuments = filteredPartyDocuments?.sort((a, b) => {
    return 0 - moment(a?.createdAt).diff(moment(b?.createdAt));
  });

  const modules = getUniqModules(requirements);
  const subjects = modules.reduce((acc, data) => {
    return acc.concat(getSubjectsByModule(data?.moduleId, requirements));
  }, []);
  const selectedDocuments = yield select((store) =>
    getPartySelectedDocuments(store, _id),
  );
  yield call(setSelectedDocumentSaga, {
    partyId: _id,
    subjects,
    selectedDocuments,
    sortedDocuments,
  });
}

function* subscribeBulkPartiesSaga() {
  const organization = yield select(getActiveOrganizationId);
  if (organization) {
    yield fork(subscribeToPartiesSaga, organization);
  }
}

export function* subscribeToPartiesSaga(organizationId) {
  const bulkPartiesChannel = yield call(() =>
    eventChannel((emit) => {
      const subscription = graphqlClient
        .subscribe({
          query: BULK_PARTIES_ACTIONS_PROCESSED_SUBSCRIPTION,
          variables: {
            organizationId,
          },
        })
        .subscribe({
          next(data) {
            emit(data);
          },
        });

      const unsubscribe = () => {
        subscription.unsubscribe();
      };

      return unsubscribe;
    }),
  );
  // eslint-disable-next-line fp/no-loops
  while (true) {
    try {
      const payload = yield take(bulkPartiesChannel);
      yield fork(updateStateBulkPartiesSubscription, payload);
    } catch (err) {
      console.error('Socket error:', err);
    }
  }
}

function* updateStateBulkPartiesSubscription(payload) {
  const type = R.pathOr('', ['data', 'bulkPartiesProcessed', 'type'], payload);
  const result = R.pathOr(
    '',
    ['data', 'bulkPartiesProcessed', 'data'],
    payload,
  );

  switch (type) {
    case EventType.bulkUpdateActiveStatusPartiesSuccess:
    case EventType.BulkDeletePartiesSuccess:
    case EventType.bulkAssignComplianceProfilePartiesSuccess:
    case EventType.bulkAssignPartyTypePartiesSuccess:
    case EventType.bulkAddTagsPartiesSuccess:
    case EventType.bulkAddPartiesToProjectSuccess:
    case EventType.bulkEnablePartiesContactsRemindersSuccess:
    case EventType.bulkDeleteTagsPartiesSuccess: {
      yield put(setBulkOperationCompleted({ status: true, entity: 'parties' }));
      yield notify.success(result.message || bulkEventDefaultMessage[type]);
      yield call(trackEvent, bulkEventAnalyticsMessage[type]);
      break;
    }
    case EventType.bulkUpdateActiveStatusPartiesFail:
    case EventType.BulkDeletePartiesFail:
    case EventType.bulkAssignComplianceProfilePartiesFail:
    case EventType.bulkAssignPartyTypePartiesFail:
    case EventType.bulkAddTagsPartiesFail:
    case EventType.bulkAddPartiesToProjectFail:
    case EventType.bulkEnablePartiesContactsRemindersFail:
    case EventType.bulkDeleteTagsPartiesFail: {
      yield notify.error(result.message);
      break;
    }
    default:
      return;
  }
}

function* partySagas() {
  yield all([
    takeLatest(SET_AUTHENTICATED_APP_READY, subscribeBulkPartiesSaga),
    takeLatest(
      `${FETCH_PARTY_WITH_DETAILS}_SUCCESS`,
      setSelectedDocumentsByDefault,
    ),
    takeLatest(`${DELETE_PARTY}_SUCCESS`, deletePartyNotificationSaga),
    takeLatest(CREATE_OR_UPDATE_PARTY, createOrUpdatePartySaga),
    takeLatest(FETCH_CREATE_OR_EDIT_PARTY_DATA, fetchCreateOrEditPartyDataSaga),
    takeLatest(`${IMPORT_PARTIES}_FAIL`, importPartiesFailNotificationSaga),
    takeLatest(SEND_REQUEST, sendRequestSaga),
    takeLatest(
      `${ASSIGN_COMPLIANCE_PROFILE}_SUCCESS`,
      selectDefaultDocumentsForProfileSaga,
    ),
    takeLatest(UPDATE_PARTY_STATE, setSelectedDocumentsByDefaultOnPartyUpdate),
  ]);
}

export default partySagas;
