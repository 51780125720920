import { useAbort } from '@common/hooks/useAbort';
import { useMutation } from '@graphql/hooks';
import { graphql } from '@graphql/types';
import type { UploadPortalAttachmentsInput } from '@graphql/types/graphql';

const UPLOAD_PORTAL_ATTACHMENTS_MUTATION = graphql(`
  mutation UploadPortalAttachments($data: UploadPortalAttachmentsInput!) {
    uploadPortalAttachments(data: $data)
  }
`);

export const useUploadPortalAttachments = () => {
  const { getSignal, abort } = useAbort();

  const [uploadPortalAttachments, { loading }] = useMutation(
    UPLOAD_PORTAL_ATTACHMENTS_MUTATION,
  );

  const uploadPortalAttachmentsWithAbort = ({
    variables,
  }: {
    variables: {
      data: UploadPortalAttachmentsInput;
    };
  }) => {
    return uploadPortalAttachments({
      variables,
      context: {
        hasUpload: true,
        fetchOptions: {
          signal: getSignal(),
        },
      },
    });
  };

  return {
    uploadPortalAttachments: uploadPortalAttachmentsWithAbort,
    isUploadingPortalAttachments: loading,
    abortUploadPortalAttachments: abort,
  };
};
