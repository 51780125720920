import { type RefObject, useEffect } from 'react';

export const KEYBOARD_KEYS = {
  ESC: 'Escape',
  ENTER: 'Enter',
  SPACE: ' ',
  ARROW_UP: 'ArrowUp',
  ARROW_DOWN: 'ArrowDown',
} as const;

export const useHotkeys = (
  ref: RefObject<HTMLElement>,
  hotkeys: string,
  callback?: (event: KeyboardEvent) => void,
) => {
  useEffect(() => {
    if (!callback) return;

    const handleKeyDown = (event: KeyboardEvent) => {
      const isButton = event.target instanceof HTMLButtonElement;

      if (event.key === hotkeys && !isButton) {
        callback(event);
      }
    };

    ref.current?.addEventListener('keydown', handleKeyDown);

    return () => {
      ref.current?.removeEventListener('keydown', handleKeyDown);
    };
  }, [hotkeys, callback, ref]);
};
