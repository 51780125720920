import type {
  PrimaryRecord,
  PrimaryRecordContact,
} from '@graphql/types/graphql';

export const getValidContacts = (
  contacts: PrimaryRecordContact[] | null = [],
) => {
  return contacts?.filter(({ email }) => email) || [];
};

export const getRecipientIdsMap = (
  recipientsMap: Record<string, PrimaryRecordContact[]>,
) => {
  return Object.keys(recipientsMap).reduce<Record<string, string[]>>(
    (recipientIdsMap, primaryRecordId) => {
      const contactIds = recipientsMap[primaryRecordId].map<string>(
        ({ _id }) => _id,
      );

      if (contactIds.length) {
        recipientIdsMap[primaryRecordId] = contactIds;
      }

      return recipientIdsMap;
    },
    {},
  );
};

export const getDefaultRecipientIdsMap = (
  primaryRecords: PrimaryRecord[],
): Record<string, PrimaryRecordContact[]> =>
  primaryRecords.reduce<Record<string, PrimaryRecordContact[]>>(
    (map, { contacts, _id }) => {
      const validContacts = getValidContacts(contacts?.nodes);
      const defaultRecipients = validContacts.filter(
        ({ defaultRequestRecipient }) => defaultRequestRecipient,
      );
      map[_id] = defaultRecipients;
      return map;
    },
    {},
  );
