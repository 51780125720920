import { WarningFilled } from '@ant-design/icons';
import { Button, Popconfirm, Typography } from 'antd';
import styled from 'styled-components';

interface FilePillProps {
  file: {
    friendlyName: string;
    s3Key: string;
  };
  isDownloadable?: boolean;
  onDelete?: () => void;
  isDeleting?: boolean;
  onCancel?: () => void;
  onClick?: () => void;
}

export const FilePill = ({
  file,
  isDownloadable = true,
  onDelete,
  onClick,
  onCancel,
  isDeleting = false,
}: FilePillProps) => {
  return (
    <StyledButtonGroup key={file.s3Key}>
      {isDownloadable && (
        <StyledLink shape="round" onClick={onClick}>
          <EllipsisText>{file.friendlyName}</EllipsisText>
        </StyledLink>
      )}
      {onDelete && (
        <Popconfirm
          title="Delete the file"
          description="Are you sure to delete this file?"
          okText="Yes"
          cancelText="No"
          onConfirm={onDelete}
          okButtonProps={{ loading: isDeleting, danger: true }}
          cancelButtonProps={{ disabled: isDeleting }}
          onCancel={onCancel}
          icon={<WarningFilled style={{ color: 'red' }} />}
        >
          <StyledDeleteButton shape="round" danger>
            <span>delete</span>
          </StyledDeleteButton>
        </Popconfirm>
      )}
    </StyledButtonGroup>
  );
};

const StyledLink = styled(Button)`
  flex: 1;
  min-width: 0;
  border: 1px solid ${({ theme }) => theme.colors.lightGray};
`;

const StyledDeleteButton = styled(Button)`
  border: 1px solid ${({ theme }) => theme.colors.lightGray} !important;
  border-left: 1px dotted ${({ theme }) => theme.colors.lightGray} !important;
  text-align: left;
`;

const StyledButtonGroup = styled(Button.Group)`
  max-width: 100%;
  display: flex;

  span {
    width: 100%;
    text-align: left;
  }
`;

const EllipsisText = styled(Typography.Text)`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
