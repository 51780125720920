import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ModalConfirm } from '@common/components/ModalConfirm';
import type { ServerFilterInput } from '@common/constants/filters/serverFilters';
import { BulkOperationStatus } from '@graphql/types/graphql';
import { useIsComplianceRequirementsAddOnEnabled } from '@modules/add-on';
import { useArchivePrimaryRecordsMutation } from '@modules/primary-records/hooks/useArchivePrimaryRecordsMutation';
import { Typography, message } from 'antd';
import { isNil } from 'lodash';

type ArchivePrimaryRecordsModalProps = {
  open: boolean;
  count: number;
  filters?: ServerFilterInput;
  primaryObject: {
    name: string;
    pluralName: string;
  };
  totalDocumentsArchived?: number;
  onCancel?: () => void;
  onCompleted?: () => void;
  onScheduled?: () => void;
  onFailed?: () => void;
};

export const ArchivePrimaryRecordsModal = ({
  open,
  count = 1,
  primaryObject,
  filters,
  totalDocumentsArchived,
  onCancel,
  onCompleted,
  onScheduled,
  onFailed,
}: ArchivePrimaryRecordsModalProps) => {
  const { isComplianceRequirementsAddOnEnabled } =
    useIsComplianceRequirementsAddOnEnabled();

  const { archivePrimaryRecords, isArchivingPrimaryRecords } =
    useArchivePrimaryRecordsMutation();

  const handleArchive = async () => {
    const res = await archivePrimaryRecords(filters);

    const status = res.data?.archivePrimaryRecords.operation.status;

    if (status === BulkOperationStatus.Completed) {
      message.success(
        `${count} ${count > 1 ? `${primaryObject.pluralName} have been` : `${primaryObject.name} has been`} archived successfully.`,
      );
      onCompleted?.();
    }

    if (status === BulkOperationStatus.Scheduled) {
      message.success(
        `${count} ${count > 1 ? `${primaryObject.pluralName} have been` : `${primaryObject.name} has been`} scheduled for archiving.`,
      );
      onScheduled?.();
    }

    if (status === BulkOperationStatus.Failed) {
      message.error(
        `The archiving of your ${count > 1 ? `${primaryObject.pluralName} have been` : `${primaryObject.name} has been`} failed. Please try again or contact support.`,
      );
      onFailed?.();
    }
  };

  const name =
    count > 1 ? `${primaryObject.pluralName}` : `${primaryObject.name}`;

  return (
    <ModalConfirm
      data-cy="archive-primary-records-modal"
      open={open}
      icon={<ExclamationCircleOutlined />}
      okButtonProps={{
        danger: true,
        loading: isArchivingPrimaryRecords,
      }}
      okText="Archive"
      title={`Are you sure you want to archive ${count} ${name}?`}
      onCancel={onCancel}
      onOk={handleArchive}
    >
      <Typography.Paragraph>
        Please confirm that you want to archive {count} {name}
      </Typography.Paragraph>
      <ul>
        <li>
          They will not show up in your reports
          {isComplianceRequirementsAddOnEnabled ? ' and dashboard' : ''}
        </li>
        <li>No automatic requests for expired documents will be sent</li>
        <li>
          All associated documents will be archived{' '}
          {isNil(totalDocumentsArchived) ? '' : `(${totalDocumentsArchived})`}
        </li>
      </ul>
    </ModalConfirm>
  );
};
