import { useWorkspacesPageFeatureFlag } from '@modules/feature-flags/hooks';
import WorkspacesPage from '@pages/Workspaces';
import { Navigate, useParams } from 'react-router-dom';

export const WorkspacePageGuard = () => {
  const { isWorkspacesPageFeatureFlagEnabled } = useWorkspacesPageFeatureFlag();
  const { organizationSlugifyName } = useParams();

  return isWorkspacesPageFeatureFlagEnabled ? (
    <WorkspacesPage />
  ) : (
    <Navigate to={`/${organizationSlugifyName}/dashboard`} />
  );
};
