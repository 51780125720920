import type { Requirement, Rule } from '@graphql/types/graphql';

export type GroupingNode =
  | Pick<Rule, 'moduleId' | 'subjectLabel' | 'subjectId'>
  | Pick<Requirement, 'moduleId' | 'subjectLabel' | 'subjectId'>;

export type ModuleSubject<R extends GroupingNode> = {
  subjectLabel: string;
  subjectId: string;
} & {
  children: R[];
};

export type ModuleItem<Node extends GroupingNode> = Node & {
  subjects: ModuleSubject<Node>[];
};

/**
 * Group rules or requirements by module and subject.
 * @param nodes - The nodes to group
 * @returns Will return a list of modules, each module contains a list of subjects, and each subject children nodes.
 */
export function groupByModule<Node extends GroupingNode>(
  nodes: Node[],
): ModuleItem<Node>[] {
  const modules = new Map<
    string,
    GroupingNode & {
      children: Map<string, ModuleSubject<Node>>;
    }
  >();

  nodes.forEach((node) => {
    const { moduleId, subjectLabel, subjectId } = node;
    if (!moduleId) {
      return;
    }

    let moduleNode = modules.get(moduleId);
    if (!moduleNode) {
      moduleNode = {
        ...node,
        children: new Map<string, ModuleSubject<Node>>(),
      };
      modules.set(moduleId, moduleNode);
    }

    const { children } = moduleNode;
    if (!subjectId) return;

    let subjectNode = children.get(subjectId);
    if (!subjectNode) {
      subjectNode = {
        subjectLabel: subjectLabel || '',
        subjectId: subjectId || '',
        children: [],
      };
      children.set(subjectId, subjectNode);
    }

    subjectNode.children.push(node);
  });

  const resultModules: ModuleItem<Node>[] = [];

  for (const { children, ...rest } of modules.values()) {
    resultModules.push({
      ...rest,
      subjects: Array.from(children.values()),
    } as ModuleItem<Node>);
  }

  return resultModules;
}
