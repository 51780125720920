import { Tag } from 'antd';
import styled from 'styled-components';

import CheckIcon from '@common/icons/CheckIcon';
import MarkIcon from '@common/icons/MarkIcon';
import { groupByModule } from '@common/utils/group-by-module';
import { getRequirementsComplianceStats } from '@modules/requirement/utils/requirement-helpers';

const ComplianceModules = ({ requirements, redirectToCompliance }) => (
  <ComplianceModules.Wrapper>
    {groupByModule(requirements).map((moduleData, index) => (
      <ComplianceModules.ModuleWrapper
        data-cy="moduleWrapper"
        key={moduleData.moduleId}
      >
        <ComplianceModules.ModuleTitle>
          {moduleData.moduleLabel}
        </ComplianceModules.ModuleTitle>
        <ComplianceModules.SubjectTags $isFirst={index === 0}>
          {moduleData.subjects.map((subjectData) => {
            const isCompliant =
              getRequirementsComplianceStats(subjectData.children)
                .nonCompliantRequirementsCount === 0;

            return (
              <ComplianceModules.Tag
                data-cy={`complianceModuleTag-${subjectData.subjectId}-${
                  isCompliant ? 'compliant' : 'nonCompliant'
                }`}
                color={isCompliant ? null : 'red'}
                key={subjectData.subjectId}
                style={{
                  background: isCompliant ? 'white' : null,
                }}
                onClick={() => redirectToCompliance(subjectData.subjectId)}
              >
                <ComplianceModules.IconWrapper>
                  {isCompliant ? <CheckIcon /> : <MarkIcon />}
                </ComplianceModules.IconWrapper>
                {subjectData.subjectLabel}
              </ComplianceModules.Tag>
            );
          })}
        </ComplianceModules.SubjectTags>
      </ComplianceModules.ModuleWrapper>
    ))}
  </ComplianceModules.Wrapper>
);

ComplianceModules.Wrapper = styled.div`
  margin-top: 10px;
  margin-left: 26px;
`;

ComplianceModules.ModuleWrapper = styled.div`
  display: flex;
`;

ComplianceModules.ModuleTitle = styled.div`
  display: flex;
  flex: 2;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  padding: 10px 20px 10px 0;
`;

ComplianceModules.SubjectTags = styled.div`
  display: flex;
  flex: 5;
  flex-wrap: wrap;
  padding: 10px 0 0;
  border-top: ${(props) => (props.$isFirst ? 'none' : '1px solid #ebeef5')};
`;

ComplianceModules.Tag = styled(Tag)`
  && {
    margin-bottom: 10px;
    height: 26px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
`;

ComplianceModules.IconWrapper = styled.span`
  margin-right: 8px;
  display: flex;
  align-items: center;
`;

export default ComplianceModules;
