import Flex from '@common/components/Flex';
import {
  ComplianceSummary,
  ActionKeys as ComplianceSummaryActionKeys,
} from '@modules/request-records';
import { AssignComplianceProfileModal } from '@modules/request-records/containers/AssignComplianceProfileModal';
import type { RequestRecordsRoutesParams } from '@modules/router/types';
import { FullWidthSpace } from '@trustlayer/ui';
import { Card, Result } from 'antd';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useRequestRecordComplianceQuery } from './hooks/useRequestRecordComplianceQuery';

export function RequestRecordChecklist() {
  const [
    isAssignComplianceProfileModalVisible,
    setIsAssignComplianceProfileModalVisible,
  ] = useState(false);

  const { requestId } = useParams<RequestRecordsRoutesParams>();
  const {
    requestRecord,
    loadingRequestRecordCompliance,
    errorRequestRecordCompliance,
    refetchRequestRecordCompliance,
  } = useRequestRecordComplianceQuery({ requestId });
  const complianceProfile = requestRecord?.complianceProfile;

  if (errorRequestRecordCompliance) {
    return (
      <StyledErrorWrapper direction="vertical" justify="center">
        <Result
          status="500"
          title="500"
          subTitle="Oops! Something went wrong. Please try again later."
        />
      </StyledErrorWrapper>
    );
  }

  const handleComplianceSummaryActionClick = (
    actionKey: ComplianceSummaryActionKeys,
  ) => {
    if (
      actionKey === ComplianceSummaryActionKeys.edit ||
      actionKey === ComplianceSummaryActionKeys.assign
    ) {
      setIsAssignComplianceProfileModalVisible(true);
    }
  };
  return (
    <>
      {requestId && (
        <>
          <AssignComplianceProfileModal
            isShow={isAssignComplianceProfileModalVisible}
            requestRecordId={requestId}
            complianceProfile={complianceProfile!}
            onSuccess={() => {
              setIsAssignComplianceProfileModalVisible(false);
              refetchRequestRecordCompliance();
            }}
            onClose={() => setIsAssignComplianceProfileModalVisible(false)}
          />
        </>
      )}
      <FullWidthSpace size="small" direction="vertical">
        <ComplianceSummary
          hasProfile={Boolean(complianceProfile)}
          isCustomComplianceProfile={Boolean(
            complianceProfile?.baseComplianceProfile,
          )}
          name={complianceProfile?.name}
          onActionClick={handleComplianceSummaryActionClick}
          loading={loadingRequestRecordCompliance}
        />

        {loadingRequestRecordCompliance ? (
          <>
            <Card loading />
            <Card loading />
            <Card loading />
          </>
        ) : (
          // TODO: Checklist👇
          <></>
        )}
      </FullWidthSpace>
    </>
  );
}

const StyledErrorWrapper = styled(Flex)`
  height: 100%;
`;
