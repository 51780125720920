export const DOCUMENT_REQUEST_PLACEHOLDER_VALUES = {
  viewRequestUrl: 'viewRequestUrl',
  requestor: 'requestor',
  webFormUrl: 'WebFormUrl',
  viewPaymentUrl: 'viewPaymentUrl',
} as const;

export const documentRequestPlaceholders = (
  isEmbeddedInsuranceAddOnEnabled: boolean,
) => ({
  title: 'Document request',
  placeholders: [
    {
      name: 'Document request page URL',
      region: `{{ ${DOCUMENT_REQUEST_PLACEHOLDER_VALUES.viewRequestUrl} }}`,
    },
    {
      name: 'Requestor',
      region: `{{ ${DOCUMENT_REQUEST_PLACEHOLDER_VALUES.requestor} }}`,
    },
    {
      name: 'Web form URL',
      region: `{{ ${DOCUMENT_REQUEST_PLACEHOLDER_VALUES.webFormUrl} }}`,
    },
    ...(isEmbeddedInsuranceAddOnEnabled
      ? [
          {
            name: 'Embedded insurance payment URL',
            region: `{{ ${DOCUMENT_REQUEST_PLACEHOLDER_VALUES.viewPaymentUrl} }}`,
          },
        ]
      : []),
  ],
});
