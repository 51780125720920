import { FilePill } from '@common/components/FilePill';
import RichTextEditor from '@common/components/RichTextEditor';
import {
  getEditorHTMLContent,
  getEmptyEditorState,
  hasVisibleText,
  isEditorStateBlank,
  setInitialEditorStateFromHTML,
} from '@common/components/RichTextEditor/helpers';
import { downloadFile } from '@common/utils/document-helpers';
import { useSignedUrl } from '@modules/context-records/containers/AttachmentsTable/hooks/useSignedUrl';
import {
  useDeletePortalAttachment,
  useUpdatePortalMessage,
} from '@modules/portal';
import { Button, Space, Typography, message } from 'antd';
import type { EditorState } from 'draft-js';
import { useCallback, useLayoutEffect, useState } from 'react';
import styled from 'styled-components';
import { UploadAttachmentModal } from '../UploadAttachmentModal';
import { usePortalData } from './hooks/usePortalData';

export const SidebarPortal = ({ recordId }: { recordId: string }) => {
  const { data, refetchPortalData, loading, fetchMoreAttachments } =
    usePortalData({ recordId });
  const { updatePortalMessage, isUpdatingPortalMessage } =
    useUpdatePortalMessage(recordId);
  const { deletePortalAttachment, isDeletingPortalAttachment } =
    useDeletePortalAttachment(recordId);
  const { signUrl } = useSignedUrl();

  const [isUploadAttachmentModalOpen, setIsUploadAttachmentModalOpen] =
    useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editorState, setEditorState] = useState(getEmptyEditorState());

  useLayoutEffect(() => {
    if (data?.message) {
      setEditorState(setInitialEditorStateFromHTML(data.message));
    }
  }, [data?.message]);

  const handleSaveEdit = useCallback(async () => {
    try {
      await updatePortalMessage(
        isEditorStateBlank(editorState)
          ? null
          : getEditorHTMLContent(editorState),
      );
      await refetchPortalData();
      setIsEditing(false);
      message.success('Portal message saved successfully');
    } catch (error) {
      message.error('Error while saving portal message, please try again');
    }
  }, [editorState, updatePortalMessage, refetchPortalData]);

  const handleCloseUploadAttachmentModal = () => {
    setIsUploadAttachmentModalOpen(false);
  };

  const handleCompleteUploadAttachmentModal = () => {
    setIsUploadAttachmentModalOpen(false);
    refetchPortalData();
  };

  const handleDeleteAttachment = async (s3Key: string) => {
    await deletePortalAttachment(s3Key);
    await refetchPortalData();
  };

  const handleDownloadAttachment = async (
    s3Key: string,
    friendlyName: string,
  ) => {
    try {
      const { data } = await signUrl({ variables: { s3Key } });

      downloadFile(data?.signedUrl, friendlyName);
    } catch (error) {
      console.error('Error downloading attachment:', error);
    }
  };

  const isLoadMoreAttachmentsButtonVisible =
    Boolean(data?.attachments?.totalCount) &&
    (data?.attachments?.totalCount ?? 0) >
      (data?.attachments?.nodes?.length ?? 0);

  return (
    <>
      <UploadAttachmentModal
        open={isUploadAttachmentModalOpen}
        primaryRecordId={recordId}
        onCancel={handleCloseUploadAttachmentModal}
        onCompleted={handleCompleteUploadAttachmentModal}
      />
      <StyledWrapper>
        <StyledSectionLabel strong>Optional message</StyledSectionLabel>
        {isEditing || hasVisibleText(data?.message) ? (
          <>
            <RichTextEditor
              minHeight={32}
              maxHeight={150}
              editorState={editorState}
              disableInlineStyleButtons={false}
              focusDependence={false}
              isOneLineEditor={false}
              isValid
              placeholder="Add message..."
              onChange={(e: EditorState) => setEditorState(e)}
            />
            <Space direction="horizontal" align="center">
              <Button
                loading={isUpdatingPortalMessage || loading}
                type="primary"
                onClick={handleSaveEdit}
              >
                Save message
              </Button>
            </Space>
          </>
        ) : (
          <Button onClick={() => setIsEditing(true)}>Add message</Button>
        )}
        <StyledSectionLabel strong>Attachments</StyledSectionLabel>
        <Space direction="vertical">
          {data?.attachments?.nodes.map((attachment) => (
            <FilePill
              key={attachment.s3Key}
              file={attachment}
              isDownloadable
              isDeleting={isDeletingPortalAttachment}
              onDelete={() => handleDeleteAttachment(attachment.s3Key)}
              onClick={() =>
                handleDownloadAttachment(
                  attachment.s3Key,
                  attachment.friendlyName,
                )
              }
            />
          ))}
          {isLoadMoreAttachmentsButtonVisible && (
            <Button
              type="link"
              loading={loading}
              onClick={fetchMoreAttachments}
            >
              Load more
            </Button>
          )}
        </Space>
        <Button onClick={() => setIsUploadAttachmentModalOpen(true)}>
          Upload Attachments
        </Button>
      </StyledWrapper>
    </>
  );
};

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const StyledSectionLabel = styled(Typography.Text)`
  color: ${({ theme }) => theme.colors.grayText};
`;
