import { Modal, message } from 'antd';
import isEmpty from 'lodash/isEmpty';
import * as R from 'ramda';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import uuidByString from 'uuid-by-string';

import { ComplianceProfileContext } from '@modules/compliance-profile/constants';
import { getModulesData } from '@modules/compliance-profile/utils/compliance-attributes-helpers';
import SelectFillableFormModal from '@modules/fillable-form/components/SelectFillableFormModal';
import { FILLABLE_FORMS_MODULE_ID } from '@modules/fillable-form/constants';
import { getOrganizationNamespaceUrl } from '@modules/organization/selectors';
import { fetchTemplates } from '@modules/requirement/actions';
import CustomComplianceModal from '@modules/requirement/components/CustomComplianceModal';
import { getTemplates } from '@modules/requirement/selectors';
import SelectSurveyModal from '@modules/surveys/components/SelectSurveyModal';
import { SURVEY_MODULE_ID } from '@modules/surveys/constants';

import Spinner from '@common/components/Spinner';
import { setIsChangingComplianceProfile } from '@modules/party/actions';

import { withControlledMount } from '@common/hoc/withControlledMount';
import { trackEvent } from '@common/utils/track-helpers';
import {
  createComplianceProfile,
  setCurrentComplianceProfile,
  setCurrentDocumentChecklists,
  setCurrentRequirements,
} from '@modules/compliance-profile/actions';
import { useAssignComplianceProfileMutation } from '@modules/request-records/hooks/useAssignComplianceProfileMutation';
import ComplianceProfileEditor from '../../components/ComplianceProfileEditor';
import ComplianceProfileViewDrawer from '../../components/ComplianceProfileViewDrawer';
import SelectDocumentChecklistModal from '../../components/SelectDocumentChecklistModal';
import { AttributeType, DefaultRule, Operator } from '../../constants';
import {
  getCurrentComplianceProfile,
  getCurrentDocumentChecklists,
  getCurrentRequirements,
} from '../../selectors';
import ComplianceDrawerTitleContainer from '../ComplianceDrawerTitleContainer';
import { useLazyComplianceProfile } from './hooks';

/**
 * This is a copy of the ComplianceProfileContainer component, just adapted to work on request record.
 * Every reference to old entities such as party is kept as it is intentionally.
 * We will refactor it later, once we have a new UI/UX to work on.
 */
const ComplianceProfileDrawer = ({
  complianceProfileId,
  requestRecordId,
  partyName,
  open,
  onClose,
  onSaved,
}) => {
  const [activeKeyTab, setActiveKeyTab] = useState('');
  const [isCustom, setIsCustom] = useState(false);
  const [complianceProfileName, setComplianceProfileName] = useState('');
  const [selectedComplianceProfile, setSelectedComplianceProfile] = useState();
  const [isInit, setIsInit] = useState(false);
  // Modals
  const [isVisibleAddModuleModal, setIsVisibleAddModuleModal] = useState(false);
  const [editModuleId, setEditModuleId] = useState('');
  const [editModuleValue, setEditModuleValue] = useState('');
  const [isVisibleAddSubjectModal, setIsVisibleAddSubjectModal] =
    useState(false);
  const [editSubjectId, setEditSubjectId] = useState('');
  const [editSubjectValue, setEditSubjectValue] = useState('');
  const [selectedSubjectData, setSelectedSubjectData] = useState({});
  const [isVisibleAddChecklistModal, setIsVisibleAddChecklistModal] =
    useState(false);
  const [isVisibleAddFillableFormModal, setIsVisibleAddFillableFormModal] =
    useState(false);
  const [isVisibleSelectSurveyModal, setIsVisibleSelectSurveyModal] =
    useState(false);

  const templates = useSelector(getTemplates);

  const currentComplianceProfile = useSelector(getCurrentComplianceProfile);
  const currentRequirements = useSelector(getCurrentRequirements);
  const currentDocumentChecklists = useSelector(getCurrentDocumentChecklists);
  const organizationNamespace = useSelector(getOrganizationNamespaceUrl);

  const { complianceModules } = getModulesData(currentRequirements, templates);

  const dispatch = useDispatch();

  const { assignComplianceProfile, isAssignComplianceProfileLoading } =
    useAssignComplianceProfileMutation();

  const {
    complianceProfile,
    fetchComplianceProfile,
    isComplianceProfileLoading,
  } = useLazyComplianceProfile();

  useEffect(() => {
    if (!complianceProfileId) {
      return;
    }

    fetchAndSetCurrentData(complianceProfileId);
  }, [complianceProfileId]);

  useEffect(() => {
    if (isEmpty(templates)) {
      dispatch(fetchTemplates);
    }
  }, [dispatch, templates]);

  useEffect(() => {
    setComplianceProfileName(R.propOr('', 'name', currentComplianceProfile));

    if (isEmpty(currentComplianceProfile)) {
      setSelectedComplianceProfile();
    } else {
      setSelectedComplianceProfile({
        _id: currentComplianceProfile?._id,
        name: currentComplianceProfile?.name,
        baseComplianceProfile: currentComplianceProfile?.baseComplianceProfile,
      });
    }
  }, [currentComplianceProfile]);

  const isEdited = useMemo(() => {
    const rulesHaveChanged = !R.equals(
      currentRequirements,
      R.propOr([], 'rules', currentComplianceProfile),
    );

    const checklistsHaveChanged = !R.equals(
      currentDocumentChecklists,
      R.propOr([], 'documentChecklists', currentComplianceProfile),
    );

    return rulesHaveChanged || checklistsHaveChanged;
  }, [
    currentComplianceProfile,
    currentDocumentChecklists,
    currentRequirements,
  ]);

  /**
   * Define custom compliance profile.
   */
  // biome-ignore lint/correctness/useExhaustiveDependencies: Legacy
  useEffect(() => {
    if (isEdited && !isCustom && Boolean(complianceProfile)) {
      setIsCustom(true);
      setSelectedComplianceProfile((selectedProfile) => ({
        ...selectedProfile,
        baseComplianceProfile: null,
      }));
      dispatch(
        setCurrentComplianceProfile(
          R.compose(
            R.assoc(
              'baseComplianceProfile',
              R.propOr(
                R.prop('_id', currentComplianceProfile),
                'baseComplianceProfile',
                currentComplianceProfile,
              ),
            ),
            R.assoc('_id', null),
          )(currentComplianceProfile),
        ),
      );
    }
  }, [
    currentComplianceProfile,
    currentRequirements,
    currentDocumentChecklists,
  ]);

  /**
   * Reset state.
   */
  useEffect(() => {
    if (!open) {
      setIsCustom(false);
      dispatch(setCurrentComplianceProfile({}));
      dispatch(setCurrentRequirements([]));
      dispatch(setCurrentDocumentChecklists([]));
    }
  }, [dispatch, open]);

  /**
   * Open modals if edit values have changed.
   */
  useEffect(() => {
    if (editSubjectValue) {
      setIsVisibleAddSubjectModal(true);
    }
    if (editModuleValue) {
      setIsVisibleAddModuleModal(true);
    }
  }, [editModuleValue, editSubjectValue]);

  const setRequirements = (requirements) =>
    dispatch(setCurrentRequirements(requirements));

  const setComplianceProfile = (profile) =>
    dispatch(setCurrentComplianceProfile(profile));

  const onSaveComplianceProfile = async () => {
    let complianceProfileData = R.assoc(
      'name',
      complianceProfileName,
      currentComplianceProfile,
    );

    setComplianceProfile(complianceProfileData);

    dispatch(setIsChangingComplianceProfile(true));

    if (!complianceProfileData._id) {
      const rulesData = currentRequirements
        .filter((rule) => Boolean(rule.attributeId))
        .map((rule) =>
          R.omit(['_id', 'complianceProfile', 'updatedAt', 'createdAt'], rule),
        );
      const documentChecklistsData = currentDocumentChecklists.map(
        (checklist) => R.omit(['_id', 'createdAt', 'updatedAt'], checklist),
      );

      const res = await dispatch(
        createComplianceProfile({
          ...R.omit(
            [
              '_id',
              'createdAt',
              'updatedAt',
              'context',
              'deletedAt',
              'deletedBy',
              'partiesCount',
              'projectsCount',
            ],
            currentComplianceProfile,
          ),
          context: ComplianceProfileContext.RequestRecord,
          contextId: requestRecordId,
          rules: rulesData,
          documentChecklists: documentChecklistsData.map((checklist) => ({
            ...checklist,
            type: checklist.type._id,
          })),
        }),
      );
      complianceProfileData = R.path(
        ['payload', 'data', 'createComplianceProfile'],
        res,
      );

      await trackEvent(
        'User assigned a compliance profile to a Request record',
      );
    }

    await assignComplianceProfile({
      variables: {
        data: {
          id: complianceProfileData?._id,
          requestRecords: [
            {
              requestRecordId,
            },
          ],
        },
      },
    });
    await trackEvent("User updated a Request record's compliance profile");

    dispatch(setIsChangingComplianceProfile(false));
    dispatch(setCurrentComplianceProfile(complianceProfileData));
    dispatch(setCurrentRequirements(complianceProfileData.rules));
    dispatch(
      setCurrentDocumentChecklists(complianceProfileData.documentChecklists),
    );

    message.success('Compliance profile successfully assigned to Request');

    onSaved();
  };

  const showConfirmClose = () => {
    if (currentComplianceProfile.name !== complianceProfileName || isEdited) {
      Modal.confirm({
        title: 'There are unsaved changes. Do you want to save your changes?',
        okText: 'Save changes',
        cancelText: 'Close without saving',
        onOk: () => onSaveComplianceProfile(),
        width: 480,
        onCancel: () => {
          onClose?.();
        },
        zIndex: 1005,
      });
    } else {
      onClose?.();
    }
  };

  const handleOnSendModule = (
    moduleLabel,
    editModuleValue,
    setIsVisibleAddModuleModal,
    setEditModuleValue,
  ) => {
    moduleLabel = moduleLabel?.trim();

    if (editModuleValue) {
      const requirementsToUpdate = currentRequirements.map((requirement) => {
        if (requirement.moduleId === editModuleId) {
          return { ...requirement, moduleLabel };
        }
        return requirement;
      });
      setRequirements(requirementsToUpdate);
      setIsVisibleAddModuleModal(false);
      setEditModuleValue('');
      setEditModuleId('');
      return;
    }

    setRequirements(
      R.append(
        {
          ...DefaultRule,
          moduleId: uuidByString(moduleLabel?.toLowerCase()),
          moduleLabel,
        },
        currentRequirements,
      ),
    );
    setIsVisibleAddModuleModal(false);
  };

  const handleOnSendSubject = (
    subjectLabel,
    editSubjectValue,
    setIsVisibleAddSubjectModal,
    setEditSubjectValue,
    moduleId,
    moduleLabel,
  ) => {
    subjectLabel = subjectLabel?.trim();

    if (editSubjectValue) {
      const requirementsToUpdate = currentRequirements.map((requirement) => {
        if (requirement.subjectId === editSubjectId) {
          return { ...requirement, subjectLabel };
        }
        return requirement;
      });
      setRequirements(requirementsToUpdate);
      setIsVisibleAddSubjectModal(false);
      setEditSubjectValue('');
      setEditSubjectId('');
      return;
    }

    const definitionWithoutSubjectIndex = R.findIndex(
      (x) => x.moduleId === moduleId && !Boolean(x.subjectId),
      currentRequirements,
    );

    const requirement = {
      ...DefaultRule,
      moduleId,
      moduleLabel,
      subjectId: uuidByString(moduleId.concat(subjectLabel?.toLowerCase())),
      subjectLabel,
    };

    if (definitionWithoutSubjectIndex > -1) {
      setRequirements(
        R.update(
          definitionWithoutSubjectIndex,
          requirement,
          currentRequirements,
        ),
      );
    } else {
      setRequirements(R.append(requirement, currentRequirements));
    }
    setIsVisibleAddSubjectModal(false);
  };

  const handleOnSelectFillableForm = (form) => {
    const uniqAnnotationAssociations = R.compose(
      R.keys,
      R.indexBy(R.path(['customData', 'association'])),
      R.filter(R.has('customData')),
      R.propOr([], 'annotations'),
    )(form);

    const formRequirements = uniqAnnotationAssociations.map((association) => {
      const associationLabel = association === 'party' ? 'Party' : 'Requester';

      return {
        ...DefaultRule,
        moduleId: FILLABLE_FORMS_MODULE_ID,
        moduleLabel: 'Fill & Sign',
        subjectId: form._id,
        subjectLabel: form.name,
        attributeId: `${FILLABLE_FORMS_MODULE_ID}${form._id}Form${associationLabel}`,
        attributeLabel: 'Form',
        operator: 'must be completed by',
        targetValue: association,
        attributeType: 'fillableForm',
        masterDocumentAttributeId: `${form._id}Form${associationLabel}`,
      };
    });

    setRequirements(R.append(formRequirements, currentRequirements));

    setIsVisibleAddFillableFormModal(false);
  };

  const editor = (
    <ComplianceProfileEditor
      activeKeyTab={activeKeyTab}
      organizationNamespace={organizationNamespace}
      isParty={true}
      isCustomField={false}
      showConfirmClose={showConfirmClose}
      currentDocumentChecklists={currentDocumentChecklists}
      complianceProfile={currentComplianceProfile}
      setActiveKeyTab={setActiveKeyTab}
      party={{ name: partyName }}
      setIsVisibleAddModuleModal={setIsVisibleAddModuleModal}
      setIsVisibleAddChecklistModal={setIsVisibleAddChecklistModal}
      setIsVisibleAddFillableFormModal={setIsVisibleAddFillableFormModal}
      openSelectSurveyModal={() => setIsVisibleSelectSurveyModal(true)}
      setEditSubjectId={setEditSubjectId}
      setEditSubjectValue={setEditSubjectValue}
      setEditModuleId={setEditModuleId}
      setEditModuleValue={setEditModuleValue}
      setIsVisibleAddSubjectModal={setIsVisibleAddSubjectModal}
      setSelectedSubjectData={setSelectedSubjectData}
    />
  );

  const requirementSubjects = currentRequirements.map(
    (requirement) => requirement.subjectId,
  );

  const handleOnSelectSurvey = (surveyId, title) => {
    const surveyRequirement = {
      ...DefaultRule,
      moduleId: SURVEY_MODULE_ID,
      moduleLabel: 'Surveys',
      subjectId: surveyId,
      subjectLabel: title,
      attributeId: `${SURVEY_MODULE_ID}${surveyId}`,
      attributeLabel: 'Survey',
      attributeType: AttributeType.Connected,
      masterDocumentAttributeId: surveyId,
      operator: Operator.PRESENT,
    };
    setRequirements(R.append(surveyRequirement, currentRequirements));
  };

  const fetchAndSetCurrentData = async (complianceProfileId) => {
    const { data } = await fetchComplianceProfile(complianceProfileId);

    dispatch(setCurrentComplianceProfile(data.getComplianceProfile));
    dispatch(setCurrentRequirements(data.getComplianceProfile?.rules ?? []));
    dispatch(
      setCurrentDocumentChecklists(
        data.getComplianceProfile?.documentChecklists ?? [],
      ),
    );
  };

  const handleOnSelectedComplianceProfileChange = async (
    selectedComplianceProfile,
  ) => {
    setSelectedComplianceProfile(selectedComplianceProfile);
    await fetchAndSetCurrentData(selectedComplianceProfile._id);
    setIsCustom(false);
  };

  if (!currentComplianceProfile) {
    return <Spinner />;
  }

  return (
    <>
      <ComplianceProfileViewDrawer
        isShow={open}
        showConfirmClose={showConfirmClose}
        title={
          <ComplianceDrawerTitleContainer
            // pass `party` as object since ComplianceDrawerTitleContainer will check for its existance in order to show the right UI for this use case
            party={{
              name: partyName,
            }}
            organizationNamespace={organizationNamespace}
            // pass the complianceProfileId so that the ComplianceProfilesSelect renders the Skeleton until it fetches the name
            selectedComplianceProfile={
              selectedComplianceProfile || complianceProfileId
            }
            complianceProfileId={currentComplianceProfile?._id}
            isParty={true}
            onSaveComplianceProfile={onSaveComplianceProfile}
            showConfirmClose={showConfirmClose}
            onSelectedComplianceProfileChange={
              handleOnSelectedComplianceProfileChange
            }
            loadingComplianceProfile={isComplianceProfileLoading}
          />
        }
      >
        {isComplianceProfileLoading ? <Spinner /> : editor}
      </ComplianceProfileViewDrawer>

      <SelectDocumentChecklistModal
        visible={isVisibleAddChecklistModal}
        setIsVisible={setIsVisibleAddChecklistModal}
      />
      <CustomComplianceModal
        open={isVisibleAddModuleModal}
        okText="Add module"
        title="Add new module"
        initialValue={editModuleValue}
        onSend={(moduleLabel) =>
          handleOnSendModule(
            moduleLabel,
            editModuleValue,
            setIsVisibleAddModuleModal,
            setEditModuleValue,
          )
        }
        onCancel={() => setIsVisibleAddModuleModal(false)}
        cancelText="Cancel"
        description="Custom module name:"
        zIndex={1100}
      />
      <CustomComplianceModal
        open={isVisibleAddSubjectModal}
        okText={editSubjectValue ? 'Save' : 'Add requirement group'}
        title={
          editSubjectValue
            ? 'Edit requirement group'
            : 'Add new requirement group'
        }
        initialValue={editSubjectValue}
        onSend={(subjectLabel) => {
          handleOnSendSubject(
            subjectLabel,
            editSubjectValue,
            setIsVisibleAddSubjectModal,
            setEditSubjectValue,
            selectedSubjectData.moduleId,
            selectedSubjectData.moduleLabel,
          );
          setSelectedSubjectData({});
        }}
        onCancel={() => {
          setIsVisibleAddSubjectModal(false);
          setSelectedSubjectData({});
        }}
        cancelText="Cancel"
        description="Requirement group name:"
        zIndex={1100}
      />
      <SelectFillableFormModal
        onCancel={() => setIsVisibleAddFillableFormModal(false)}
        visible={isVisibleAddFillableFormModal}
        onSelect={handleOnSelectFillableForm}
        disabledFillableFormIds={requirementSubjects}
      />
      <SelectSurveyModal
        onSelectSurvey={handleOnSelectSurvey}
        disabledSurveyIds={requirementSubjects}
        visible={isVisibleSelectSurveyModal}
        onClose={() => setIsVisibleSelectSurveyModal(false)}
      />
    </>
  );
};

export default withControlledMount(ComplianceProfileDrawer);
