import { Button, Checkbox, Modal, Steps, message } from 'antd';
import { useCallback, useState } from 'react';
import styled from 'styled-components';

import Spinner from '@common/components/Spinner';

import { useSendEmailsMutation } from '../../hooks/useSendEmailsMutation';

import type { ServerFilterInput } from '@common/constants/filters/serverFilters';
import { trackEvent } from '@common/utils/track-helpers';
import { BulkOperationStatus } from '@graphql/types/graphql';
import { AllPartiesSelectedMessage } from '@modules/party/containers/BulkSendRequestsModal/AllPartiesSelectedMessage';
import { EmailTemplateForm } from '@modules/party/containers/BulkSendRequestsModal/EmailTemplateForm';
import { RecipientsTable } from './RecipientsTable';
import { usePrimaryRecordsEmailsData } from './hooks/usePrimaryRecordsEmailsData';

type SendEmailsModalProps = {
  open: boolean;
  areAllPrimaryRecordsSelected: boolean;
  filters?: ServerFilterInput;
  primaryObject: {
    name: string;
    pluralName: string;
  };
  onClose: () => void;
  onEmailsSent: () => void;
};

type EmailTemplateState = {
  id: string;
  subject: string;
};

export const SendEmailsModal = ({
  open,
  filters,
  primaryObject,
  areAllPrimaryRecordsSelected,
  onClose,
  onEmailsSent,
}: SendEmailsModalProps) => {
  const [excludeCompliantPrimaryRecords, setExcludeCompliantPrimaryRecords] =
    useState(true);
  const [isConfirmButtonDisabled, setIsConfirmButtonDisabled] = useState(false);
  const [emailTemplate, setEmailTemplate] = useState<EmailTemplateState>({
    id: '',
    subject: '',
  });
  const [validPrimaryRecords, setValidPrimaryRecords] = useState({});
  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  const {
    loading: primaryRecordsLoading,
    primaryRecords,
    primaryRecordsTotalCount,
    primaryRecordsWithoutContactsTotal,
  } = usePrimaryRecordsEmailsData({
    areQueriesSkipped: !open,
    activeFilters: filters,
    includeNodes: !areAllPrimaryRecordsSelected,
  });

  const { sendEmails, loading: isSendingEmails } = useSendEmailsMutation();

  const handleModalConfirm = async () => {
    const res = await sendEmails({
      filters,
      emailTemplate,
      excludeCompliantPrimaryRecords,
      recipientsMap: !areAllPrimaryRecordsSelected
        ? validPrimaryRecords
        : undefined,
    });
    const status = res.data?.sendPrimaryRecordsRequest?.operation.status;

    if (status === BulkOperationStatus.Completed) {
      onEmailsSent();
      setCurrentStepIndex(0);

      message.success(`Your send emails action is completed.`);
    } else if (status === BulkOperationStatus.Scheduled) {
      onEmailsSent();
      setCurrentStepIndex(0);
      trackEvent('User sent a bulk document request');

      message.success(
        `Your bulk send emails action is being processed. You will receive a notification when the send is complete.`,
      );
    } else if (status === BulkOperationStatus.Failed) {
      message.error(
        `The sending emails action has failed. Please try again or contact support.`,
      );
    }
  };

  const onRecipientsChanged = useCallback(
    ({
      hasError,
      recipientIdsMapByPrimaryRecordId,
    }: {
      hasError: boolean;
      recipientIdsMapByPrimaryRecordId: Record<string, string[]>;
    }) => {
      setIsConfirmButtonDisabled(hasError);
      setValidPrimaryRecords(recipientIdsMapByPrimaryRecordId);
    },
    [],
  );

  if (!open) {
    return null;
  }

  const isLastStep = currentStepIndex === 1;
  const isSendEmailsDisabled = isConfirmButtonDisabled || primaryRecordsLoading;

  const buttonsConfig = isLastStep
    ? {
        primaryButtonLabel: 'Send emails',
        isBackButtonVisible: true,
        primaryButtonHandler: handleModalConfirm,
      }
    : {
        primaryButtonLabel: 'Continue',
        isBackButtonVisible: false,
        primaryButtonHandler: () => setCurrentStepIndex(1),
      };

  const handleOnClose = () => {
    setCurrentStepIndex(0);
    onClose();
  };

  const FooterButtons = () => (
    <StyledActions>
      <Button onClick={handleOnClose} className="button">
        Cancel
      </Button>

      {buttonsConfig.isBackButtonVisible && (
        <Button onClick={() => setCurrentStepIndex(0)} className="button">
          Back
        </Button>
      )}

      <Button
        type="primary"
        className="button"
        onClick={buttonsConfig.primaryButtonHandler}
        disabled={isSendEmailsDisabled}
        loading={isSendingEmails}
      >
        {buttonsConfig.primaryButtonLabel}
      </Button>
    </StyledActions>
  );

  if (!open) {
    return null;
  }

  const firstStepContent = !areAllPrimaryRecordsSelected ? (
    <RecipientsTable
      primaryRecords={primaryRecords}
      primaryObjectPluralName={primaryObject.pluralName}
      onContactsChange={onRecipientsChanged}
    />
  ) : (
    <AllPartiesSelectedMessage
      partiesMissingContactCount={primaryRecordsWithoutContactsTotal || 0}
      partiesTotalCount={primaryRecordsTotalCount}
    />
  );

  const validPrimaryRecordsCount = areAllPrimaryRecordsSelected
    ? primaryRecordsTotalCount - primaryRecordsWithoutContactsTotal
    : Object.keys(validPrimaryRecords).length || 0;

  return (
    <StyledModal
      width={!areAllPrimaryRecordsSelected || isLastStep ? 1100 : 550}
      open={open}
      title={
        !areAllPrimaryRecordsSelected ? 'Review recipients' : 'Send emails'
      }
      okText="Send emails"
      onCancel={handleOnClose}
      footer={<FooterButtons />}
    >
      <StyledContentSection>
        {primaryRecordsLoading ? (
          <Spinner />
        ) : (
          <>
            <StyledSteps
              current={currentStepIndex}
              items={[
                {
                  title: 'Review Recipients',
                },
                {
                  title: 'Select Email Template',
                },
              ]}
            />
            <>
              {currentStepIndex === 0 ? (
                <>
                  <section>{firstStepContent}</section>
                  <StyledContentSection>
                    <Checkbox
                      checked={excludeCompliantPrimaryRecords}
                      onChange={() => {
                        setExcludeCompliantPrimaryRecords(
                          !excludeCompliantPrimaryRecords,
                        );
                      }}
                    >
                      {`Skip fully compliant ${primaryObject.pluralName}`}
                    </Checkbox>
                  </StyledContentSection>
                </>
              ) : (
                <EmailTemplateForm
                  objectPluralName={primaryObject.pluralName}
                  partiesTotal={validPrimaryRecordsCount}
                  onFormChange={({ subject, templateId }) => {
                    setEmailTemplate({
                      subject,
                      id: templateId,
                    });
                  }}
                />
              )}
            </>
          </>
        )}
      </StyledContentSection>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding: 0;
  }

  .ant-modal-footer {
    margin-top: 20px;
  }
`;

const StyledContentSection = styled.section`
  border-bottom: 1px solid #f0f0f0;
  padding: 15px 0;

  &:first-child {
    padding-bottom: 0;
  }
`;

const StyledActions = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 15px 0;

  .button {
    margin-left: 10px;
  }
`;

const StyledSteps = styled(Steps)`
  max-width: 600px;
  margin: 0 auto 24px;
`;
