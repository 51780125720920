import { UploadAttachmentsModal as CommonUploadAttachmentsModal } from '@common/components/UploadAttachmentsModal';
import { useUploadPortalAttachments } from '@modules/portal';
import { message } from 'antd';
import type { UploadFile } from 'antd/es/upload';

interface UploadAttachmentsModalProps {
  open: boolean;
  primaryRecordId: string;
  onCancel?: VoidFunction;
  onCompleted?: VoidFunction;
  onFailed?: VoidFunction;
}

export function UploadAttachmentModal({
  open,
  primaryRecordId,
  onCancel,
  onCompleted,
  onFailed,
}: UploadAttachmentsModalProps) {
  const {
    uploadPortalAttachments,
    isUploadingPortalAttachments,
    abortUploadPortalAttachments,
  } = useUploadPortalAttachments();

  const handleUpload = async (files: UploadFile[]) => {
    try {
      const res = await uploadPortalAttachments({
        variables: {
          data: {
            primaryRecordId,
            files,
          },
        },
      });

      if (res.data?.uploadPortalAttachments) {
        message.success('Attachments uploaded successfully.');
        onCompleted?.();
      } else if (res.errors) {
        message.error('Failed to upload attachments.');
        onFailed?.();
      }
    } catch (error) {
      message.error('Failed to upload attachments.');
      onFailed?.();
    }
  };

  const handleCancel = () => {
    abortUploadPortalAttachments();
    onCancel?.();
  };

  return (
    <CommonUploadAttachmentsModal
      open={open}
      onCancel={handleCancel}
      onOk={handleUpload}
      loading={isUploadingPortalAttachments}
      title="Upload Portal Attachments"
    />
  );
}
