import { Col, Row, Tabs } from 'antd';
import isEmpty from 'lodash/isEmpty';
import * as R from 'ramda';
import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import Spinner from '@common/components/Spinner';
import useEntityQueryString from '@common/hooks/useEntityQueryString';
import useSetState from '@common/hooks/useSetState';
import {
  setCurrentComplianceProfile,
  setCurrentRequirements,
} from '@modules/compliance-profile/actions';
import { getAutomaticallyWaivedOverridenAcrossProjects } from '@modules/compliance-profile/utils/compliance-attributes-helpers';
import { PartyCustomFields } from '@modules/custom-field/components/PartyCustomFields';
import {
  subscribeToPartyDocumentProcessed,
  unsubscribeToPartyDocumentProcessed,
} from '@modules/document/actions';
import { clearEventLogs } from '@modules/event-log/actions';
import {
  getActiveOrganizationData,
  getIsReloadPage,
  getOrganizationNamespaceUrl,
} from '@modules/organization/selectors';
import CreateConversationModal from '@modules/party-conversation/modals/CreateConversationModal';
import CreateConversationModalLegacy from '@modules/party-conversation/modals/CreateConversationModal.legacy';
import { getPartyTypeById } from '@modules/party-type/selectors';
import UploadDocumentModal from '@modules/request/components/UploadDocumentModal';
import SendRequestDrawerContainer from '@modules/request/containers/SendRequestDrawerContainer';
import AddNoteModal from '@modules/requirement/components/AddNoteModal';
import DeleteNoteModal from '@modules/requirement/components/DeleteNoteModal/DeleteNoteModal';
import { useDeleteNoteModal, useNoteModal } from '@modules/requirement/hooks';
import { PARTY_DETAILS_ROUTE } from '@modules/router/utils';
import { getCurrentProjectId } from '@modules/system-settings/selectors';

import { updateAdditionalNotes, updateParty } from '../../actions';
import PartyHeaderSection from '../../components/PartyHeaderSection';
import TabComplianceContent from '../../components/TabComplianceContent';
import TabDocumentsContent from '../../components/TabDocumentsContent.legacy';
import TabProjectsContent from '../../components/TabProjectsContent';
import { partyDetailsTabs as PartyDetailsTabs } from '../../constants';
import MessagesTabContainer from '../../containers/MessagesTabContainer';
import TabOverviewContent from '../../containers/TabOverviewContent';
import {
  getPartySelectedDocuments,
  getRequirementsByParty,
} from '../../selectors';

import { useIsContextsAddOnEnabled } from '@modules/add-on';
import { useIsComplianceRequirementsAddOnEnabled } from '@modules/add-on';
import { usePartyDetails } from './hooks/usePartyDetails';

const containerConfig = {
  xs: { span: 24 },
};

const PartyDetailsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentParams = useParams();
  const { organizationSlugifyName, partyId, tab } = currentParams;

  const {
    fetchPartyDetails,
    refetchPartyDetails,
    party,
    isPartyLoading,
    isPartyRefetchinng,
    partyComplianceProfile,
    isPartyComplianceProfileLoading,
    newMessagesCount,
  } = usePartyDetails({ partyId });

  const [isShowUploadModal, setIsShowUploadModal] = useState(false);
  const [isReminderSendRequest, setIsReminderSendRequest] = useState(false);
  const [isShowSendRequestModal, setIsShowSendRequestModal] = useState(false);
  const [selectedRecipientId, setSelectedRecipientId] = useState('');
  const [
    isVisibleConversationModalLegacy,
    setIsVisibleConversationModalLegacy,
  ] = useState(false);
  const [conversationModalData, setConversationModalData] = useSetState({
    visible: false,
    defaultSubject: '',
    defaultMessage: '',
    defaultRecipients: [],
  });
  const [defaultFiles, setDefaultFiles] = useState([]);
  const [rejectedFiles, setRejectedFiles] = useState([]);

  const organizationNamespace = useSelector(getOrganizationNamespaceUrl);
  const requirementsInGlobalStore = useSelector((state) =>
    getRequirementsByParty(state, partyId),
  );
  const requirements = getAutomaticallyWaivedOverridenAcrossProjects(
    requirementsInGlobalStore ?? [],
    party?.automaticallyWaivedRequirements ?? [],
  );
  const partyType = useSelector((state) =>
    getPartyTypeById(state, party?.type?._id),
  );
  const currentProjectId = useSelector(getCurrentProjectId);
  const organization = useSelector(getActiveOrganizationData);
  const activeOrganizationId = organization.id;
  const selectedDocuments = useSelector((state) =>
    getPartySelectedDocuments(state, party?._id || ''),
  );
  const isReloadPage = useSelector(getIsReloadPage);
  const { isComplianceRequirementsAddOnEnabled } =
    useIsComplianceRequirementsAddOnEnabled();

  const { getQsForEntityInParty } = useEntityQueryString();
  const {
    isVisible: isNoteModalVisible,
    currentNote,
    onCloseModal: onCloseNoteModal,
  } = useNoteModal();
  const {
    isVisible: isDeleteNoteModalVisible,
    onCloseModal: onCloseDeleteNoteModal,
    onDeleteNote,
    isDeleting,
  } = useDeleteNoteModal();

  const { isContextsAddOnEnabled } = useIsContextsAddOnEnabled();

  const activeDocuments = Array.isArray(party?.applicableDocuments)
    ? party?.applicableDocuments
    : Array.isArray(party?.documents)
      ? party?.documents
      : [];

  const requests = party?.requests || [];
  const latestRequest = R.last(requests);

  useEffect(() => {
    if (activeOrganizationId || isReloadPage) {
      fetchPartyDetails();
      return () => dispatch(clearEventLogs());
    }
  }, [fetchPartyDetails, isReloadPage, activeOrganizationId, dispatch]);

  useEffect(() => {
    dispatch(subscribeToPartyDocumentProcessed(partyId));

    return () => {
      dispatch(unsubscribeToPartyDocumentProcessed(partyId));
    };
  }, [dispatch, partyId]);

  const onSendRequest = useCallback(() => {
    setIsReminderSendRequest(false);
    setIsShowSendRequestModal(true);
  }, []);

  const onSendReminder = useCallback(() => {
    setIsReminderSendRequest(true);
    setIsShowSendRequestModal(true);
  }, []);

  const handleOnDocumentFormUpdated = () => {
    fetchPartyDetails();
  };

  const activeTab = tab || PartyDetailsTabs.Overview.key;

  const handleTabClick = (key) => {
    const params = {
      ...currentParams,
      tab: key,
    };

    /*
    Temporary solution to refetch party details every time the tab is opened to avoid stale data
    TODO: Should be removed once components inside tabs are isolated and independent with their own fetchings
    */
    if (key === 'compliance' || key === 'overview') {
      refetchPartyDetails();
    }

    const pathname = generatePath(PARTY_DETAILS_ROUTE, params);
    navigate({
      pathname,
      search: `?${getQsForEntityInParty(key)}`,
    });
  };

  if (!party && isPartyLoading) {
    return <Spinner />;
  }

  return (
    <StyledWrapper>
      <Helmet title={party?.name || ''} />

      {party ? (
        <>
          <AddNoteModal
            isVisible={isNoteModalVisible}
            onClose={onCloseNoteModal}
            onCompleted={() => {
              fetchPartyDetails();
            }}
            requirement={currentNote}
            key={`note-modal-${currentNote?._id}`}
            projectId={currentProjectId}
          />
          <DeleteNoteModal
            isVisible={isDeleteNoteModalVisible}
            onClose={onCloseDeleteNoteModal}
            onSubmit={() => {
              const result = onDeleteNote();

              if (result) {
                result.then(() => fetchPartyDetails());
              }
            }}
            isSubmitting={isDeleting}
          />
          <SendRequestDrawerContainer
            organizationNamespace={organizationNamespace}
            isShow={isShowSendRequestModal}
            isReminderSendRequest={isReminderSendRequest}
            onClose={() => {
              setIsShowSendRequestModal(false);
            }}
            partyId={partyId}
          />
          <CreateConversationModalLegacy
            visible={isVisibleConversationModalLegacy}
            selectedRecipientId={selectedRecipientId}
            onCancel={() => {
              setSelectedRecipientId('');
              setIsVisibleConversationModalLegacy(false);
            }}
          />
          <CreateConversationModal
            visible={conversationModalData.visible}
            partyContacts={party?.partyContacts ?? []}
            partyId={partyId}
            defaultSubject={conversationModalData.defaultSubject}
            defaultMessage={conversationModalData.defaultMessage}
            defaultRecipients={conversationModalData.defaultRecipients}
            onSubmitEnd={() => {
              setConversationModalData({ visible: false });
            }}
            onCancel={() => {
              setSelectedRecipientId('');
              setConversationModalData({ visible: false });
            }}
          />
          <UploadDocumentModal
            defaultFiles={defaultFiles}
            defaultRejectedFiles={rejectedFiles}
            party={partyId}
            isShow={isShowUploadModal}
            setIsShow={(isShow) => {
              setIsShowUploadModal(isShow);

              if (!isShow) {
                setDefaultFiles([]);
              }
            }}
          />

          {party && (
            <PartyHeaderSection
              organizationNamespace={organizationNamespace}
              party={party}
              updateParty={(data) => dispatch(updateParty(data))}
              partyType={partyType}
              onSendRequest={onSendRequest}
              onSendReminder={onSendReminder}
              isSendReminder={!isEmpty(requests)}
              onDocumentUpload={() => setIsShowUploadModal(true)}
            />
          )}
          <StyledTabWrapper>
            <Row style={{ padding: '0 20px' }}>
              <Col {...containerConfig}>
                <StyledTab
                  data-cy="partyDetailsTabs"
                  activeKey={activeTab}
                  animated={false}
                  onTabClick={handleTabClick}
                >
                  <Tabs.TabPane
                    tab={PartyDetailsTabs.Overview.name}
                    key={PartyDetailsTabs.Overview.key}
                  >
                    {activeTab === PartyDetailsTabs.Overview.key &&
                      (isPartyRefetchinng ? (
                        <Spinner />
                      ) : (
                        <TabOverviewContent
                          organizationId={activeOrganizationId}
                          currentProjectId={currentProjectId}
                          organizationNamespace={organizationNamespace}
                          party={party}
                          partyComplianceProfile={partyComplianceProfile}
                          requirements={requirements}
                          setCurrentRequirements={(data) =>
                            dispatch(setCurrentRequirements(data))
                          }
                          setCurrentComplianceProfile={(data) =>
                            dispatch(setCurrentComplianceProfile(data))
                          }
                          onSendReminder={onSendReminder}
                          onSendRequest={onSendRequest}
                          latestRequest={latestRequest}
                          updateAdditionalNotes={(data) =>
                            dispatch(updateAdditionalNotes(data))
                          }
                          onDocumentFormUpdated={handleOnDocumentFormUpdated}
                          isPartyComplianceProfileLoading={
                            isPartyComplianceProfileLoading
                          }
                        />
                      ))}
                  </Tabs.TabPane>
                  {isComplianceRequirementsAddOnEnabled &&
                    party?.isTrackingCompliance && (
                      <Tabs.TabPane
                        tab={PartyDetailsTabs.Compliance.name}
                        key={PartyDetailsTabs.Compliance.key}
                      >
                        {activeTab === PartyDetailsTabs.Compliance.key &&
                          (isPartyRefetchinng ? (
                            <Spinner />
                          ) : (
                            <TabComplianceContent
                              organizationNamespace={organizationNamespace}
                              currentProjectId={currentProjectId}
                              party={party}
                              partyComplianceProfile={partyComplianceProfile}
                              requests={requests}
                              requirements={requirements}
                              documents={activeDocuments}
                              selectedDocuments={selectedDocuments}
                              setCurrentRequirements={(data) =>
                                dispatch(setCurrentRequirements(data))
                              }
                              setCurrentComplianceProfile={(data) =>
                                dispatch(setCurrentComplianceProfile(data))
                              }
                              setIsShowUploadModal={setIsShowUploadModal}
                              setUploadFiles={setDefaultFiles}
                              setRejectedFiles={setRejectedFiles}
                              setIsReminderSendRequest={
                                setIsReminderSendRequest
                              }
                              setIsShowSendRequestModal={
                                setIsShowSendRequestModal
                              }
                              setConversationModalData={
                                setConversationModalData
                              }
                              isPartyComplianceProfileLoading={
                                isPartyComplianceProfileLoading
                              }
                            />
                          ))}
                      </Tabs.TabPane>
                    )}
                  <Tabs.TabPane
                    tab={PartyDetailsTabs.Documents.name}
                    key={PartyDetailsTabs.Documents.key}
                  >
                    {activeTab === PartyDetailsTabs.Documents.key && (
                      <TabDocumentsContent />
                    )}
                  </Tabs.TabPane>
                  {isContextsAddOnEnabled && (
                    <Tabs.TabPane
                      tab={PartyDetailsTabs.Projects.name}
                      key={PartyDetailsTabs.Projects.key}
                    >
                      {activeTab === PartyDetailsTabs.Projects.key && (
                        <TabProjectsContent />
                      )}
                    </Tabs.TabPane>
                  )}
                  <Tabs.TabPane
                    tab={PartyDetailsTabs.CustomFields.name}
                    key={PartyDetailsTabs.CustomFields.key}
                  >
                    {activeTab === PartyDetailsTabs.CustomFields.key && (
                      <PartyCustomFields
                        partyId={partyId}
                        organizationSlugifyName={organizationSlugifyName}
                        projectId={currentProjectId}
                      />
                    )}
                  </Tabs.TabPane>
                  <Tabs.TabPane
                    tab={`${PartyDetailsTabs.Communications.name}${
                      newMessagesCount
                        ? ` (${newMessagesCount.toString()} new)`
                        : ''
                    }`}
                    key={PartyDetailsTabs.Communications.key}
                  >
                    {activeTab === PartyDetailsTabs.Communications.key && (
                      <MessagesTabContainer
                        setIsVisibleConversationModal={
                          setIsVisibleConversationModalLegacy
                        }
                        setSelectedRecipientId={setSelectedRecipientId}
                      />
                    )}
                  </Tabs.TabPane>
                </StyledTab>
              </Col>
            </Row>
          </StyledTabWrapper>
        </>
      ) : (
        <Spinner />
      )}
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
`;

const StyledTabWrapper = styled.div`
  margin-top: -38px;
`;

const StyledTab = styled(Tabs)`
  padding-bottom: 10px !important;

  .ant-tabs-bar {
    background-color: ${(props) => props.theme.colors.white};
    border-bottom: none;
  }

  .ant-tabs-content {
    margin-top: 10px;
    padding: 0 5px;
  }

  .ant-tabs-tab {
    padding: 8px 0;
  }

  .ant-tabs-tabpane {
    outline: none;
    height: 100%;
  }
`;

export default PartyDetailsPage;
