import { ADD_ON } from '@modules/add-on/constants';
import { useFeatures } from '@paralleldrive/react-feature-toggles';

export const useIsEmbeddedInsuranceAddOnEnabled = () => {
  const features = useFeatures();

  const isEmbeddedInsuranceAddOnEnabled = features.includes(
    ADD_ON.EMBEDDED_INSURANCE,
  );

  return { isEmbeddedInsuranceAddOnEnabled };
};
