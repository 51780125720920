import { WarningFilled } from '@ant-design/icons';
import { Alert, Button, Space } from 'antd';
import styled from 'styled-components';

import { useTrustLayerV2FeatureFlag } from '@modules/feature-flags/hooks';
import { SingleCollapse } from '@trustlayer/ui';

type IssueListProps = {
  onResolveClik: (contactId: string, issueId: string) => void;
  onIgnoreClik: (issueId: string) => void;
  onRemoveContact?: (contactId: string, issueId: string) => void;
  issues: Array<{
    _id: string;
    contactId: string;
    email: string;
    ignorable: boolean;
    messageId: string;
    partyConversationId: string;
    reason: string;
    resendable: boolean;
    resolved: boolean;
    sendgridEventId: string;
  }>;
};

export const IssueList = ({
  issues = [],
  onIgnoreClik,
  onResolveClik,
  onRemoveContact,
  ...rest
}: IssueListProps) => {
  const { isTlV2FeatureFlagEnabled } = useTrustLayerV2FeatureFlag();

  return (
    <StyledWrapper {...rest}>
      {issues?.map(
        ({ _id, email, resendable, ignorable, resolved, contactId }) => (
          <SingleCollapse
            header={`Message not delivered to ${email}`}
            key={_id}
            isCollapsed={resolved}
          >
            <Alert
              showIcon
              message="Message not delivered"
              description={
                <Space direction="vertical" size={16}>
                  <div>
                    The address <strong>{email}</strong> could not be found,
                    check for typos or unnecessary spaces and try again.
                  </div>
                  <Space>
                    {resendable && (
                      <Button
                        size="small"
                        type="primary"
                        onClick={() => onResolveClik(contactId, _id)}
                      >
                        Resend
                      </Button>
                    )}
                    {isTlV2FeatureFlagEnabled && onRemoveContact && (
                      <Button
                        size="small"
                        danger
                        ghost
                        onClick={() => onRemoveContact(contactId, _id)}
                      >
                        Remove contact
                      </Button>
                    )}
                    {ignorable && (
                      <Button
                        size="small"
                        type="ghost"
                        onClick={() => onIgnoreClik(_id)}
                      >
                        Ignore
                      </Button>
                    )}
                  </Space>
                </Space>
              }
              type="warning"
              icon={<WarningFilled />}
            />
          </SingleCollapse>
        ),
      )}
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  width: 100%;
  order: 3;
`;
