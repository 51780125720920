import { Collapse } from 'antd';
import * as R from 'ramda';
import React, { useEffect, useState } from 'react';

import { groupByModule } from '@common/utils/group-by-module';
import CollapsePanel from '../components/CollapsePanel';
import ModuleLabel from '../components/ModuleLabel';

import CollapsePanelSubjectContainer from './CollapsePanelSubjectsContainer';

const CollapsePanelContainer = ({
  party = {},
  documents = [],
  requirements = [],
  selectedDocuments,
  projectId,
  organizationNamespace,
  hideRequirementActions = false,
  setConversationModalData,
  waivedSubjects = [],
}) => {
  const groupedModules = groupByModule(requirements);
  const [activeKeys, setActiveKeys] = useState(() =>
    (groupedModules || []).map((x) => x.moduleId),
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: We can't ensure that groupedModules is memoized, for now re set the expand state when the number of modules changes. I will preserve the behavior when change context on party's compliance tab
  useEffect(() => {
    setActiveKeys(groupedModules.map((x) => x.moduleId));
  }, [groupedModules.length]);

  const getIsInsuranceModule = React.useCallback((moduleData) => {
    const search = /insurance/g;
    return (
      R.propOr('', 'moduleId', moduleData).toLowerCase().match(search) ||
      R.propOr('', 'moduleLabel', moduleData).toLowerCase().match(search)
    );
  }, []);

  return (
    <CollapsePanel
      activeKeys={activeKeys}
      onChange={(keys) => setActiveKeys(keys)}
    >
      {groupedModules.map((moduleData) => {
        /**
         * @description - Here we merge the subjects with the waived subjects in the party object
         */
        const subjects = moduleData.subjects.map((subject) => {
          const waivedSubject = waivedSubjects?.find(
            (waivedSubject) => waivedSubject.subjectId === subject.subjectId,
          );

          return waivedSubject
            ? { ...waivedSubject, children: subject.children }
            : subject;
        });

        return (
          <Collapse.Panel
            key={moduleData.moduleId}
            header={<ModuleLabel>{moduleData.moduleLabel}</ModuleLabel>}
            showArrow={false}
          >
            <CollapsePanelSubjectContainer
              documents={documents}
              hideRequirementActions={hideRequirementActions}
              isInsuranceModule={getIsInsuranceModule(moduleData)}
              isLicenseModule={getIsInsuranceModule(moduleData)}
              organizationNamespace={organizationNamespace}
              party={party}
              projectId={projectId}
              requirements={requirements}
              selectedDocuments={selectedDocuments}
              subjects={subjects}
              setConversationModalData={setConversationModalData}
            />
          </Collapse.Panel>
        );
      })}
    </CollapsePanel>
  );
};

export default CollapsePanelContainer;
