import { FileOutlined } from '@ant-design/icons';
import * as R from 'ramda';
import { Link } from 'react-router-dom';

import { useIsBaseAddOnEnabled } from '@modules/add-on';
import ToReviewTag from '@modules/document/components/ToReviewTag';
import { getDocumentFirstExpirationDate } from '@modules/document/utils/getDocumentFirstExpirationDate';

import ExpirationBadgePopover from '../Badges/components/ExpirationBadgePopover';
import FlagBadgePopover from '../Badges/components/FlagBadgePopover';
import VerificationBadgePopover from '../Badges/components/VerificationBadgePopover';
import { FileInfo, FileNameWrapper } from './FileName';

const FileNameWithBadges = ({
  fileData,
  filename,
  party,
  projectId,
  organizationNamespace,
}) => {
  const toReview = !Boolean(fileData.reviewedAt);
  const { isBaseAddOnEnabled } = useIsBaseAddOnEnabled();

  return (
    <FileNameWrapper>
      <Link
        to={`${organizationNamespace}/parties/${R.path(
          ['party', '_id'],
          fileData,
        )}/documents-review/${R.prop('_id', fileData)}${
          projectId ? `?project=${projectId}` : ''
        }`}
      >
        <FileOutlined theme="twoTone" />
        <span>{filename}</span>
        <FileInfo>
          <ExpirationBadgePopover
            expirationDate={
              isBaseAddOnEnabled
                ? fileData.expirationDate
                : getDocumentFirstExpirationDate({
                    metadata: fileData.metadata,
                    recordRequirements: party?.requirements,
                  })
            }
            sizeIcon="Small"
            style={{ marginLeft: 'auto' }}
            defaultVisible
          />
          <FlagBadgePopover
            documentData={{
              _id: fileData._id,
              issuedBy: fileData.issuedBy,
              documentName: fileData.friendlyName,
              party,
            }}
            flagData={R.propOr({}, 'flag', fileData)}
            sizeIcon="Small"
            defaultVisible
            isShortVersion
          />
          <VerificationBadgePopover
            documentData={{
              _id: fileData._id,
              issuedBy: fileData.issuedBy,
              documentName: fileData.friendlyName,
            }}
            verificationData={R.propOr({}, 'verification', fileData)}
            sizeIcon="Small"
            isShortVersion
          />
          {toReview && <ToReviewTag>to review</ToReviewTag>}
        </FileInfo>
      </Link>
    </FileNameWrapper>
  );
};

export default FileNameWithBadges;
