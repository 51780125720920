import {
  ExportOutlined,
  FolderOutlined,
  MoreOutlined,
  SendOutlined,
  ShopOutlined,
  SwapOutlined,
} from '@ant-design/icons';
import { ArchivedBanner } from '@common/components/ArchivedBanner';
import { PageTitle } from '@common/components/PageTitleWrapper';
import { TabButtons } from '@common/components/TabButtons';
import { useIsComplianceRequirementsAddOnEnabled } from '@modules/add-on';
import { SendRequestDrawerContainer } from '@modules/primary-records/containers/SendRequestDrawerContainer';
import {
  ArchiveRequestRecordModal,
  DeleteRequestRecordModal,
  REQUEST_RECORDS_TABS,
} from '@modules/request-records';
import { MakeActiveRequestRecordModal } from '@modules/request-records/containers/MakeActiveRequestRecordModal/MakeActiveRequestRecordModal';
import { OBJECT_TYPES } from '@modules/router/constants';
import { getObjectTypeFromPath } from '@modules/router/utils';
import {
  ContextRecordStatus,
  PrimaryRecordStatus,
  RequestRecordStatus,
} from '@trustlayer/common';
import {
  Breadcrumb,
  Button,
  Dropdown,
  Result,
  Skeleton,
  Space,
  Typography,
} from 'antd';
import { DetailsPageLayout } from 'layouts';
import pluralize from 'pluralize';
import { useMemo, useState } from 'react';
import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import styled from 'styled-components';
import { useRequestRecordDetails } from './hooks/useRequestRecordDetails';

enum ACTIONS_ITEMS_KEYS {
  ARCHIVE = 'archive',
  DELETE = 'delete',
  MAKE_ACTIVE = 'make_active',
}

export const RequestRecordDetail = () => {
  const { organizationSlugifyName, requestId, recordId } = useParams<{
    organizationSlugifyName: string;
    requestId: string;
    recordId: string;
  }>();

  const { isComplianceRequirementsAddOnEnabled } =
    useIsComplianceRequirementsAddOnEnabled();

  const { pathname } = useLocation();
  const pathSegments = pathname.split('/');
  const tab = pathSegments[7];
  const objectType = getObjectTypeFromPath(pathname);

  const {
    requestRecord,
    loading: isLoadingRequestRecord,
    error: requestRecordError,
    refetchRequestRecord,
  } = useRequestRecordDetails(requestId);

  const breadcrumbItems = {
    origin:
      objectType === OBJECT_TYPES.primary.code
        ? requestRecord?.primaryRecord?.name
        : requestRecord?.contextRecord?.name,
    requestsCount:
      objectType === OBJECT_TYPES.primary.code
        ? requestRecord?.primaryRecord?.requestRecords?.totalCount
        : requestRecord?.contextRecord?.requestRecords?.totalCount,
  };

  const navigate = useNavigate();
  const [sendRequestDrawerOpen, setSendRequestDrawerOpen] = useState(false);
  const [archiveModalOpen, setArchiveModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [makeActiveModalOpen, setMakeActiveModalOpen] = useState(false);

  const activeTab =
    Object.values(REQUEST_RECORDS_TABS).find((t) => t.key === tab)?.key ?? '';
  const isRequestRecordArchived =
    requestRecord?.status === RequestRecordStatus.Archived;
  const isContextRecordArchived =
    requestRecord?.contextRecord?.status === ContextRecordStatus.Archived;
  const isPrimaryRecordArchived =
    requestRecord?.primaryRecord?.status === PrimaryRecordStatus.Archived;
  const isContextOrPrimaryRecordArchived =
    isContextRecordArchived || isPrimaryRecordArchived;

  const handleClose = () => {
    const pathSegmentsWithoutTab = pathSegments.slice(0, -2).join('/');

    navigate(pathSegmentsWithoutTab);
  };

  const handleDeleted = () => {
    setDeleteModalOpen(false);
    handleClose();
  };

  const handleArchive = () => {
    setArchiveModalOpen(false);
    refetchRequestRecord();
  };

  const handleMakeActiveCompleted = () => {
    setMakeActiveModalOpen(false);
    refetchRequestRecord();
  };

  const dropdownMenuItems = useMemo(() => {
    const items = [];

    if (requestRecord?.status !== RequestRecordStatus.Archived) {
      items.push({
        key: ACTIONS_ITEMS_KEYS.ARCHIVE,
        label: 'Archive',
        onClick: () => setArchiveModalOpen(true),
      });
    }

    if (
      requestRecord?.status !== RequestRecordStatus.Active &&
      requestRecord?.primaryRecord.status === PrimaryRecordStatus.Active &&
      (!requestRecord?.contextRecord ||
        requestRecord?.contextRecord?.status === ContextRecordStatus.Active)
    ) {
      items.push({
        key: ACTIONS_ITEMS_KEYS.MAKE_ACTIVE,
        label: 'Make Active',
        onClick: () => setMakeActiveModalOpen(true),
      });
    }

    items.push({
      key: ACTIONS_ITEMS_KEYS.DELETE,
      label: 'Delete',
      danger: true,
      onClick: () => setDeleteModalOpen(true),
    });

    return items;
  }, [
    requestRecord?.status,
    requestRecord?.primaryRecord.status,
    requestRecord?.contextRecord,
  ]);

  if (requestRecordError) {
    return (
      <Result
        status={500}
        title={requestRecordError.name}
        subTitle={requestRecordError.message}
      />
    );
  }

  return (
    <DetailsPageLayout>
      <DeleteRequestRecordModal
        open={deleteModalOpen}
        requestRecord={{
          _id: requestRecord?._id,
          name: requestRecord?.name,
        }}
        onCancel={() => setDeleteModalOpen(false)}
        onCompleted={handleDeleted}
        onScheduled={handleDeleted}
      />
      <ArchiveRequestRecordModal
        open={archiveModalOpen}
        requestRecord={{
          _id: requestRecord?._id,
          name: requestRecord?.name,
        }}
        onCancel={() => setArchiveModalOpen(false)}
        onCompleted={handleArchive}
      />
      <MakeActiveRequestRecordModal
        open={makeActiveModalOpen}
        requestRecord={{
          _id: requestRecord?._id,
          name: requestRecord?.name,
        }}
        onCancel={() => setMakeActiveModalOpen(false)}
        onCompleted={handleMakeActiveCompleted}
        onScheduled={handleMakeActiveCompleted}
        onFailed={() => setMakeActiveModalOpen(false)}
      />
      <SendRequestDrawerContainer
        open={sendRequestDrawerOpen}
        primaryRecordId={requestRecord?.primaryRecord?._id}
        onCancel={() => setSendRequestDrawerOpen(false)}
      />
      <DetailsPageLayout.TopBar>
        <DetailsPageLayout.PageActions onClose={handleClose} />
        <Breadcrumb>
          <Breadcrumb.Item>
            {isLoadingRequestRecord ? (
              <Skeleton.Input active size="small" />
            ) : (
              breadcrumbItems.origin
            )}
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {isLoadingRequestRecord ? (
              <Skeleton.Input active size="small" />
            ) : (
              `${breadcrumbItems.requestsCount} ${pluralize('Request', breadcrumbItems.requestsCount)}`
            )}
          </Breadcrumb.Item>
        </Breadcrumb>
      </DetailsPageLayout.TopBar>

      {isRequestRecordArchived && (
        <ArchivedBanner>
          {`${requestRecord?.name} is archived`}
          {isContextOrPrimaryRecordArchived && (
            <>
              <br />
              {`To activate this request please activate `}
              <b>
                {isPrimaryRecordArchived && requestRecord?.primaryRecord?.name}
              </b>
              {isContextRecordArchived && isPrimaryRecordArchived && ' and '}
              <b>
                {isContextRecordArchived && requestRecord?.contextRecord?.name}
              </b>{' '}
              first.
            </>
          )}
        </ArchivedBanner>
      )}

      <DetailsPageLayout.Header>
        <DetailsPageLayout.Header.TopLeftSection>
          <Space direction="vertical" size={8}>
            <PageTitle.Composite
              leftTitle={
                <PageTitle
                  title={
                    isLoadingRequestRecord ? (
                      <Skeleton.Input active size="small" />
                    ) : (
                      <StyledLink
                        to={`/${organizationSlugifyName}/${OBJECT_TYPES.primary.key}/parties/${requestRecord?.primaryRecord?._id}`}
                      >
                        {requestRecord?.primaryRecord?.name}
                      </StyledLink>
                    )
                  }
                  icon={<ShopOutlined />}
                  iconSize="small"
                />
              }
              middleIcon={
                requestRecord?.contextRecord?.name ? (
                  <SwapOutlined />
                ) : undefined
              }
              rightTitle={
                !isLoadingRequestRecord &&
                requestRecord?.contextRecord?.name ? (
                  <PageTitle
                    title={
                      isLoadingRequestRecord ? (
                        <Skeleton.Input active size="small" />
                      ) : (
                        <StyledLink
                          to={`/${organizationSlugifyName}/${OBJECT_TYPES.context.key}/projects/${requestRecord?.contextRecord?._id}`}
                        >
                          {requestRecord?.contextRecord?.name}
                        </StyledLink>
                      )
                    }
                    icon={<FolderOutlined />}
                    iconSize="small"
                  />
                ) : null
              }
            />
            <StyledRequestRecordName className="requestName">
              {isLoadingRequestRecord ? (
                <Skeleton.Input active size="small" />
              ) : (
                requestRecord?.name
              )}
            </StyledRequestRecordName>
          </Space>
        </DetailsPageLayout.Header.TopLeftSection>

        <DetailsPageLayout.Header.BottomLeftSection>
          <TabButtons
            tabs={[
              isComplianceRequirementsAddOnEnabled
                ? REQUEST_RECORDS_TABS.Compliance
                : REQUEST_RECORDS_TABS.Checklist,
              REQUEST_RECORDS_TABS.Attributes,
            ]}
            activeTab={activeTab}
            onClick={(tabKey) => {
              navigate(`./${tabKey}`, {
                relative: 'path',
              });
            }}
          />
        </DetailsPageLayout.Header.BottomLeftSection>

        <DetailsPageLayout.Header.BottomRightSection>
          <Button
            type="primary"
            icon={<SendOutlined />}
            onClick={() => setSendRequestDrawerOpen(true)}
          >
            Send Email
          </Button>
          <Button
            icon={<ExportOutlined />}
            href={`${import.meta.env.VITE_PORTAL_URL}/p/${recordId}`}
            target="_blank"
          />

          <Dropdown
            menu={{
              items: dropdownMenuItems,
            }}
          >
            <Button data-cy="moreButton" icon={<MoreOutlined />} />
          </Dropdown>
        </DetailsPageLayout.Header.BottomRightSection>
      </DetailsPageLayout.Header>

      <DetailsPageLayout.MainContent>
        <DetailsPageLayout.Content>
          <DetailsPageLayout.Body>
            <Outlet />
          </DetailsPageLayout.Body>
        </DetailsPageLayout.Content>
      </DetailsPageLayout.MainContent>
    </DetailsPageLayout>
  );
};

const StyledRequestRecordName = styled(Typography.Text)`
  color: ${({ theme }) => theme.colors.subtitleText};
  margin: 0;
  padding: 0;
`;

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.black};

  &:hover {
  color: ${({ theme }) => theme.colors.black};
    text-decoration: underline;
  }
`;
