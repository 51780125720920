import { utc } from '@common/utils/date';
import { isSupportedSubjectDateValues } from '@modules/compliance-profile/constants';

type RequirementData = {
  effectiveDate: string | null;
  expirationDate: string | null;
};

type RequirementId = string;

export type RecordRequirement = {
  subjectId: string;
};

type DocumentMetadata = {
  [x: RequirementId]: RequirementData;
};

type GetDocumentFirstExpirationDateParams = {
  metadata: DocumentMetadata | null;
  recordRequirements?: RecordRequirement[] | null;
};

const getRequirementSubjectIdsWithValidExpirationDate = (
  metadata: DocumentMetadata,
) => {
  return Object.keys(metadata).filter(
    (key) =>
      Boolean(metadata[key]) &&
      Boolean(metadata[key].expirationDate) &&
      !isSupportedSubjectDateValues(metadata[key].expirationDate as string),
  );
};

export const getDocumentFirstExpirationDate = ({
  metadata,
  recordRequirements,
}: GetDocumentFirstExpirationDateParams) => {
  if (!metadata) {
    return undefined;
  }

  const subjectIds = getRequirementSubjectIdsWithValidExpirationDate(metadata);
  const recordRequirementsSubjectsSet = new Set<string>(
    recordRequirements?.map((r) => r.subjectId),
  );

  /**
   * @note when record requirements are present it get the subject ids in both record and document,
   * otherwise it get every document subject ids.
   */
  const availableSubjectIds = subjectIds.filter((id) => {
    return recordRequirementsSubjectsSet.size
      ? recordRequirementsSubjectsSet.has(id)
      : true;
  });

  const sortedDates = availableSubjectIds
    .map((id) => metadata[id].expirationDate)
    .sort((a, b) => utc(a).valueOf() - utc(b).valueOf());

  return sortedDates[0];
};
