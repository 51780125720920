import {
  CaretRightOutlined,
  CheckCircleOutlined,
  CheckOutlined,
  CloseOutlined,
  CommentOutlined,
  ContainerOutlined,
  DeleteOutlined,
  EditOutlined,
  FileAddOutlined,
  FileSearchOutlined,
  MailOutlined,
  MessageOutlined,
  PoweroffOutlined,
  ProfileOutlined,
  ReloadOutlined,
  TagOutlined,
  UploadOutlined,
  UserAddOutlined,
  UserDeleteOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Avatar } from 'antd';
import { htmlToText } from 'html-to-text';
import moment from 'moment';
import * as R from 'ramda';
import styled from 'styled-components';

import { DocumentSeverityColorMap } from '@common/components/Badges/components/FlagBadgePopover';
import { BadgeColors } from '@common/components/Badges/model/badges';
import ConversationLinkName from '@common/components/ConversationLinkName';
import DateTimeline from '@common/components/Date/DateTimeline';
import DocumentLinkName from '@common/components/DocumentLinkName';
import UserAvatar from '@common/components/UserAvatar';
import FlagIcon from '@common/icons/FlagIcon';
import PaperPlane from '@common/icons/PaperPlane';
import VerifiedIcon from '@common/icons/VerifiedIcon';
import RuleDisplayValue from '@modules/compliance-profile/components/RuleDisplayValue';

import { EventType } from '../constants';
import CommentEventLogContainer from '../containers/CommentEventLogContainer';
import { getAuthorEvent } from '../utils/event-log-helpers';

import { Link, useLocation } from 'react-router-dom';
import PartyProjectLine from './PartyProjectLine';

const DEFAULT_COLOR = '#5c6c87';

export const getEventLogItem = ({ log, editableComments, themeContext }) => {
  const authorEvent = getAuthorEvent(log) || 'Someone';
  const notes = log.data?.notes;
  const subjectLabel = log.data?.subjectLabel;
  const until = log.data?.resetRequirementOn;
  const location = useLocation();

  switch (log.type) {
    case EventType.PartyAddedToProject:
      return {
        dot: (
          <IconWrapper>
            <UserAddOutlined />
          </IconWrapper>
        ),
        color: DEFAULT_COLOR,
        children: (
          <>
            <b>{authorEvent}</b> added party to project <b>{log.projectName}</b>{' '}
            <PartyProjectLine log={log} />
            <b>{R.path(['data', 'partyName'], log)}</b>
            <DateTimeline date={log.createdAt} />
          </>
        ),
      };
    case EventType.PartyRemovedFromProject:
      return {
        dot: (
          <IconWrapper>
            <UserDeleteOutlined />
          </IconWrapper>
        ),
        color: DEFAULT_COLOR,
        children: (
          <>
            <b>{authorEvent}</b> removed party from project{' '}
            <b>{log.projectName}</b> <PartyProjectLine log={log} />
            <b>{R.path(['data', 'partyName'], log)}</b>
            <DateTimeline date={log.createdAt} />
          </>
        ),
      };
    case EventType.ExternalPartyRemovedFromProject:
      return {
        dot: (
          <IconWrapper>
            <UserDeleteOutlined />
          </IconWrapper>
        ),
        color: DEFAULT_COLOR,
        children: (
          <>
            <b>[{log.actorId}]</b> Party was removed from project{' '}
            <b>{log.projectName}</b> <PartyProjectLine log={log} />
            <b>{R.path(['data', 'partyName'], log)}</b>
            <DateTimeline date={log.createdAt} />
          </>
        ),
      };
    case EventType.PartyCreated:
      return {
        dot: (
          <IconWrapper>
            <UserOutlined />
          </IconWrapper>
        ),
        color: DEFAULT_COLOR,
        children: (
          <>
            <b>{authorEvent}</b> created party{' '}
            <b>{R.path(['data', 'partyName'], log)}</b>
            <PartyProjectLine log={log} />
            <DateTimeline date={log.createdAt} />
          </>
        ),
      };
    case EventType.PartyEdited:
      return {
        dot: (
          <IconWrapper>
            <EditOutlined />
          </IconWrapper>
        ),
        color: DEFAULT_COLOR,
        children: (
          <>
            <b>{authorEvent}</b> edited{' '}
            <b>{R.path(['data', 'partyName'], log)}</b>
            <PartyProjectLine log={log} />
            <DateTimeline date={log.createdAt} />
          </>
        ),
      };
    case EventType.PartyEditedAdditionalNotes:
      return {
        dot: (
          <IconWrapper>
            <EditOutlined />
          </IconWrapper>
        ),
        color: DEFAULT_COLOR,
        children: (
          <>
            <b>{authorEvent}</b> edited Additional notes section
            <PartyProjectLine log={log} />
            <DateTimeline date={log.createdAt} />
          </>
        ),
      };
    case EventType.PartyMarkAs:
      return {
        dot: (
          <IconWrapper>
            {R.path(['data', 'isActive'], log) ? (
              <ReloadOutlined />
            ) : (
              <PoweroffOutlined />
            )}
          </IconWrapper>
        ),
        color: DEFAULT_COLOR,
        children: (
          <>
            <b>{authorEvent}</b> marked{' '}
            <b>{R.path(['data', 'partyName'], log)}</b> as{' '}
            {R.path(['data', 'isActive'], log) ? 'active' : 'inactive'}
            <PartyProjectLine log={log} />
            <DateTimeline date={log.createdAt} />
          </>
        ),
      };
    case EventType.PartyTagAdded:
      return {
        dot: (
          <IconWrapper>
            <TagOutlined />
          </IconWrapper>
        ),
        color: DEFAULT_COLOR,
        children: (
          <>
            <b>{authorEvent}</b> added the tag{' '}
            <b>{R.path(['data', 'tagName'], log)}</b> to{' '}
            <b>{R.path(['data', 'partyName'], log)}</b>
            <PartyProjectLine log={log} />
            <DateTimeline date={log.createdAt} />
          </>
        ),
      };
    case EventType.PartyTagDeleted:
      return {
        dot: (
          <IconWrapper>
            <TagOutlined />
          </IconWrapper>
        ),
        color: DEFAULT_COLOR,
        children: (
          <>
            <b>{authorEvent}</b> removed the tag{' '}
            <b>{R.path(['data', 'tagName'], log)}</b> from{' '}
            <b>{R.path(['data', 'partyName'], log)}</b>
            <PartyProjectLine log={log} />
            <DateTimeline date={log.createdAt} />
          </>
        ),
      };
    case EventType.PartyContactCreated:
      return {
        dot: (
          <IconWrapper>
            <UserOutlined />
          </IconWrapper>
        ),
        color: DEFAULT_COLOR,
        children: (
          <>
            <b>{authorEvent}</b> created contact{' '}
            <b>{R.path(['data', 'contactName'], log)}</b>
            <PartyProjectLine log={log} />
            <DateTimeline date={log.createdAt} />
          </>
        ),
      };
    case EventType.PartyContactDeleted:
      return {
        dot: (
          <IconWrapper>
            <UserDeleteOutlined />
          </IconWrapper>
        ),
        color: DEFAULT_COLOR,
        children: (
          <>
            <b>{authorEvent}</b> deleted contact{' '}
            <b>{R.path(['data', 'contactName'], log)}</b>
            <PartyProjectLine log={log} />
            <DateTimeline date={log.createdAt} />
          </>
        ),
      };
    case EventType.ComplianceChanged:
      return {
        dot: (
          <IconWrapper>
            {log?.data?.compliant ? <CheckOutlined /> : <CloseOutlined />}
          </IconWrapper>
        ),
        color: DEFAULT_COLOR,
        children: (
          <>
            <b>{authorEvent}</b> status changed to{' '}
            {log?.data?.compliant ? 'compliant' : 'non-compliant'}
            <PartyProjectLine log={log} />
            <DateTimeline date={log.createdAt} />
          </>
        ),
      };
    case EventType.ComplianceTrackingEnabled:
      return {
        dot: (
          <IconWrapper>
            <PoweroffOutlined />
          </IconWrapper>
        ),
        color: DEFAULT_COLOR,
        children: (
          <>
            <b>{authorEvent}</b> enabled compliance tracking
            <PartyProjectLine log={log} />
            <DateTimeline date={log.createdAt} />
          </>
        ),
      };
    case EventType.ComplianceTrackingDisabled:
      return {
        dot: (
          <IconWrapper>
            <PoweroffOutlined />
          </IconWrapper>
        ),
        color: DEFAULT_COLOR,
        children: (
          <>
            <b>{authorEvent}</b> disabled compliance tracking
            <PartyProjectLine log={log} />
            <DateTimeline date={log.createdAt} />
          </>
        ),
      };
    case EventType.ExpiringPartyTagDeleted:
      return {
        dot: (
          <IconWrapper>
            <TagOutlined />
          </IconWrapper>
        ),
        color: DEFAULT_COLOR,
        children: (
          <>
            <b>TrustLayer</b> removed the expiring tag{' '}
            <b>{R.path(['data', 'tagName'], log)}</b> from{' '}
            <b>{R.path(['data', 'partyName'], log)}</b>
            <PartyProjectLine log={log} />
            <DateTimeline date={log.createdAt} />
          </>
        ),
      };
    case EventType.ComplianceProfileAssign:
      const projectName = R.prop('projectName', log);
      const oldComplianceProfileName = R.path(
        ['data', 'oldComplianceProfileName'],
        log,
      );
      const newComplianceProfile = R.path(
        ['data', 'complianceProfileName'],
        log,
      );

      return {
        dot: (
          <IconWrapper>
            <ProfileOutlined />
          </IconWrapper>
        ),
        color: DEFAULT_COLOR,
        children: (
          <>
            <b>{authorEvent}</b> changed compliance profile{' '}
            {oldComplianceProfileName && (
              <>
                from <b>{oldComplianceProfileName}</b>{' '}
              </>
            )}
            to <b>{newComplianceProfile}</b>{' '}
            {projectName && (
              <>
                on project <b>{projectName}</b>
              </>
            )}
            <PartyProjectLine log={log} />
            <DateTimeline date={log.createdAt} />
          </>
        ),
      };
    case EventType.MessageSend:
      return {
        dot: (
          <IconWrapper>
            <MailOutlined />
          </IconWrapper>
        ),
        color: DEFAULT_COLOR,
        children: (
          <>
            <b>{authorEvent}</b> sent a message to{' '}
            <b>{R.path(['data', 'recipient'], log)}</b>
            <PartyProjectLine log={log} />
            <DateTimeline date={log.createdAt} />
          </>
        ),
      };
    case EventType.RequestSend:
      return {
        'data-cy': 'activityTimeline',
        dot: (
          <IconWrapper>
            <PaperPlane />
          </IconWrapper>
        ),
        color: DEFAULT_COLOR,
        children: (
          <>
            <b>{authorEvent}</b> sent a document request to{' '}
            <b>{R.pathOr([], ['data', 'recipients'], log)?.join(', ')}</b>
            <PartyProjectLine log={log} />
            <DateTimeline date={log.createdAt} />
          </>
        ),
      };
    case EventType.RequestSendReminder:
      return {
        dot: (
          <IconWrapper>
            <PaperPlane />
          </IconWrapper>
        ),
        color: DEFAULT_COLOR,
        children: (
          <>
            <b>{authorEvent}</b> sent a reminder to{' '}
            <b>{R.pathOr([], ['data', 'recipients'], log)?.join(', ')}</b>
            <PartyProjectLine log={log} />
            <DateTimeline date={log.createdAt} />
          </>
        ),
      };
    case EventType.RequestSendWebForm:
      return {
        dot: (
          <IconWrapper>
            <PaperPlane />
          </IconWrapper>
        ),
        color: DEFAULT_COLOR,
        children: (
          <>
            <b>{authorEvent}</b> sent a web form request to{' '}
            <b>{R.pathOr([], ['data', 'recipients'], log)?.join(', ')}</b>
            <PartyProjectLine log={log} />
            <DateTimeline date={log.createdAt} />
          </>
        ),
      };
    case EventType.RequestSendReminderAutomatic:
      return {
        dot: (
          <IconWrapper>
            <PaperPlane />
          </IconWrapper>
        ),
        color: DEFAULT_COLOR,
        children: (
          <>
            Automatic reminder was sent to{' '}
            <b>{R.path(['data', 'partyName'], log)}</b>
            <PartyProjectLine log={log} />
            <DateTimeline date={log.createdAt} />
          </>
        ),
      };
    case EventType.RequestSendAutomatic:
      return {
        dot: (
          <IconWrapper>
            <PaperPlane />
          </IconWrapper>
        ),
        color: DEFAULT_COLOR,
        children: (
          <>
            Automatic document request was sent to{' '}
            <b>{R.prop('partyName', log)}</b>
            <PartyProjectLine log={log} />
            <DateTimeline date={log.createdAt} />
          </>
        ),
      };
    case EventType.PaymentSendReminder:
      return {
        dot: (
          <IconWrapper>
            <PaperPlane />
          </IconWrapper>
        ),
        color: DEFAULT_COLOR,
        children: (
          <>
            Automatic payment reminder was sent to{' '}
            <b>{R.prop('partyName', log)}</b>
            <PartyProjectLine log={log} />
            <DateTimeline date={log.createdAt} />
          </>
        ),
      };
    case EventType.DocumentUploaded:
      return {
        dot: (
          <IconWrapper>
            <UploadOutlined />
          </IconWrapper>
        ),
        color: DEFAULT_COLOR,
        children: (
          <>
            <b>{authorEvent}</b> uploaded document{' '}
            <DocumentLinkName document={{ _id: log.contextEntityId }}>
              {R.pathOr('', ['data', 'documentName'], log)}
            </DocumentLinkName>
            <PartyProjectLine log={log} />
            <DateTimeline date={log.createdAt} />
          </>
        ),
      };
    case EventType.ReportDocumentsUploaded:
      return {
        dot: (
          <IconWrapper>
            <CaretRightOutlined />
          </IconWrapper>
        ),
        color: DEFAULT_COLOR,
        children: (
          <>
            <b>{authorEvent}</b> compliance report based on uploaded files:
            <ComplianceStatusContainer>
              <b>
                {R.path(['data', 'compliant'], log)} of{' '}
                {R.path(['data', 'totalRequirementsCount'], log)} requirements
                marked as compliant{' '}
              </b>
            </ComplianceStatusContainer>
            <ComplianceStatusContainer>
              <b>
                {R.path(['data', 'nonCompliant'], log)} of{' '}
                {R.path(['data', 'totalRequirementsCount'], log)} requirements
                marked as noncompliant{' '}
              </b>
            </ComplianceStatusContainer>
            <PartyProjectLine log={log} />
            <DateTimeline date={log.createdAt} />
          </>
        ),
      };
    case EventType.DocumentReviewed:
      return {
        dot: (
          <IconWrapper>
            <FileSearchOutlined />
          </IconWrapper>
        ),
        color: DEFAULT_COLOR,
        children: (
          <>
            <b>{authorEvent}</b> marked document{' '}
            <DocumentLinkName document={{ _id: log.contextEntityId }}>
              {R.pathOr('', ['data', 'documentName'], log)}
            </DocumentLinkName>{' '}
            as{' '}
            {R.pathOr('', ['data', 'isReviewed'], log)
              ? 'reviewed'
              : 'to review'}
            <PartyProjectLine log={log} />
            <DateTimeline date={log.createdAt} />
          </>
        ),
      };
    case EventType.DocumentArchived:
      return {
        dot: (
          <IconWrapper>
            {R.pathOr('', ['data', 'isArchived'], log) ? (
              <ContainerOutlined />
            ) : (
              <CaretRightOutlined />
            )}
          </IconWrapper>
        ),
        color: DEFAULT_COLOR,
        children: (
          <>
            <b>{authorEvent}</b> marked document{' '}
            <DocumentLinkName document={{ _id: log.contextEntityId }}>
              {R.pathOr('', ['data', 'documentName'], log)}
            </DocumentLinkName>{' '}
            as{' '}
            {R.pathOr('', ['data', 'isArchived'], log) ? 'archived' : 'active'}
            <PartyProjectLine log={log} />
            <DateTimeline date={log.createdAt} />
          </>
        ),
      };
    case EventType.DocumentDeleted:
      return {
        dot: (
          <IconWrapper>
            <DeleteOutlined />
          </IconWrapper>
        ),
        color: DEFAULT_COLOR,
        children: (
          <>
            <b>{authorEvent}</b> deleted{' '}
            <DocumentLinkName document={{ _id: log.contextEntityId }}>
              {R.pathOr('', ['data', 'documentName'], log)}
            </DocumentLinkName>
            <PartyProjectLine log={log} />
            <DateTimeline date={log.createdAt} />
          </>
        ),
      };
    case EventType.DocumentFlagAdded:
      return {
        dot: (
          <IconWrapper>
            <FlagIcon
              color={
                themeContext.colors[
                  R.propOr(
                    BadgeColors.grayIcon,
                    R.path(['data', 'severityLevel'], log),
                    DocumentSeverityColorMap,
                  )
                ]
              }
              size={16}
            />
          </IconWrapper>
        ),
        color: DEFAULT_COLOR,
        children: (
          <>
            <div>
              <b>{authorEvent}</b> added flag to document{' '}
              <DocumentLinkName document={{ _id: log.contextEntityId }}>
                {R.pathOr('', ['data', 'documentName'], log)}
              </DocumentLinkName>{' '}
              <PartyProjectLine log={log} />
              <DateTimeline date={log.createdAt} />
            </div>
            {log?.data?.notes && (
              <Notes
                dangerouslySetInnerHTML={{
                  __html: log.data.notes,
                }}
              />
            )}
          </>
        ),
      };
    case EventType.DocumentFlagEdited:
      return {
        dot: (
          <IconWrapper>
            <FlagIcon
              color={
                themeContext.colors[
                  R.propOr(
                    BadgeColors.grayIcon,
                    R.path(['data', 'severityLevel'], log),
                    DocumentSeverityColorMap,
                  )
                ]
              }
              size={16}
            />
          </IconWrapper>
        ),
        color: DEFAULT_COLOR,
        children: (
          <>
            <div>
              <b>{authorEvent}</b> edited flag details on document{' '}
              <DocumentLinkName document={{ _id: log.contextEntityId }}>
                {R.pathOr('', ['data', 'documentName'], log)}
              </DocumentLinkName>{' '}
              <PartyProjectLine log={log} />
              <DateTimeline date={log.createdAt} />
            </div>
            {log?.data?.notes && (
              <Notes
                dangerouslySetInnerHTML={{
                  __html: log.data.notes,
                }}
              />
            )}
          </>
        ),
      };
    case EventType.DocumentFlagRemoved:
      return {
        dot: (
          <IconWrapper>
            <CaretRightOutlined />
          </IconWrapper>
        ),
        color: DEFAULT_COLOR,
        children: (
          <>
            <b>{authorEvent}</b> removed flag from document{' '}
            <DocumentLinkName document={{ _id: log.contextEntityId }}>
              {R.pathOr('', ['data', 'documentName'], log)}
            </DocumentLinkName>{' '}
            <PartyProjectLine log={log} />
            <DateTimeline date={log.createdAt} />
          </>
        ),
      };
    case EventType.DocumentVerificationLogged:
      return {
        dot: (
          <IconWrapper>
            <VerifiedIcon size={16} color="#4ED256" />
          </IconWrapper>
        ),
        color: DEFAULT_COLOR,
        children: (
          <>
            <div>
              <b>{authorEvent}</b> logged verification on document{' '}
              <DocumentLinkName document={{ _id: log.contextEntityId }}>
                {R.pathOr('', ['data', 'documentName'], log)}
              </DocumentLinkName>{' '}
              <PartyProjectLine log={log} />
              <DateTimeline date={log.createdAt} />
            </div>
            {log?.data?.notes && <Notes>{log.data.notes}</Notes>}
          </>
        ),
      };
    case EventType.DocumentVerificationEdited:
      return {
        dot: (
          <IconWrapper>
            <VerifiedIcon size={16} color="#4ED256" />
          </IconWrapper>
        ),
        color: DEFAULT_COLOR,
        children: (
          <>
            <div>
              <b>{authorEvent}</b> edited verification details on document{' '}
              <DocumentLinkName document={{ _id: log.contextEntityId }}>
                {R.pathOr('', ['data', 'documentName'], log)}
              </DocumentLinkName>{' '}
              <PartyProjectLine log={log} />
              <DateTimeline date={log.createdAt} />
            </div>
            {log?.data?.notes && <Notes>{log.data.notes}</Notes>}
          </>
        ),
      };
    case EventType.DocumentVerificationRemoved:
      return {
        dot: (
          <IconWrapper>
            <CaretRightOutlined />
          </IconWrapper>
        ),
        color: DEFAULT_COLOR,
        children: (
          <>
            <b>{authorEvent}</b> removed verification from document{' '}
            <DocumentLinkName document={{ _id: log.contextEntityId }}>
              {R.pathOr('', ['data', 'documentName'], log)}
            </DocumentLinkName>{' '}
            <PartyProjectLine log={log} />
            <DateTimeline date={log.createdAt} />
          </>
        ),
      };
    case EventType.DocumentAssignedToParty:
      return {
        dot: (
          <IconWrapper>
            <FileAddOutlined />
          </IconWrapper>
        ),
        color: DEFAULT_COLOR,
        children: (
          <>
            <b>{authorEvent}</b> assigned{' '}
            <DocumentLinkName document={{ _id: log.contextEntityId }}>
              {R.pathOr('', ['data', 'documentName'], log)}
            </DocumentLinkName>{' '}
            to party
            <PartyProjectLine log={log} />
            <DateTimeline date={log.createdAt} />
          </>
        ),
      };
    case EventType.DocumentAssociatedAllProjects:
      return {
        dot: (
          <IconWrapper>
            <CaretRightOutlined />
          </IconWrapper>
        ),
        color: DEFAULT_COLOR,
        children: (
          <>
            <b>{authorEvent}</b> associated document{' '}
            <DocumentLinkName document={{ _id: log.contextEntityId }}>
              {R.pathOr('', ['data', 'documentName'], log)}
            </DocumentLinkName>{' '}
            to all projects <PartyProjectLine log={log} />
            <DateTimeline date={log.createdAt} />
          </>
        ),
      };
    case EventType.DocumentAssociatedToProject:
      return {
        dot: (
          <IconWrapper>
            <CaretRightOutlined />
          </IconWrapper>
        ),
        color: DEFAULT_COLOR,
        children: (
          <>
            <b>{authorEvent}</b> associated document{' '}
            <DocumentLinkName document={{ _id: log.contextEntityId }}>
              {R.pathOr('', ['data', 'documentName'], log)}
            </DocumentLinkName>{' '}
            to project <PartyProjectLine log={log} />
            <DateTimeline date={log.createdAt} />
          </>
        ),
      };
    case EventType.CommentCreated:
      if (editableComments) {
        return {
          dot: (
            <CommentIconWrapper>
              {R.path(['data', 'avatarLink'], log) ? (
                <Avatar size={40} src={R.path(['data', 'avatarLink'], log)} />
              ) : (
                <UserAvatar
                  name={R.path(['data', 'userName'], log)}
                  email={R.path(['data', 'userEmail'], log)}
                />
              )}
            </CommentIconWrapper>
          ),
          children: <CommentEventLogContainer log={log} />,
        };
      }

      const message = R.pathOr('', ['data', 'message'], log);

      return {
        dot: (
          <IconWrapper>
            <CommentOutlined />
          </IconWrapper>
        ),
        color: DEFAULT_COLOR,
        children: (
          <>
            {R.path(['data', 'userName'], log)
              ? `${R.path(['data', 'userName'], log)} (${R.path(
                  ['data', 'userEmail'],
                  log,
                )})`
              : R.path(['data', 'userEmail'], log)}{' '}
            added a comment on {R.prop('partyName', log)}:
            <Blockquote>{htmlToText(message)}</Blockquote>
            <PartyProjectLine log={log} />
            <DateTimeline date={log.createdAt} />
          </>
        ),
      };
    case EventType.ConversationMessageSent:
      return {
        dot: (
          <IconWrapper>
            <MessageOutlined />
          </IconWrapper>
        ),
        color: DEFAULT_COLOR,
        children: (
          <>
            <b>{authorEvent}</b> sent a message to{' '}
            <b>{R.pathOr([], ['data', 'recipients'], log).join(', ')}</b> in the
            conversation{' '}
            <ConversationLinkName
              conversation={{
                _id: R.path(['data', 'conversationId'], log),
                partyId: R.prop('contextEntityId', log),
              }}
            >
              {R.path(['data', 'conversationSubject'], log)}
            </ConversationLinkName>
            <PartyProjectLine log={log} />
            <DateTimeline date={log.createdAt} />
          </>
        ),
      };
    case EventType.ConversationMessageReplied:
      return {
        dot: (
          <IconWrapper>
            <MessageOutlined />
          </IconWrapper>
        ),
        color: DEFAULT_COLOR,
        children: (
          <>
            <b>{authorEvent}</b> replied to the conversation{' '}
            <ConversationLinkName
              conversation={{
                _id: R.path(['data', 'conversationId'], log),
                partyId: R.prop('contextEntityId', log),
              }}
            >
              {R.path(['data', 'conversationSubject'], log)}
            </ConversationLinkName>
            <PartyProjectLine log={log} />
            <DateTimeline date={log.createdAt} />
          </>
        ),
      };
    case EventType.RequirementWaiverAdded:
    case EventType.RequirementOverrideAdded:
      return {
        dot: (
          <IconWrapper>
            <CaretRightOutlined />
          </IconWrapper>
        ),
        color: DEFAULT_COLOR,
        children: (
          <>
            <span>
              <b>{authorEvent}</b>{' '}
              {log.type === EventType.RequirementWaiverAdded
                ? 'waived'
                : 'overrode'}{' '}
              the requirement
              <RuleDisplayValue rule={log.data} /> on <b>{subjectLabel}</b>
              {until && ` until ${moment(until).format('ll')}`}
            </span>{' '}
            <PartyProjectLine log={log} />
            <DateTimeline date={log.createdAt} />
            {Boolean(notes) && <Notes>{notes}</Notes>}
          </>
        ),
      };
    case EventType.RequirementWaiverRemoved:
    case EventType.RequirementOverrideRemoved:
      return {
        dot: (
          <IconWrapper>
            <CaretRightOutlined />
          </IconWrapper>
        ),
        color: DEFAULT_COLOR,
        children: (
          <>
            <span>
              <b>{authorEvent}</b> removed the{' '}
              {log.type === EventType.RequirementWaiverRemoved
                ? 'waiver'
                : 'override'}{' '}
              from the requirement
              <RuleDisplayValue rule={log.data} /> on <b>{subjectLabel}</b>
            </span>
            <PartyProjectLine log={log} />
            <DateTimeline date={log.createdAt} />
          </>
        ),
      };
    case EventType.SubjectWaiverAdded:
    case EventType.SubjectOverrideAdded:
      return {
        dot: (
          <IconWrapper>
            <CaretRightOutlined />
          </IconWrapper>
        ),
        color: DEFAULT_COLOR,
        children: (
          <>
            <span>
              <b>{authorEvent}</b>{' '}
              {log.type === EventType.SubjectWaiverAdded
                ? 'waived'
                : 'overrode'}{' '}
              the subject
              <RuleDisplayValue rule={log.data} /> on <b>{subjectLabel}</b>
              {until && ` until ${moment(until).format('ll')}`}
            </span>{' '}
            <PartyProjectLine log={log} />
            <DateTimeline date={log.createdAt} />
            {Boolean(notes) && <Notes>{notes}</Notes>}
          </>
        ),
      };
    case EventType.SubjecttWaiverRemoved:
    case EventType.SubjecttOverrideRemoved:
      return {
        dot: (
          <IconWrapper>
            <CaretRightOutlined />
          </IconWrapper>
        ),
        color: DEFAULT_COLOR,
        children: (
          <>
            <span>
              <b>{authorEvent}</b> removed the{' '}
              {log.type === EventType.SubjectWaiverAdded
                ? 'waiver'
                : 'override'}{' '}
              from the subject
              <RuleDisplayValue rule={log.data} /> on <b>{subjectLabel}</b>
            </span>
            <PartyProjectLine log={log} />
            <DateTimeline date={log.createdAt} />
          </>
        ),
      };
    case EventType.AutomaticRequestsEnabled:
      return {
        dot: (
          <IconWrapper>
            <PoweroffOutlined />
          </IconWrapper>
        ),
        color: DEFAULT_COLOR,
        children: (
          <>
            <span>
              <b>{authorEvent}</b> enabled automatic requests for expiring
              documents
            </span>
            <PartyProjectLine log={log} />
            <DateTimeline date={log.createdAt} />
          </>
        ),
      };
    case EventType.AutomaticRequestsDisabled:
      return {
        dot: (
          <IconWrapper>
            <PoweroffOutlined />
          </IconWrapper>
        ),
        color: DEFAULT_COLOR,
        children: (
          <>
            <span>
              <b>{authorEvent}</b> disabled automatic requests for expiring
              documents
            </span>
            <PartyProjectLine log={log} />
            <DateTimeline date={log.createdAt} />
          </>
        ),
      };
    case EventType.AutomationEnabled:
      return {
        dot: (
          <IconWrapper>
            <PoweroffOutlined />
          </IconWrapper>
        ),
        color: DEFAULT_COLOR,
        children: (
          <>
            <span>
              <b>{authorEvent}</b> enabled {log.data.automationName} automation
            </span>
            <PartyProjectLine log={log} />
            <DateTimeline date={log.createdAt} />
          </>
        ),
      };
    case EventType.AutomationDisabled:
      return {
        dot: (
          <IconWrapper>
            <PoweroffOutlined />
          </IconWrapper>
        ),
        color: DEFAULT_COLOR,
        children: (
          <>
            <span>
              <b>{authorEvent}</b> disabled {log.data.automationName} automation
            </span>
            <PartyProjectLine log={log} />
            <DateTimeline date={log.createdAt} />
          </>
        ),
      };
    case EventType.ProjectCreated:
      return {
        dot: (
          <IconWrapper>
            <UserOutlined />
          </IconWrapper>
        ),
        color: DEFAULT_COLOR,
        children: (
          <>
            <span>
              <b>{authorEvent}</b> created project <b>{log.projectName}</b>
            </span>
            <DateTimeline date={log.createdAt} />
          </>
        ),
      };
    case EventType.PartyCreateMatchingDocument:
      return {
        dot: (
          <IconWrapper>
            <CheckCircleOutlined />
          </IconWrapper>
        ),
        color: DEFAULT_COLOR,
        children: (
          <>
            <b>{authorEvent}</b> made <b>{log.partyName}</b> compliant and
            document created{' '}
            <DocumentLinkName
              document={{
                _id: log.data?.documentId,
                party: { _id: log.partyId },
              }}
            >
              {log.data?.documentName}
            </DocumentLinkName>
            {log.projectName && (
              <>
                {' '}
                on project <b>{log.projectName}</b>
              </>
            )}
            <PartyProjectLine log={log} />
            <DateTimeline date={log.createdAt} />
          </>
        ),
      };
    case EventType.RequestRecordCreated:
      return {
        dot: (
          <IconWrapper>
            <UserOutlined />
          </IconWrapper>
        ),
        color: DEFAULT_COLOR,
        children: (
          <>
            <b>{authorEvent}</b> created a new request{' '}
            <Link
              to={{
                pathname: `${location.pathname.replace('/activity', '')}/requests/${log.data.requestRecord.id}/compliance`,
              }}
            >
              {log.data.requestRecord.name}
            </Link>
            <PartyProjectLine
              log={{
                partyName: log.primaryRecordName,
                projectName: log.projectName,
                partyId: log.primaryRecordId,
              }}
            />
            <DateTimeline date={log.createdAt} />
          </>
        ),
      };
    case EventType.RequestRecordUpdated:
      return {
        dot: (
          <IconWrapper>
            <UserOutlined />
          </IconWrapper>
        ),
        color: DEFAULT_COLOR,
        children: (
          <>
            <b>{authorEvent}</b> updated request{' '}
            <Link
              to={{
                pathname: `${location.pathname.replace('/activity', '')}/requests/${log.data.requestRecord.id}/compliance`,
              }}
            >
              {log.data.requestRecord.name}
            </Link>
            <PartyProjectLine
              log={{
                partyName: log.primaryRecordName,
                projectName: log.projectName,
                partyId: log.primaryRecordId,
              }}
            />
            <DateTimeline date={log.createdAt} />
          </>
        ),
      };
    case EventType.RequestRecordDeleted:
      return {
        dot: (
          <IconWrapper>
            <UserOutlined />
          </IconWrapper>
        ),
        color: DEFAULT_COLOR,
        children: (
          <>
            <b>{authorEvent}</b> deleted request {log.data.requestRecord.name}
            <PartyProjectLine
              log={{
                partyName: log.primaryRecordName,
                projectName: log.projectName,
                partyId: log.primaryRecordId,
              }}
            />
            <DateTimeline date={log.createdAt} />
          </>
        ),
      };
    default:
      console.debug('???', log.type);
      return null;
  }
};

const IconWrapper = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f4f8fd;
  border-radius: 50%;
  border: 1px solid #cfdae7;
`;

const Blockquote = styled.blockquote`
  font-size: 12px;
  margin: 5px;
  color: rgba(0, 0, 0, 0.65);
`;

const CommentIconWrapper = styled(IconWrapper)`
  position: absolute;
  left: -73px;
  top: -11.5px;
  width: auto;
  height: auto;
`;

const ComplianceStatusContainer = styled.div`
  margin-top: 5px;
  padding-bottom: 5px;

  b {
    margin-left: 5px;
  }
`;

const Notes = styled.div`
  margin-top: 5px;
  color: ${(props) => props.theme.colors.lightBlack};
  font-size: 12px;
`;
