import { FILTER_OPERATORS } from '@common/constants/filters/serverFilters';
import { useMutation } from '@graphql/hooks';
import { graphql } from '@graphql/types';

const DELETE_PORTAL_ATTACHMENTS_MUTATION = graphql(`
  mutation DeletePortalAttachments($data: DeletePortalAttachmentsInput!) {
    deletePortalAttachments(data: $data)
  }
`);

export const useDeletePortalAttachment = (primaryRecordId: string) => {
  const [
    deletePortalAttachmentMutation,
    { loading: isDeletingPortalAttachment },
  ] = useMutation(DELETE_PORTAL_ATTACHMENTS_MUTATION);

  const deletePortalAttachment = (s3Key: string) => {
    return deletePortalAttachmentMutation({
      variables: {
        data: {
          primaryRecordId,
          filter: {
            and: [
              {
                name: 's3Key',
                operator: FILTER_OPERATORS.equal,
                value: s3Key,
              },
            ],
          },
        },
      },
    });
  };

  return {
    deletePortalAttachment,
    isDeletingPortalAttachment,
  };
};
