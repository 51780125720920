import isEmpty from 'lodash/isEmpty';
import * as R from 'ramda';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import uuidByString from 'uuid-by-string';

import { getTemplates } from '@modules/requirement/selectors';
import {
  getAttributeById,
  getIsCustomModule,
  getIsCustomSubject,
  getIsFillableFormModule,
  getIsSurveyModule,
} from '../utils/compliance-attributes-helpers';

import { getIsCustomRequirement } from '@modules/requirement/utils/requirement-helpers';
import * as ComplianceProfileActions from '../actions';
import AddRequirement from '../components/AddRequirement';
import deleteEntityModal from '../components/DeleteEntityModal';
import RequirementItem from '../components/RequirementItem';
import {
  AMBEST_RATING_TARGET_VALUE,
  AMBEST_REQUIREMENT_TARGET_VALUE,
  AttributeType,
  BASE_BOOLEAN_VALUE,
  BASE_TARGET_VALUE,
  CustomAttributeTypeByOperator,
  DefaultRule,
  Operator,
  isInputNumber,
} from '../constants';
import { generateCascaderOptions } from '../utils/rule-helpers';

const CUSTOM_REQUIREMENT = {
  value: 'add custom',
  label: '+ Add custom requirement',
  children: [
    {
      value: Operator.PRESENT,
      label: Operator.PRESENT,
    },
    {
      value: Operator.GREATER_OR_EQUAL,
      label: Operator.GREATER_OR_EQUAL,
    },
    {
      value: Operator.LESS_OR_EQUAL,
      label: Operator.LESS_OR_EQUAL,
    },
  ],
};

const getAttributeType = (selectedAttribute, targetValue) => {
  if (
    [
      AttributeType.AmBestRating,
      AttributeType.AmBestFinancialStrength,
      AttributeType.Connected,
      AttributeType.Ai,
    ].includes(selectedAttribute.attributeType)
  ) {
    return selectedAttribute.attributeType;
  }

  return Number.isNaN(parseInt(targetValue))
    ? AttributeType.Boolean
    : AttributeType.Number;
};

const RequirementContainer = ({
  subject,
  moduleId,
  isRequirementRow,
  moduleLabel,
  requirements,
  requirement,
  index,
}) => {
  const [isShowForm, setIsShowForm] = useState(false);
  const [isCustom, setIsCustom] = useState(false);
  const [isShowDescription, setIsShowDescription] = useState(false);
  const [selectedAttribute, setSelectedAttribute] = useState({});
  const [selectedOperator, setSelectedOperator] = useState('');
  const [description, setDescription] = useState('');
  const [matchingCriteria, setMatchingCriteria] = useState('');
  const [targetValue, setTargetValue] = useState('');
  const [isValidRequirement, setIsValidRequirement] = useState(true);
  const templates = useSelector(getTemplates);
  const isCustomRequirement = getIsCustomRequirement(selectedAttribute);
  const isCustomSubject = getIsCustomSubject(
    subject.subjectId,
    moduleId,
    templates,
  );
  const isCustomModule = getIsCustomModule(moduleId, templates);
  const dispatch = useDispatch();

  const cascaderOptions = generateCascaderOptions(
    templates,
    requirements,
    moduleId,
    subject.subjectId,
    isRequirementRow ? selectedAttribute.attributeId : undefined,
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: Legacy
  useEffect(() => {
    if (isRequirementRow) {
      setSelectedAttribute({
        attributeId: requirement.attributeId,
        attributeLabel: requirement.attributeLabel,
        attributeType: requirement.attributeType,
        masterDocumentAttributeId: requirement.masterDocumentAttributeId,
        invalidatedBy: requirement.invalidatedBy,
        invalidationScope: requirement.invalidationScope,
      });
      setTargetValue(requirement.targetValue);
      setDescription(requirement.attributeDescription);
      setMatchingCriteria(requirement.matchingCriteria);
      setSelectedOperator(requirement.operator);
    }
  }, [isRequirementRow]);

  useEffect(() => {
    if (!targetValue) {
      setIsValidRequirement(false);
    }
    setIsValidRequirement(true);
  }, [targetValue]);

  const isActiveCreate = () => {
    if (isCustom || isCustomSubject) {
      return Boolean(selectedAttribute.attributeLabel) && isValidRequirement;
    }

    if (!isEmpty(selectedAttribute) && Boolean(selectedOperator)) {
      if (isInputNumber(selectedOperator)) {
        return Boolean(targetValue);
      }
      return true;
    }
    return false;
  };

  const resetState = () => {
    if (isRequirementRow) {
      setIsShowDescription(!!requirement.attributeDescription);
      setSelectedAttribute({
        attributeId: requirement.attributeId,
        attributeLabel: requirement.attributeLabel,
        attributeType: requirement.attributeType,
        masterDocumentAttributeId: requirement.masterDocumentAttributeId,
        invalidatedBy: requirement.invalidatedBy,
        invalidationScope: requirement.invalidationScope,
      });
      setSelectedOperator(requirement.operator);
      setDescription(requirement.attributeDescription);
      setMatchingCriteria(requirement.matchingCriteria);
      setTargetValue(requirement.targetValue);
    } else {
      setIsShowForm(false);
      setIsCustom(false);
      setIsValidRequirement(true);
      setIsShowDescription(false);
      setSelectedAttribute({});
      setSelectedOperator('');
      setDescription('');
      setMatchingCriteria('');
      setTargetValue('');
    }
  };

  const createRequirement = () => {
    // TODO: Improve this workaround for fix a problem in the hardcoded type inside templates
    const attributeType = getAttributeType(selectedAttribute, targetValue);

    // This prevents a blank operator if `selectedOperator` has no value.
    const operator =
      selectedOperator === ''
        ? attributeType === AttributeType.Number
          ? Operator.GREATER_OR_EQUAL
          : Operator.PRESENT
        : selectedOperator;

    const definitionWithoutSubjectIndex = R.findIndex(
      (x) =>
        x.moduleId === moduleId &&
        x.subjectId === subject.subjectId &&
        !Boolean(x.attributeId),
      requirements,
    );

    const customRequirementIdentifier = uuidByString(
      moduleId
        .concat(subject.subjectId)
        .concat(selectedAttribute.attributeLabel.trim().toLowerCase()),
    );

    const requirement = {
      ...DefaultRule,
      attributeDescription: description,
      matchingCriteria: matchingCriteria,
      attributeId: isCustom
        ? customRequirementIdentifier
        : selectedAttribute.attributeId,
      attributeLabel: selectedAttribute.attributeLabel,
      attributeType,
      masterDocumentAttributeId: isCustom
        ? customRequirementIdentifier
        : selectedAttribute.masterDocumentAttributeId,
      moduleId,
      moduleLabel,
      operator,
      subjectId: subject.subjectId,
      subjectLabel: subject.subjectLabel,
      targetValue: targetValue?.toString(),
      invalidatedBy: isCustom ? null : selectedAttribute.invalidatedBy,
      invalidationScope: isCustom ? null : selectedAttribute.invalidationScope,
    };

    if (definitionWithoutSubjectIndex > -1) {
      dispatch(
        ComplianceProfileActions.setCurrentRequirements(
          R.update(definitionWithoutSubjectIndex, requirement, requirements),
        ),
      );
    } else {
      const newRequirements = R.append(requirement, requirements);
      dispatch(
        ComplianceProfileActions.setCurrentRequirements(newRequirements),
      );
    }

    resetState();
  };

  const searchFilter = (inputValue, path) =>
    path.some(
      (option) =>
        option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1,
    );

  const deleteRequirement = () => {
    deleteEntityModal({
      entityName: 'requirement',
      entityLabel: '',
      isCustom: getIsCustomRequirement(requirement),
      onOk: () => {
        const requirementsByAttribute = R.filter(
          R.propEq('attributeId', requirement.attributeId),
          requirements,
        );

        if (requirementsByAttribute.length === 1) {
          const [requirement] = requirementsByAttribute;
          const index = requirements.findIndex(
            R.propEq('attributeId', requirement.attributeId),
          );
          dispatch(
            ComplianceProfileActions.setCurrentRequirements(
              R.update(
                index,
                {
                  ...requirement,
                  attributeId: '',
                  attributeLabel: '',
                },
                requirements,
              ),
            ),
          );
        } else {
          dispatch(
            ComplianceProfileActions.setCurrentRequirements(
              R.filter(
                (currentRequirement) =>
                  currentRequirement.attributeId !== requirement.attributeId,
                requirements,
              ),
            ),
          );
        }
        setIsShowForm(false);
        resetState();
      },
    });
  };

  const onChangeHandle = ([selectedId, operator = '']) => {
    if (selectedId === CUSTOM_REQUIREMENT.value) {
      setIsCustom(true);
      setSelectedAttribute((prev) => ({
        ...prev,
        attributeType: CustomAttributeTypeByOperator[operator],
      }));
      setSelectedOperator(operator);

      switch (operator) {
        case Operator.GREATER_OR_EQUAL:
        case Operator.LESS_OR_EQUAL:
          setTargetValue(BASE_TARGET_VALUE);
          break;
        case Operator.PRESENT:
          setTargetValue(BASE_BOOLEAN_VALUE);
          break;

        default:
          break;
      }
    } else {
      const attribute = selectedId
        ? getAttributeById(selectedId, operator, requirements, templates)
        : {};
      setSelectedAttribute(attribute);
      setSelectedOperator(operator);

      if (attribute.attributeDescription) {
        setIsShowDescription(true);
        setDescription(attribute.attributeDescription);
      }
      switch (attribute.attributeType) {
        case AttributeType.Number:
          setTargetValue(BASE_TARGET_VALUE);
          break;

        case AttributeType.Boolean:
        case AttributeType.Connected:
          setTargetValue(BASE_BOOLEAN_VALUE);
          break;

        case AttributeType.AmBestRating:
          setTargetValue(AMBEST_RATING_TARGET_VALUE);
          break;

        case AttributeType.AmBestFinancialStrength:
          setTargetValue(AMBEST_REQUIREMENT_TARGET_VALUE);
          break;

        default:
          break;
      }
    }
  };

  const updateRequirement = () => {
    // TODO: Improve this workaround for fix a problem in the hardcoded type inside templates
    const attributeType = getAttributeType(selectedAttribute, targetValue);

    dispatch(
      ComplianceProfileActions.setCurrentRequirements(
        requirements.map((cur) =>
          cur.attributeId === requirement.attributeId
            ? {
                ...DefaultRule,
                attributeDescription: description,
                matchingCriteria,
                attributeId: selectedAttribute.attributeId,
                attributeLabel: selectedAttribute.attributeLabel,
                attributeType,
                masterDocumentAttributeId:
                  selectedAttribute.masterDocumentAttributeId,
                moduleId,
                moduleLabel,
                operator: selectedOperator,
                subjectId: subject.subjectId,
                subjectLabel: subject.subjectLabel,
                targetValue: targetValue?.toString(),
                invalidatedBy: selectedAttribute.invalidatedBy,
                invalidationScope: selectedAttribute.invalidationScope,
              }
            : cur,
        ),
      ),
    );
    setIsShowForm(false);
    resetState();
  };

  const validateCustomRequirement = (attributeLabel = '', attributeId) => {
    const subjectRequirements = requirements.filter(
      (requirement) =>
        requirement.subjectId === subject.subjectId &&
        requirement.attributeId !== attributeId,
    );

    if (
      Boolean(
        subjectRequirements.find(
          (requirement) => requirement.attributeLabel === attributeLabel.trim(),
        ),
      )
    ) {
      setIsValidRequirement(false);
      return Promise.reject('');
    }

    setIsValidRequirement(true);
    return Promise.resolve();
  };

  return isRequirementRow && index !== undefined ? (
    <RequirementItem
      description={description}
      matchingCriteria={matchingCriteria}
      cascaderOptions={cascaderOptions}
      isShowForm={isShowForm}
      isCustom={isCustom}
      isCustomSubject={isCustomSubject}
      isCustomModule={isCustomModule}
      isShowDescription={isShowDescription}
      setDescription={setDescription}
      setMatchingCriteria={setMatchingCriteria}
      selectedAttribute={selectedAttribute}
      selectedOperator={selectedOperator}
      onChangeHandle={onChangeHandle}
      targetValue={targetValue}
      searchFilter={searchFilter}
      setTargetValue={setTargetValue}
      setSelectedAttribute={setSelectedAttribute}
      setIsShowDescription={setIsShowDescription}
      setIsShowForm={setIsShowForm}
      isActiveCreate={isActiveCreate}
      isCustomRequirement={isCustomRequirement}
      requirement={requirement}
      resetState={resetState}
      isValidRequirement={isValidRequirement}
      validateCustomRequirement={validateCustomRequirement}
      setIsCustom={setIsCustom}
      deleteRequirement={deleteRequirement}
      updateRequirement={updateRequirement}
      index={index}
      hideActions={
        getIsFillableFormModule({ moduleId }) || getIsSurveyModule({ moduleId })
      }
    />
  ) : (
    <AddRequirement
      description={description}
      matchingCriteria={matchingCriteria}
      cascaderOptions={R.append(CUSTOM_REQUIREMENT, cascaderOptions)}
      isShowForm={isShowForm}
      isCustom={isCustom}
      isCustomSubject={isCustomSubject}
      isShowDescription={isShowDescription}
      setDescription={setDescription}
      setMatchingCriteria={setMatchingCriteria}
      selectedAttribute={selectedAttribute}
      selectedOperator={selectedOperator}
      onChangeHandle={onChangeHandle}
      targetValue={targetValue}
      searchFilter={searchFilter}
      setTargetValue={setTargetValue}
      setSelectedAttribute={setSelectedAttribute}
      setIsShowDescription={setIsShowDescription}
      setIsShowForm={setIsShowForm}
      setIsCustom={setIsCustom}
      isActiveCreate={isActiveCreate}
      createRequirement={createRequirement}
      resetState={resetState}
      isValidRequirement={isValidRequirement}
      validateCustomRequirement={validateCustomRequirement}
    />
  );
};

export default RequirementContainer;
