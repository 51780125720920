import * as R from 'ramda';

import {
  flatGroupedRules,
  groupRules,
} from '../utils/compliance-attributes-helpers';

import { STATE_KEY } from '../constants';

export const getComplianceProfiles = R.compose(
  R.filter((profile) => profile.context === 'global'),
  R.values,
  R.pathOr({}, [STATE_KEY, 'complianceProfile']),
);

export const getAllComplianceProfiles = R.compose(
  R.values,
  R.pathOr({}, [STATE_KEY, 'complianceProfile']),
);

export const getCurrentComplianceProfile = R.pathOr({}, [
  STATE_KEY,
  'currentComplianceProfile',
]);

export const getCurrentRequirements = (state) => {
  const requirements = R.pathOr([], [STATE_KEY, 'currentRequirements'])(state);
  return flatGroupedRules(groupRules(requirements));
};

export const getCurrentDocumentChecklists = R.pathOr(
  [],
  [STATE_KEY, 'currentDocumentChecklists'],
);

export const getComplianceProfileById = (state, complianceProfileId) =>
  R.compose(
    R.find(R.propEq('_id', complianceProfileId)),
    R.values,
    R.pathOr([], [STATE_KEY, 'complianceProfile']),
  )(state);
