/**
 * @deprecated - this will be removed in the future since party/project will be removed in v2
 */
export const ComplianceProfileContext = {
  Global: 'global',
  CustomField: 'customField',
  Party: 'party',
  Project: 'project',
  RequestRecord: 'requestRecord',
} as const;

export const BASE_TARGET_VALUE = 1000000 as const;
export const BASE_BOOLEAN_VALUE = '' as const;
export const AMBEST_RATING_TARGET_VALUE = 160 as const;
export const AMBEST_REQUIREMENT_TARGET_VALUE = 150 as const;
