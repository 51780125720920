import Icon, {
  DownOutlined,
  RightOutlined,
  SelectOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { Dropdown, Empty, Menu, Progress, Tag, Tooltip } from 'antd';
import * as R from 'ramda';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import Column from '@common/components/Column';
import ListPageTableWrapper from '@common/components/ListPageTableWrapper';
import Table from '@common/components/Table.legacy';
import TwoLinesText from '@common/components/TwoLinesText';
import { DAYJS_TABLE_FORMAT, SortDirectionsFullMap } from '@common/constants';
import DotsIcon from '@common/icons/Dots';
import { utc } from '@common/utils/date';
import { useIsComplianceRequirementsAddOnEnabled } from '@modules/add-on';
import ConnectivityBadge from '@modules/integration/components/ConnectivityBadge';
import { HideForViewerRole } from '@modules/organization-member/containers/HideForRoles';
import useMemberRole from '@modules/organization-member/hooks/useMemberRole';
import { ROLES } from '@modules/organization-member/models/data';
import { getOrganizationNamespaceUrl } from '@modules/organization/selectors';
import PartyComplianceIndicator from '@modules/party/components/PartyComplianceIndicator/PartyComplianceIndicator';
import { getNewGraphqlPayload } from '@store/helpers';

import { fetchPartyRequirementsByProject } from '../actions';
import { ProjectComplianceStatus, getProjectsColumns } from '../constants';
import { ProjectCustomFieldChips } from '../containers/ProjectCustomFieldChips';

const ProjectsTableView = ({
  partyId,
  isTrackingCompliance,
  loading,
  projects,
  currentProjectId,
  setProjectAsCurrent,
  selectedProjects,
  onOpenProjectDuplicateModal,
  updateSort,
  sortField,
  sortDirection,
  areAllProjectsSelected,
  updateProjectsActiveStatus,
  deleteProjects,
  onRowSelection,
  paginationOptions,
  openEditProjectModal,
  embeddedInPartyProfile = false,
  removePartyFromProjects,
}) => {
  const dispatch = useDispatch();
  const organizationNamespace = useSelector(getOrganizationNamespaceUrl);
  const { isComplianceRequirementsAddOnEnabled } =
    useIsComplianceRequirementsAddOnEnabled();
  const memberRole = useMemberRole();
  const MemoizedProjectCustomFieldChips = useMemo(
    () => (record) => {
      return <ProjectCustomFieldChips projectId={record.key} />;
    },
    [],
  );

  const getPartyData = (project) =>
    R.compose(
      R.assoc('_id', partyId),
      R.assoc('isTrackingCompliance', isTrackingCompliance),
      R.propOr({}, 'projectPartyData'),
    )(project);

  const initTableConfig = () => {
    const tableConfig = {
      loading,
      dataSource: projects.map((project) => ({
        key: R.prop('_id', project),
        name: (
          <Column>
            <ProjectsTableView.NameWrapper>
              <TwoLinesText>
                <ProjectsTableView.Name
                  onClick={() => {
                    setProjectAsCurrent(
                      R.prop('_id', project),
                      embeddedInPartyProfile
                        ? null
                        : `${organizationNamespace}/parties`,
                    );
                  }}
                >
                  {currentProjectId === project._id && (
                    <Tag color="#1890ff">current</Tag>
                  )}
                  <ConnectivityBadge entity={project} showTitle />
                  <span>{R.prop('name', project)}</span>
                </ProjectsTableView.Name>
              </TwoLinesText>
            </ProjectsTableView.NameWrapper>
          </Column>
        ),
        customFields: project?.customFields ?? {
          totalCount: 0,
        },
        status:
          Boolean(partyId) && isTrackingCompliance ? (
            <PartyComplianceIndicator
              popoverPlacement="topLeft"
              party={getPartyData(project)}
              fetchRequirements={async (partyId) => {
                const res = await dispatch(
                  fetchPartyRequirementsByProject(partyId, project._id),
                );
                const requirements = R.compose(
                  R.prop('requirements'),
                  R.head,
                  R.prop('data'),
                )(getNewGraphqlPayload(res));
                return requirements;
              }}
            />
          ) : (
            <Column>
              <ProjectsTableView.Progress
                data-cy="complianceProgressBar"
                format={() => (
                  <>
                    {R.path(
                      ['complianceStatus', 'compliantPartiesCount'],
                      project,
                    )}
                    /
                    {R.path(['complianceStatus', 'totalPartiesCount'], project)}
                  </>
                )}
                percent={
                  (R.path(
                    ['complianceStatus', 'compliantPartiesCount'],
                    project,
                  ) /
                    R.path(
                      ['complianceStatus', 'totalPartiesCount'],
                      project,
                    )) *
                  100
                }
                size="small"
                status={
                  R.path(['complianceStatus', 'status'], project) ===
                  ProjectComplianceStatus.Compliant
                    ? 'success'
                    : ''
                }
              />
            </Column>
          ),
        startDate: (
          <Column>
            {project.startDate
              ? utc(project.startDate).format(DAYJS_TABLE_FORMAT)
              : '—'}
          </Column>
        ),
        endDate: (
          <Column>
            {project.endDate
              ? utc(project.endDate).format(DAYJS_TABLE_FORMAT)
              : '—'}
          </Column>
        ),
        actions: (
          <ProjectsTableView.Actions>
            {currentProjectId === project._id ? (
              <Tooltip title="Current project">
                <SelectOutlined style={{ color: '#1890ff' }} />
              </Tooltip>
            ) : (
              <Tooltip title="Switch to this project">
                <SelectOutlined
                  data-cy="switchProjectButton"
                  onClick={() => setProjectAsCurrent(project._id)}
                />
              </Tooltip>
            )}

            <HideForViewerRole>
              <SettingOutlined
                onClick={() => openEditProjectModal(project)}
                data-cy="projectSettings"
              />
              <Dropdown
                dropdownRender={() =>
                  embeddedInPartyProfile ? (
                    <Menu>
                      <Menu.Item
                        key="action-menu-item-remove-from-project"
                        onClick={() => {
                          removePartyFromProjects(R.prop('_id', project));
                        }}
                        data-cy="removeFromThisProject"
                      >
                        Remove from this project
                      </Menu.Item>
                    </Menu>
                  ) : (
                    <Menu>
                      <Menu.Item
                        key="action-menu-item-duplicate-project"
                        onClick={() => onOpenProjectDuplicateModal(project)}
                      >
                        Duplicate project
                      </Menu.Item>
                      <Menu.Item
                        key="action-menu-item-active-inactive"
                        onClick={() =>
                          updateProjectsActiveStatus([R.prop('_id', project)])
                        }
                      >
                        {project.isActive ? 'Make inactive' : 'Make active'}
                      </Menu.Item>
                      <Menu.Item
                        key="action-menu-item-delete-project"
                        onClick={() => deleteProjects([R.prop('_id', project)])}
                        data-cy="deleteProjectButton"
                      >
                        Delete
                      </Menu.Item>
                    </Menu>
                  )
                }
                trigger={['click']}
              >
                <Icon component={DotsIcon} data-cy="projectActions" />
              </Dropdown>
            </HideForViewerRole>
          </ProjectsTableView.Actions>
        ),
      })),
      columns: getProjectsColumns({
        sortField,
        sortDirection,
        isPartyView: Boolean(partyId) && isTrackingCompliance,
        isComplianceRequirementsAddOnEnabled,
      }),
      expandable: {
        expandedRowRender: MemoizedProjectCustomFieldChips,
        rowExpandable: (project) => Boolean(project.customFields.totalCount),
        expandIcon: ({ expanded, onExpand, record: project }) => {
          if (!project.customFields.totalCount) {
            return null;
          }
          return expanded ? (
            <DownOutlined
              style={{ color: '#9c9c9c' }}
              onClick={(e) => onExpand(project, e)}
            />
          ) : (
            <RightOutlined
              style={{ color: '#9c9c9c' }}
              onClick={(e) => onExpand(project, e)}
            />
          );
        },
      },
      pagination: paginationOptions,
      sortDirections: [
        SortDirectionsFullMap.asc,
        SortDirectionsFullMap.desc,
        SortDirectionsFullMap.asc,
      ],
      showSorterTooltip: false,
      onChange: (_, __, sort) => {
        if (!sort.order) {
          sort.order = SortDirectionsFullMap.asc;
        }
        updateSort(sort);
      },
      rowSelection:
        memberRole !== ROLES.VIEWER
          ? {
              selectedRowKeys: areAllProjectsSelected
                ? projects.map((project) => project._id)
                : selectedProjects,
              onChange: onRowSelection,
            }
          : undefined,
    };

    return tableConfig;
  };

  const tableConfig = initTableConfig();

  return (
    <ListPageTableWrapper>
      <Table
        data-cy="listProjectsTable"
        {...tableConfig}
        locale={{
          emptyText: tableConfig.loading ? (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="Loading, please wait..."
            />
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No projects to show"
            />
          ),
        }}
      />
    </ListPageTableWrapper>
  );
};

ProjectsTableView.NameWrapper = styled.div`
  display: grid;
`;

ProjectsTableView.Name = styled.span`
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  display: block;
  cursor: pointer;
  transition: color 0.3s;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    color: ${(props) => props.theme.colors.blue};
  }
`;

ProjectsTableView.Actions = styled.div`
  display: flex;
  justify-content: right;
  align-items: baseline;

  > .anticon {
    font-size: 20px;
    padding: 8px;
    color: #595959;
  }
`;

ProjectsTableView.CustomFieldsWrapper = styled.div`
  margin: 7px 100px 0 70px;

  .ant-tag {
    margin-bottom: 7px;
  }
`;

ProjectsTableView.Progress = styled(Progress)`
  width: 100px;

  .ant-progress-text {
    font-size: 11px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
  }

  &.ant-progress-status-success {
    .ant-progress-text {
      color: #52c41a;
    }
  }
`;
export default ProjectsTableView;
