import isEmpty from 'lodash/isEmpty';
import * as R from 'ramda';
import { useEffect, useState } from 'react';

import TagWithPopover from '@common/components/TagWithPopover';
import { groupByModule } from '@common/utils/group-by-module';
import RuleDisplayValue, {
  CustomFieldTitle,
} from '@modules/compliance-profile/components/RuleDisplayValue';
import { getAttributesByDocumentType } from '@modules/compliance-profile/utils/checklist-helpers';
import { getIsCustomRequirement } from '@modules/requirement/utils/requirement-helpers';

import ProjectRequirementsTreeView from '../components/ProjectRequirementsTreeView';

const ProjectRequirementsTreeContainer = ({
  localComplianceProfile,
  onCheckRequirement,
  onCheckDocumentChecklist,
}) => {
  const [checkedRequirements, setCheckedRequirements] = useState([]);
  const [checkedDocumentChecklists, setCheckedDocumentChecklists] = useState(
    [],
  );

  const requirements = R.propOr([], 'rules', localComplianceProfile);

  const documentChecklists = R.propOr(
    [],
    'documentChecklists',
    localComplianceProfile,
  );

  const groupedModules = groupByModule(requirements);
  const requirementsTreeData = groupedModules.map((moduleData) => {
    const children = moduleData.subjects.map((subject) => {
      const children = subject.children.map((rule) => ({
        key: rule.attributeId,
        title: (
          <RuleDisplayValue
            rule={rule}
            isCustom={getIsCustomRequirement(rule)}
            customFields={R.propOr([], 'customFields', rule)}
          />
        ),
      }));

      return {
        key: subject.subjectId,
        title: subject.subjectLabel,
        children,
      };
    });

    return {
      key: moduleData.moduleId,
      title: moduleData.moduleLabel,
      children,
    };
  });

  const documentChecklistsTreeData = R.compose(
    R.map((type) => {
      const attributes = getAttributesByDocumentType(documentChecklists, type);

      return {
        key: type._id,
        title: type.name,
        children: attributes.map(
          ({ attributeId, attributeLabel, customFields = [] }) => ({
            key: attributeId,
            title: (
              <>
                <span>{attributeLabel}</span>
                <div>
                  {!isEmpty(customFields) && (
                    <>
                      <CustomFieldTitle>Added by</CustomFieldTitle>
                      {customFields.map(({ customField }) => (
                        <TagWithPopover
                          key={customField._id}
                          color={customField.color}
                          name={customField.name}
                        />
                      ))}
                    </>
                  )}
                </div>
              </>
            ),
          }),
        ),
      };
    }),
    R.uniq,
    R.filter(({ _id }) => Boolean(_id)),
    R.map((checklist) => ({
      _id: R.path(['type', '_id'], checklist),
      name: R.path(['type', 'name'], checklist),
    })),
  )(documentChecklists);

  // biome-ignore lint/correctness/useExhaustiveDependencies: More Deps Than Needed
  useEffect(() => {
    setCheckedRequirements(
      R.compose(
        R.map((el) => el.attributeId),
        R.filter((el) => el.isActive),
      )(requirements),
    );

    setCheckedDocumentChecklists(
      R.compose(
        R.map((el) => el.attributeId),
        R.filter((el) => el.isActive),
      )(documentChecklists),
    );
  }, [documentChecklists, requirements, localComplianceProfile]);

  return (
    <div data-cy={`projectRequirementsTree_${localComplianceProfile._id}`}>
      <ProjectRequirementsTreeView
        requirementsTreeData={requirementsTreeData}
        documentChecklistsTreeData={documentChecklistsTreeData}
        checkedRequirements={checkedRequirements}
        checkedDocumentChecklists={checkedDocumentChecklists}
        onCheckRequirement={onCheckRequirement}
        onCheckDocumentChecklist={onCheckDocumentChecklist}
      />
    </div>
  );
};

export default ProjectRequirementsTreeContainer;
