import { Select } from 'antd';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { type ReactNode, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import Table from '@common/components/Table.legacy';

import { FullWidthSelect } from '@trustlayer/ui';
import {
  getDefaultRecipientIdsMap,
  getRecipientIdsMap,
  getValidContacts,
} from './RecipientsTable.utils';

dayjs.extend(relativeTime);

type onContactsChangeParams = {
  recipientIdsMapByPrimaryRecordId: Record<string, string[]>;
  hasError: boolean;
};

type RecipientsTableProps = {
  primaryRecords: PrimaryRecord[];
  primaryObjectPluralName?: string;
  onContactsChange: (params: onContactsChangeParams) => void;
};

type CompactPrimaryRecord = {
  _id: string;
  name: string;
  lastEmail: string;
  contact: ReactNode;
};

import type { PrimaryRecord } from '@graphql/types/graphql';
import type { SortOrder } from 'antd/es/table/interface';

export const getTableConfiguration = (primaryObjectPluralName?: string) => [
  {
    title: primaryObjectPluralName || 'Primary Record',
    dataIndex: 'name',
    key: 'name',
    sorter: (a: CompactPrimaryRecord, b: CompactPrimaryRecord) =>
      b.name > a.name ? 1 : -1,
    defaultSortOrder: 'descend' as SortOrder,
    sortDirections: ['descend', 'ascend'] as SortOrder[],
  },
  {
    title: 'Contact',
    dataIndex: 'contact',
    key: 'contact',
    width: '45%',
  },
  { title: 'Last email', dataIndex: 'lastEmail', key: 'lastEmail' },
];

const formatLastEmailSentOn = (stringDate?: string) =>
  stringDate ? dayjs(stringDate).fromNow() : '—';

export const RecipientsTable = ({
  primaryRecords,
  primaryObjectPluralName,
  onContactsChange,
}: RecipientsTableProps) => {
  const [recipientsByParty, setRecipientsByParty] = useState(() =>
    getDefaultRecipientIdsMap(primaryRecords),
  );
  const [errorsMap, setErrorsMap] = useState({});

  const recipientIdsMapByPrimaryRecordId = useMemo(() => {
    return getRecipientIdsMap(recipientsByParty);
  }, [recipientsByParty]);

  const onSelectChange = ({
    primaryRecordId,
    selectedContactIds,
    contacts,
  }: any) => {
    setRecipientsByParty((currentRecipientsState) => {
      const updatedContacts = contacts.filter(({ _id }: any) =>
        selectedContactIds?.includes(_id),
      );

      return {
        ...currentRecipientsState,
        [primaryRecordId]: updatedContacts,
      };
    });
  };

  useEffect(() => {
    if (onContactsChange) {
      const hasError = Object.values(errorsMap).some((value) => value);
      onContactsChange({ recipientIdsMapByPrimaryRecordId, hasError });
    }
  }, [recipientIdsMapByPrimaryRecordId, onContactsChange, errorsMap]);

  return (
    <Table<CompactPrimaryRecord>
      size="small"
      scroll={{ y: 500 }}
      pagination={false}
      loading={false}
      rowKey={(row) => row._id}
      columns={getTableConfiguration(primaryObjectPluralName)}
      dataSource={primaryRecords.map((primaryRecord) => {
        const contacts = getValidContacts(primaryRecord?.contacts?.nodes);
        const selectedContactIds =
          recipientIdsMapByPrimaryRecordId[primaryRecord?._id] || [];

        return {
          _id: primaryRecord._id,
          name: primaryRecord?.name,
          lastEmail: formatLastEmailSentOn(
            primaryRecord?.stats?.messages?.lastMessageSentOn,
          ),
          contact: (
            <>
              {contacts.length ? (
                <StyledSelectWrapper
                  className={
                    !selectedContactIds.length ? 'error missing-recipients' : ''
                  }
                >
                  <FullWidthSelect
                    mode="multiple"
                    value={selectedContactIds}
                    filterOption={(input, option) =>
                      ((option?.label as string) || '')
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    onChange={(newContactIds) => {
                      setErrorsMap((currentState) => {
                        return {
                          ...currentState,
                          [primaryRecord._id]: !(newContactIds as string[])
                            .length,
                        };
                      });
                      onSelectChange({
                        primaryRecordId: primaryRecord._id,
                        selectedContactIds: newContactIds,
                        contacts,
                      });
                    }}
                  >
                    {contacts.map((contact) => (
                      <Select.Option
                        key={contact._id}
                        value={contact._id}
                        label={contact?.contactPersonName || contact?.email}
                      >
                        {contact?.contactPersonName
                          ? `${contact.contactPersonName} (${contact?.email})`
                          : `${contact?.email}`}
                      </Select.Option>
                    ))}
                  </FullWidthSelect>
                </StyledSelectWrapper>
              ) : (
                <span>
                  No valid contact information available for this party
                </span>
              )}
            </>
          ),
        };
      })}
    />
  );
};

const StyledSelectWrapper = styled.div`
  &&&.error.missing-recipients .ant-select-selector {
    border: 1px solid ${({ theme }) => theme.colors.red};
  }
`;
