import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import { Button } from 'antd';
import styled from 'styled-components';

type PageActionsProps = {
  onClose: () => void;
  onNext?: () => void;
  onPrevious?: () => void;
};

export const PageActions = ({
  onClose,
  onPrevious,
  onNext,
}: PageActionsProps) => {
  return (
    <StyledActions>
      <Button icon={<CloseOutlined />} onClick={onClose} size="small" />
      {onPrevious && (
        <Button
          icon={<ArrowUpOutlined />}
          disabled={!Boolean(onPrevious)}
          onClick={onPrevious}
          size="small"
        />
      )}
      {onNext && (
        <Button icon={<ArrowDownOutlined />} onClick={onNext} size="small" />
      )}
    </StyledActions>
  );
};

const StyledActions = styled.div`
  display: flex;
  gap: 5px;
`;
