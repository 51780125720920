import { ControlOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import styled from 'styled-components';

const BaseComplianceProfileIcon = () => (
  <Tooltip
    title="This is a custom compliance profile because some base compliance requirements have been changed."
    overlayClassName="baseComplianceProfileTooltip"
  >
    <StyledControlOutlined data-cy="customComplianceProfileIcon" />
  </Tooltip>
);

const StyledControlOutlined = styled(ControlOutlined)`
  color: #fa8c16 !important;
`;

export default BaseComplianceProfileIcon;
