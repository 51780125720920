import { setAuthenticatedAppReady } from '@modules/app';
import { setActiveOrganization } from '@modules/organization/actions';
import { getActiveOrganizationData } from '@modules/organization/selectors';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useFirstOrganizationQuery } from './useFirstOrganizationQuery';

export const useInitialOrganization = () => {
  const { organizationSlugifyName } = useParams();
  const dispatch = useDispatch();
  const [isOrganizationLoading, setIsOrganizationLoading] = useState(true);

  const organization = useSelector(getActiveOrganizationData);

  // take the initial active organization from the store just once
  const store = useStore();
  const initialActiveOrganizationSlug = useMemo(
    () => getActiveOrganizationData(store.getState())?.slugifyName,
    [store],
  );

  const { fetchFirstOrganization } = useFirstOrganizationQuery();

  const fetchOrg = useCallback(async () => {
    if (organizationSlugifyName)
      return fetchFirstOrganization(organizationSlugifyName).catch(() =>
        fetchFirstOrganization(),
      );

    if (initialActiveOrganizationSlug)
      return fetchFirstOrganization(initialActiveOrganizationSlug).catch(() =>
        fetchFirstOrganization(),
      );

    return fetchFirstOrganization();
  }, [
    fetchFirstOrganization,
    organizationSlugifyName,
    initialActiveOrganizationSlug,
  ]);

  const fetchInitialOrganization = useCallback(
    async (args?: {
      omitAuthenticatedAppReady?: boolean;
    }) => {
      setIsOrganizationLoading(true);

      const org = await fetchOrg();
      if (!org) return;

      dispatch(setActiveOrganization(org));

      if (!args?.omitAuthenticatedAppReady) {
        dispatch(setAuthenticatedAppReady());
      }

      setIsOrganizationLoading(false);
      return org;
    },
    [dispatch, fetchOrg],
  );

  return {
    initialActiveOrganizationSlug,
    organization,
    fetchInitialOrganization,
    isOrganizationLoading,
  };
};
