import { UploadOutlined } from '@ant-design/icons';
import { ModalConfirm } from '@common/components/ModalConfirm';
import { withControlledMount } from '@common/hoc/withControlledMount';
import { Space, Typography, Upload, theme } from 'antd';
import type { UploadFile } from 'antd/es/upload';
import { useState } from 'react';

interface UploadAttachmentsModalProps {
  open: boolean;
  title?: string;
  maxUploadCount?: number;
  acceptedFileTypes?: string;
  onCancel?: VoidFunction;
  onCompleted?: VoidFunction;
  onFailed?: VoidFunction;
  onOk: (files: UploadFile[]) => void;
  loading?: boolean;
}

function UploadAttachmentsModalComponent({
  open,
  title = 'Upload attachments',
  maxUploadCount = 10,
  acceptedFileTypes,
  onCancel,
  onOk,
  loading = false,
}: UploadAttachmentsModalProps) {
  const { token } = theme.useToken();
  const [files, setFiles] = useState<UploadFile[]>([]);

  return (
    <ModalConfirm
      data-cy="upload-attachment-modal"
      open={open}
      okText="Upload"
      title={title}
      onCancel={onCancel}
      onOk={() => {
        onOk(files);
        setFiles([]);
      }}
      bodyStyle={{ padding: 0 }}
      okButtonProps={{ loading: loading, disabled: files.length === 0 }}
    >
      <Typography.Paragraph>
        Attach up to {maxUploadCount} files at a time.
      </Typography.Paragraph>
      <Upload.Dragger
        multiple
        maxCount={maxUploadCount}
        accept={acceptedFileTypes}
        beforeUpload={(file) => {
          setFiles((files) => {
            if (files.length >= maxUploadCount) {
              return files;
            }
            return files.concat(file);
          });
          return false;
        }}
        onRemove={(file) => {
          setFiles((files) => files.filter((f) => f.uid !== file.uid));
        }}
        fileList={files}
      >
        <Space direction="vertical" size="middle">
          <UploadOutlined
            style={{
              fontSize: token.sizeXXL,
              color: token.colorPrimaryHover,
            }}
          />
          <div>
            <Typography.Text>Upload documents</Typography.Text>
            <div>
              <Typography.Text
                type="secondary"
                style={{ fontSize: token.fontSizeSM }}
              >
                Drag and drop documents or{' '}
              </Typography.Text>
              <Typography.Text
                underline
                type="secondary"
                style={{ fontSize: token.fontSizeSM }}
              >
                click to browse and select
              </Typography.Text>
            </div>
          </div>
        </Space>
      </Upload.Dragger>
    </ModalConfirm>
  );
}

export const UploadAttachmentsModal = withControlledMount(
  UploadAttachmentsModalComponent,
);
