import {
  FILTER_OPERATORS,
  type ServerFilterInput,
} from '@common/constants/filters/serverFilters';
import { useQuery } from '@graphql/hooks';
import { graphql } from '@graphql/types';
import type { PrimaryRecord } from '@graphql/types/graphql';

type UsePrimaryRecordsEmailsDataParams = {
  activeFilters?: ServerFilterInput;
  areQueriesSkipped: boolean;
  includeNodes: boolean;
};

const PRIMARY_RECORDS_EMAILS_DATA_QUERY = graphql(`
  query PrimaryRecordsSendEmails($input: PrimaryRecordsInput, $includeNodes: Boolean!) {
    primaryRecords(input: $input) {
      totalCount
      nodes @include(if: $includeNodes) {
        _id
        name
        stats {
          messages {
            lastMessageSentOn
          }
        }
        contacts {
          nodes {
            _id
            contactPersonName
            email
            defaultRequestRecipient
          }
        }
      }
    }
  }
`);

const PRIMARY_RECORDS_COUNTERS_QUERY = graphql(`
  query PrimaryRecordsWithoutContactsCounter($inputWithoutContacts: PrimaryRecordsInput) {
    primaryRecords(input: $inputWithoutContacts) {
      totalCount
    }
  }
`);

export const usePrimaryRecordsEmailsData = ({
  activeFilters,
  areQueriesSkipped,
  includeNodes,
}: UsePrimaryRecordsEmailsDataParams) => {
  const { loading, data } = useQuery(PRIMARY_RECORDS_EMAILS_DATA_QUERY, {
    variables: {
      input: {
        filter: activeFilters,
      },
      includeNodes,
    },
    skip: areQueriesSkipped,
  });

  const {
    loading: isPrimaryRecordsWithoutContactsLoading,
    data: primaryRecordsWithoutContacts,
  } = useQuery(PRIMARY_RECORDS_COUNTERS_QUERY, {
    variables: {
      inputWithoutContacts: {
        filter: {
          and: [
            ...(activeFilters?.and || []),
            {
              name: 'contactEmailStatus',
              operator: FILTER_OPERATORS.in,
              value: ['withoutContactEmail'],
            },
          ],
        },
      },
    },
    skip: areQueriesSkipped || includeNodes,
  });

  return {
    primaryRecords: (data?.primaryRecords.nodes || []) as PrimaryRecord[],
    loading,
    primaryRecordsTotalCount: data?.primaryRecords.totalCount || 0,
    isPrimaryRecordsWithoutContactsLoading,
    primaryRecordsWithoutContactsTotal:
      primaryRecordsWithoutContacts?.primaryRecords.totalCount || 0,
  };
};
