import * as R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';

import { getTemplates } from '@modules/requirement/selectors';
import { getModulesData } from '../utils/compliance-attributes-helpers';

import * as ComplianceProfileActions from '../actions';
import ComplianceProfileModuleLabels from '../components/ComplianceProfileModuleLabels';
import { DefaultRule } from '../constants';
import {
  getCurrentComplianceProfile,
  getCurrentDocumentChecklists,
  getCurrentRequirements,
} from '../selectors';

const ComplianceModuleLabelsContainer = ({ ...props }) => {
  const currentComplianceProfile = useSelector(getCurrentComplianceProfile);
  const currentRequirements = useSelector(getCurrentRequirements);
  const currentDocumentChecklists = useSelector(getCurrentDocumentChecklists);
  const templates = useSelector(getTemplates);
  const { complianceModules, formModules } = getModulesData(
    currentRequirements,
    templates,
  );

  const dispatch = useDispatch();

  const onSetActiveModules = (moduleId, isActive) => {
    R.compose(
      (requirements) =>
        dispatch(ComplianceProfileActions.setCurrentRequirements(requirements)),
      (requirements) =>
        isActive
          ? [
              ...requirements,
              {
                ...DefaultRule,
                moduleId,
                moduleLabel: R.compose(
                  R.propOr('', 'moduleLabel'),
                  R.find(R.propEq('moduleId', moduleId)),
                )(templates),
              },
            ]
          : requirements,
      R.filter((requirement) => requirement.moduleId !== moduleId),
    )(currentRequirements);
    dispatch(
      ComplianceProfileActions.setCurrentComplianceProfile(
        currentComplianceProfile,
      ),
    );
  };

  const setIsActiveDocumentChecklist = (typeId, isActive) =>
    dispatch(
      ComplianceProfileActions.setIsActiveDocumentChecklist(typeId, isActive),
    );

  return (
    <ComplianceProfileModuleLabels
      {...props}
      complianceModules={complianceModules}
      formModules={formModules}
      currentDocumentChecklists={currentDocumentChecklists}
      requirements={currentRequirements}
      onSetActiveModules={onSetActiveModules}
      setIsActiveDocumentChecklist={setIsActiveDocumentChecklist}
    />
  );
};

export default ComplianceModuleLabelsContainer;
