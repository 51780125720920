export const defaultDateFormats = [
  // 'll',
  'MM/DD/YYYY',
  'MM/DD/YY',
  'MM-DD-YYYY',
  'MM-DD-YY',
  'MMDDYY',
  'MMDDYYYY',
];

export const DAYJS_TABLE_FORMAT = 'MMM D, YYYY';
