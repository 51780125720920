import { Tabs, Tree } from 'antd';
import * as R from 'ramda';
import { useCallback } from 'react';
import styled from 'styled-components';

import { groupByModule } from '@common/utils/group-by-module';
import RuleDisplayValue from '@modules/compliance-profile/components/RuleDisplayValue';
import { getIsCustomRequirement } from '@modules/requirement/utils/requirement-helpers';

const BulkMatchRequirements = ({
  complianceProfiles,
  checkedRequirements,
  setCheckedRequirements,
}) => {
  const getRequirementsTreeData = useCallback((requirements) => {
    const groupedModules = groupByModule(requirements);

    const buildRuleNode = (rule) => ({
      key: rule.attributeId,
      title: (
        <RuleDisplayValue
          rule={rule}
          subjectId={rule.subjectId}
          isCustom={getIsCustomRequirement(rule)}
          customFields={R.propOr([], 'customFields', rule)}
        />
      ),
    });

    const buildSubjectNode = (subject) => ({
      key: subject.subjectId,
      title: subject.subjectLabel,
      children: subject.children.map(buildRuleNode),
    });

    const buildModuleNode = (moduleData) => ({
      key: moduleData.moduleId,
      title: moduleData.moduleLabel,
      children: moduleData.subjects.map(buildSubjectNode),
    });

    return groupedModules.map(buildModuleNode);
  }, []);

  const getSelectedRequirementsCountLabel = useCallback(
    (_id, requirements) =>
      R.compose(
        (count) => (count === 0 ? '' : ` (${count})`),
        R.length,
        R.filter((key) => R.any((x) => x.attributeId === key, requirements)),
      )(R.propOr([], _id, checkedRequirements)),
    [checkedRequirements],
  );

  return (
    <BulkMatchRequirements.Wrapper data-cy="bulkRequirementsModalContent">
      <BulkMatchRequirements.Title>
        <p>
          Select one or more requirements you want to match on the current
          documents selection using the controls below.
        </p>
        <p>
          Heads up: only requirements from active groups (having valid time
          range or marked as &quot;Always valid&quot;) will be effectively used
          to calculate the compliance score for their respective parties.
        </p>
      </BulkMatchRequirements.Title>
      <Tabs type="card">
        {complianceProfiles.map(({ _id, name, requirements }) => (
          <Tabs.TabPane
            tab={`${name}${getSelectedRequirementsCountLabel(
              _id,
              requirements,
            )}`}
            key={_id}
          >
            <Tree
              blockNode={true}
              selectable={false}
              checkable
              defaultExpandAll
              checkedKeys={R.propOr([], _id, checkedRequirements)}
              treeData={getRequirementsTreeData(requirements)}
              onCheck={(keys) =>
                setCheckedRequirements(
                  keys.length
                    ? R.assoc(_id, keys, checkedRequirements)
                    : R.omit([_id], checkedRequirements),
                )
              }
            />
          </Tabs.TabPane>
        ))}
      </Tabs>
    </BulkMatchRequirements.Wrapper>
  );
};

BulkMatchRequirements.Wrapper = styled.div`
  width: 100%;
  padding-bottom: 20px;
`;

BulkMatchRequirements.Title = styled.div`
  font-size: 13px;
  margin-bottom: 30px;
`;

export default BulkMatchRequirements;
