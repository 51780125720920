import {
  CaretDownOutlined,
  LogoutOutlined,
  PlusOutlined,
  SettingOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Button, Popover, Skeleton } from 'antd';
import { type ReactNode, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';

import OrganizationAvatar from '@common/components/OrganizationAvatar';
import type { Maybe } from '@common/types';
import type { Organization } from '@graphql/types/graphql';
import { useIsComplianceRequirementsAddOnEnabled } from '@modules/add-on';
import { useWorkspacesPageFeatureFlag } from '@modules/feature-flags/hooks';
import {
  CreateOrganizationModal,
  OrganizationList,
} from '@modules/organization';
import { HideForViewerRole } from '@modules/organization-member/containers/HideForRoles';
import { getActiveOrganizationData } from '@modules/organization/selectors';

type CurrentWorkspaceProps = {
  minfied?: boolean;
  label: string;
  iconUrl?: Maybe<string>;
  onClick: () => void;
};

// icon?: Maybe<Scalars['String']['output']>;

const PopoverContent = ({
  onClick,
  children,
}: {
  onClick: (param: string) => void;
  children: ReactNode;
}) => {
  return (
    <>
      <StyledStaticLinks>
        <HideForViewerRole>
          <StyledItem onClick={() => onClick('settings/organization')}>
            <SettingOutlined />
            <span className="label">Workspace settings</span>
          </StyledItem>
        </HideForViewerRole>
        <StyledItem onClick={() => onClick('settings/profile')}>
          <UserOutlined />
          <span className="label">Account settings</span>
        </StyledItem>
        <StyledItem onClick={() => onClick('logout')}>
          <LogoutOutlined />
          <span className="label">Sign out</span>
        </StyledItem>
      </StyledStaticLinks>
      {children}
    </>
  );
};

const CurrentWorkspace = ({
  label,
  iconUrl,
  onClick,
  minfied,
}: CurrentWorkspaceProps) => {
  return minfied ? (
    <StyledAvatarWrapper onClick={onClick}>
      <OrganizationAvatar name={label} size={24} iconUrl={iconUrl} />
    </StyledAvatarWrapper>
  ) : (
    <StyledActiveItem onClick={onClick}>
      <div className="labelWrapper">
        <OrganizationAvatar name={label} size={24} iconUrl={iconUrl} />
        <div className="label">{label}</div>
      </div>
      <CaretDownOutlined />
    </StyledActiveItem>
  );
};

export const SettingsMenu = ({
  isMinifiedMode,
}: {
  isMinifiedMode?: boolean;
}) => {
  const { isWorkspacesPageFeatureFlagEnabled } = useWorkspacesPageFeatureFlag();
  const navigate = useNavigate();

  const { isComplianceRequirementsAddOnEnabled } =
    useIsComplianceRequirementsAddOnEnabled();

  const [
    isCreateOrganizationModalVisible,
    setIsCreateOrganizationModalVisible,
  ] = useState(false);

  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);

  const activeOrganization = useSelector<any, Organization>(
    getActiveOrganizationData,
  );

  const [selectedWorkspace, setSelectedWorkspace] = useState<
    Organization | undefined
  >();

  useEffect(() => {
    setSelectedWorkspace(activeOrganization);
  }, [activeOrganization]);

  const togglePopover = () => {
    setIsPopoverOpen((currentStatus) => !currentStatus);
  };

  const handleOnWorkSpaceChange = (workspaceCode: string) => {
    window.location.replace(
      `${window.location.origin}/${workspaceCode}/dashboard`,
    );
  };

  const handleOnLinksClick = (route: string) => {
    const path =
      route === 'logout'
        ? '/logout'
        : `/${selectedWorkspace?.slugifyName || ''}/${route}`;

    setIsPopoverOpen(false);
    navigate(path);
  };

  const Loader = isMinifiedMode ? (
    <Skeleton.Avatar active />
  ) : (
    <StyledSkeleton active />
  );

  return (
    <>
      <GlobalStyle />
      <CreateOrganizationModal
        visible={isCreateOrganizationModalVisible}
        onClose={() => setIsCreateOrganizationModalVisible(false)}
      />
      <Popover
        destroyTooltipOnHide
        overlayClassName="settingsMenuOverlay"
        open={isPopoverOpen}
        placement="bottomRight"
        trigger="click"
        arrow={false}
        onOpenChange={(shouldPopoverBeOpen) => {
          if (!shouldPopoverBeOpen) {
            setIsPopoverOpen(false);
          }
        }}
        content={
          <>
            <PopoverContent onClick={handleOnLinksClick}>
              <StyledOrganizationListWrapper>
                {isWorkspacesPageFeatureFlagEnabled && (
                  <StyledWorspacesLinkWrapper>
                    <span className="label">My workspaces</span>
                    <Link to="/workspaces">View all</Link>
                  </StyledWorspacesLinkWrapper>
                )}
                <OrganizationList
                  onClick={(workspace) => {
                    handleOnWorkSpaceChange(workspace.slugifyName);
                  }}
                />
              </StyledOrganizationListWrapper>
              {isComplianceRequirementsAddOnEnabled ? (
                <StyledButtonWrapper>
                  <StyledButton
                    onClick={() => {
                      setIsCreateOrganizationModalVisible(true);
                      setIsPopoverOpen(false);
                    }}
                  >
                    <PlusOutlined />
                    <span>New workspace</span>
                  </StyledButton>
                </StyledButtonWrapper>
              ) : null}
            </PopoverContent>
          </>
        }
      >
        {!selectedWorkspace ? (
          <div>{Loader}</div>
        ) : (
          <CurrentWorkspace
            minfied={isMinifiedMode}
            label={selectedWorkspace.name}
            iconUrl={selectedWorkspace.icon}
            onClick={togglePopover}
          />
        )}
      </Popover>
    </>
  );
};

const GlobalStyle = createGlobalStyle`
  .settingsMenuOverlay {
    min-width: 290px;

    .ant-popover-inner {
      padding: 0;
    }
  }
`;

const StyledSkeleton = styled(Skeleton.Input)`
  border-radius: 8px;
  width: 100% !important;
`;

const StyledWorspacesLinkWrapper = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 8px;

  .label {
    color: ${({ theme }) => theme.colors.lightTextGray};
  }
`;

const StyledActiveItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.lightGray};
  padding: 4px 8px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.white};

  .labelWrapper {
    display: flex;
    align-items: center;
    position: relative;
    left: -4px;
    flex-grow: 1;
    overflow: hidden;
  }

  .label {
    margin-left: 8px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

const StyledAvatarWrapper = styled.div`
  padding: 5px 9px;
  cursor: pointer;
`;

const StyledItem = styled.section`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.mineShaft};
  padding: 4px 8px;
  border-radius: 8px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.lightBGGray};
  }

  .label {
    margin-left: 8px;
  }
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

const StyledOrganizationListWrapper = styled.section`
  padding: 12px;
  border-top: 1px solid ${({ theme }) => theme.colors.lightGrayTone};
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrayTone};
`;

const StyledStaticLinks = styled.section`
  padding: 12px;
`;

const StyledButtonWrapper = styled.div`
  padding: 16px;
`;
